import { makeAutoObservable } from "mobx"
import { TPermission } from "@/api/controls"
import { addCatchNotification } from "../notifications"
import { api } from "./api"
import { StructuredPermissions } from "./types"
import { getStructuredPermissions, normalizedPermissions } from "./utils"

export class Store {
  permissions: TPermission[] | null = null
  structuredPermissions: StructuredPermissions | null = null
  menus: TPermission[] | null = null

  constructor() {
    makeAutoObservable(this)
  }

  getMenus = () =>
    api.getMenus().then((response) => {
      this.setMenus(response.data)
      this.setStructuredPermissions(response.data)

      return response
    })

  getPermissions = () =>
    api
      .getPermissions()
      .then((response) => {
        this.setPermissions(response.data)
      })
      .catch(addCatchNotification)

  setPermissions = (permissions: TPermission[]) => {
    this.permissions = normalizedPermissions(permissions)
  }

  setMenus = (permissions: TPermission[]) => {
    this.menus = normalizedPermissions(permissions)
  }

  setStructuredPermissions = (permissions: TPermission[]) => {
    this.structuredPermissions = getStructuredPermissions(permissions)
  }

  reset = () => {
    this.menus = null
  }
}

export const store = new Store()
