import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import { LeftOutlined } from "@ant-design/icons"
import { Button, Flex, Tag, Typography } from "antd"
import classNames from "classnames/bind"
import FolderIcon from "@/assets/icons/folder.svg"
import StatisticsIcon from "@/assets/icons/statistics.svg"
import mainDictionary from "@/dictionary"

import styles from "./group-top.module.scss"

const cn = classNames.bind(styles)

export const GroupTop = observer(() => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <Flex className={cn("group-top")} align="center" justify="space-between">
      <Flex align="center" gap={10}>
        <Button onClick={handleBack} icon={<LeftOutlined />} type="text" />

        <Flex align="center" gap={16}>
          <Typography.Title className={cn("group-top__title")} level={5}>
            GROUP name
          </Typography.Title>

          <Tag className={cn("group-top__tag")}>{mainDictionary.fullGroup}</Tag>
        </Flex>
      </Flex>

      <Flex gap={16} align="center">
        <Button icon={<img src={StatisticsIcon} width={18} height={16} alt="statistics-icon" />}>
          {mainDictionary.statistics}
        </Button>

        <Button icon={<img src={FolderIcon} width={18} height={16} alt="statistics-icon" />}>
          {mainDictionary.downloadXlsx}
        </Button>
      </Flex>
    </Flex>
  )
})
