export const auditDictionary = {
  executor: "Bajaruvchi",
  exucatorRole: "Bajaruvchi roli",
  actionTitle: "Amal nomi",
  actionDesc: "Amal haqida",
  check: "Yo'qlama",
  examPassed: "O'tganlar",
  failed: "Yiqilganlar",
  dontSubmitted: "Ishtirok etmaganlar",
  grade: "Bahosi",
  allAction: "Barchasi",
  roleAction: "Rollar",
  usersAction: "Userlar",
  groupVideos: "Guruh videolari",
  groupHomework: "Guruh uyga vazifalari",
  groupExams: "Guruh imtihonlari",
  groupAttendance: "Guruh davomati",
  staffRolesAccess: "Berilgan huquqlar:",
  staffCancelRoles: "Bekor qilingan huquqlar:",
  emptyDetail: "---",
  Attended: "Keldi",
  DidNotParticipate: "Kelmadi",
  previousGroups: "Oldingi guruhlar",
  previousCurriculum: "O`zgartirishdan oldingi tartib",
  currentCurriculum: "O`zgartirilgandan keyingi tartib",
  topic: "Mavzu nomi",
  minute: "daqiqa",
  description: "Izoh",
  isEdited: "O`zgartirilgan",
  homeworkTopic: "Vazifa mavzusi",
}
