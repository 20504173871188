import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const Reports = lazy(() =>
  import("./Reports").then(({ Reports }) => ({ default: Reports })).catch(handleCatchChunkError),
)

export const AutoCall = lazy(() =>
  import("./AutoCall").then(({ AutoCall }) => ({ default: AutoCall })).catch(handleCatchChunkError),
)

export const AutoCallHistory = lazy(() =>
  import("./AutoCall/Templates/History").then(({ History }) => ({ default: History })).catch(handleCatchChunkError),
)
