import mainDictionary from "@/dictionary"

export enum FinanceKyes {
  Prices = "1",
  Discounts = "2",
  AdditionalFees = "3",
  Categories = "4",
  Paymens = "5",
  Tariffs = "6",
  CalcAuto = "7",
  OtherInfo = "8",
}

export enum pricesSegmentedValues {
  ForHoursPrice = "1",
  ForDaysPrice = "2",
  ForCalendarPrice = "3",
}

export const pricesSegmentedItems = [
  {
    label: mainDictionary.forHoursPrice,
    value: pricesSegmentedValues.ForHoursPrice,
  },
  {
    label: mainDictionary.forDaysPrice,
    value: pricesSegmentedValues.ForDaysPrice,
  },
  {
    label: mainDictionary.forCalendarPrice,
    value: pricesSegmentedValues.ForCalendarPrice,
  },
]

export enum pricesFilterSegmentedValue {
  Daily = "1",
  Package = "2",
}

export const pricesFilterSegmentedItems = [
  {
    label: mainDictionary.settingsDaily,
    value: pricesFilterSegmentedValue.Daily,
  },
  {
    label: mainDictionary.settingsPackage,
    value: pricesFilterSegmentedValue.Package,
  },
]

export enum PaymentSegmentedValues {
  PaymentType = "1",
  PaymentMethod = "2",
  Tag = "3",
}

export const paymentsSegmentedItems = [
  {
    label: mainDictionary.paymentType,
    value: PaymentSegmentedValues.PaymentType,
  },
  {
    label: mainDictionary.paymentMethod,
    value: PaymentSegmentedValues.PaymentMethod,
  },
  {
    label: mainDictionary.tag,
    value: PaymentSegmentedValues.Tag,
  },
]

export enum FinanceDiscountsTypes {
  Fixed = "fixed",
  Percent = "percent",
}

export const financeDiscountsTypes = [
  {
    label: mainDictionary.fixed,
    value: FinanceDiscountsTypes.Fixed,
  },
  {
    label: mainDictionary.percent,
    value: FinanceDiscountsTypes.Percent,
  },
]

export const enum DayType {
  LESSON = "lesson",
  FREE_LESSON = "free_lesson",
  CHARGEABLE_ABSENCE = "chargeable_absence",
  NONCHARGEABLE_ABSENCE = "nonchargeable_absence",
}

export const dayTypes = [
  {
    label: mainDictionary.dayTypeLesson,
    value: DayType.LESSON,
  },
  {
    label: mainDictionary.freeLesson,
    value: DayType.FREE_LESSON,
  },
  {
    label: mainDictionary.chargeableAbsence,
    value: DayType.CHARGEABLE_ABSENCE,
  },
  {
    label: mainDictionary.nonChargeableAbsence,
    value: DayType.NONCHARGEABLE_ABSENCE,
  },
]

export const academicPriceInitialValues = [{ minStudentCount: 0, value: null }]

export const MIN_LENGTH = 1

export const COMMA_REGEX = /,/g
