export interface IUserSingleProp {
  isEdit?: boolean
  isAdd?: boolean
}

export enum StaffWorkStatus {
  Working = 1,
  NotWorking = 2,
}

export enum GenderTypes {
  MALE = 1,
  FEMALE = 2,
}

export enum RoleTypes {
  Staff = 1,
  Academic = 2,
}

export enum FaceIdScreenTab {
  Single = "1",
  Multi = "2",
}
