import { useEffect, useMemo } from "react"
import { observer } from "mobx-react"
import { Button, Form, Input, Select, SelectProps, Space } from "antd"
import { useBoolean } from "usehooks-ts"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { branchOfficeApi } from "../shared/branch-office.api"
import { branchOfficeStore } from "../shared/branch-office.store"

type FormFieldFormValues = {
  name: string
  hOfficeId: number
}

export const BranchOfficeAddEditForm = observer(() => {
  const { value: isLoading, setTrue: setTrueLoading, setFalse: setFalseLoading } = useBoolean()
  const {
    setOpenAddModalStatus,
    getOfficesHh,
    hhOffices,
    getBranchOffices,
    inActionBranchOffice,
    setInActionBranchOffice,
  } = branchOfficeStore
  const hhSelectOptions: SelectProps["options"] = useMemo(
    () =>
      hhOffices?.map((hhOffice) => ({
        value: hhOffice.id,
        label: hhOffice.name,
        disabled: hhOffice?.isRelated,
      })),
    [hhOffices],
  )
  const isEdit = !!inActionBranchOffice

  const [form] = Form.useForm<FormFieldFormValues>()

  const handleSubmit = async ({ name, hOfficeId }: FormFieldFormValues) => {
    setTrueLoading()

    try {
      if (inActionBranchOffice) {
        await branchOfficeApi.changeOffices(inActionBranchOffice.id, { name, hOfficeId })
      } else {
        await branchOfficeApi.addNewCurrentBranchOffices({ name, hOfficeId })
      }

      await getBranchOffices()

      addSuccessNotification(mainDictionary.addedBranchOffice(name))

      form.resetFields()
      setOpenAddModalStatus(false)
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    } finally {
      setFalseLoading()
    }
  }

  const handleCancelButtonClick = async () => {
    form.resetFields()
    setInActionBranchOffice(null)
    setOpenAddModalStatus(false)
  }

  const handleBranchChange = (value: number) => {
    const selectedBranch = hhOffices?.find((item) => item.id === value)

    form.setFieldValue("name", selectedBranch?.name)
  }

  useEffect(() => {
    setTrueLoading()

    getOfficesHh().finally(setFalseLoading)
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      name: inActionBranchOffice?.name || "",
      hOfficeId: inActionBranchOffice?.hOffice.id,
    })
  }, [inActionBranchOffice])

  return (
    <Form layout="vertical" onFinish={handleSubmit} form={form} disabled={isLoading} size="large">
      <Form.Item
        label={mainDictionary.holyHopeBranchOffice}
        name="hOfficeId"
        rules={[{ required: true, message: mainDictionary.holyHopeOfficeIdFieldErrorRequired }]}
      >
        <Select onChange={handleBranchChange} options={hhSelectOptions} disabled={isEdit} />
      </Form.Item>

      <Form.Item
        label={mainDictionary.branchName}
        name="name"
        rules={[
          { required: true, message: mainDictionary.currentOfficeNameErrorRequiredMessage },
          { type: "string", min: 3, message: mainDictionary.currentOfficeNameErrorMore3chartsMessage },
          { whitespace: true },
        ]}
      >
        <Input />
      </Form.Item>

      <Space align="end" style={{ width: "100%", justifyContent: "end" }}>
        <Button onClick={handleCancelButtonClick}>{mainDictionary.cancel}</Button>

        <Button htmlType="submit" type="primary" loading={isLoading}>
          {isEdit ? mainDictionary.save : mainDictionary.add}
        </Button>
      </Space>
    </Form>
  )
})
