import { ITeacherData } from "@/pages/Users/Teachers/types"
import { IPagination } from "../types"

export interface ITeachersParams extends IPagination {
  keys?: string[]
  workStatus?: StaffAccountStatus
  isActive?: boolean
  value?: string
  orderBy?: string
}

export interface ITeacherRolesParams extends IPagination {
  key?: string[]
  value?: string
}

export interface ITeacherRolesData {
  id: number
  name: string
}

export interface ITeachersSignUp {
  id: number
  login: string
}

export interface IResetPasswordRequest {
  userId: number
}

export interface IResetPasswordResponse {
  password: string
}

export interface ITeacherResponse {
  teachers: ITeacherData[]
  total: number
}

export interface ITeacherRoles {
  roles: []
  total: number
}

export enum TeacherStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
}

export interface IBlockStaffRequest {
  id: number
  login?: string
  isActive?: boolean
}

export interface ITeacherProfileAdd {
  id: number
  login: string
  roleId: number
}

export interface ISuccessResponse {
  success: boolean
}

export interface ITeacherPassword {
  password: string
}

export enum StaffWorkStatus {
  Working = 1,
  NotWorking = 2,
}

export enum StaffAccountStatus {
  Active = 1,
  InActive = 2,
  HasNotAccount = 3,
}
