import { makeAutoObservable, runInAction } from "mobx"
import {
  controlsApi,
  GetAllScreensListRes,
  GetLastScreenInDeviceRes,
  ICreateUserResponse,
  IDevice,
  IGetDeviceResponse,
  IGetFaceScreens,
  IGetPermissionResponse,
  IPermissionResponse,
  IRolesResponse,
  IRoleUsersResponse,
  ISingleRoleResponse,
} from "@/api/controls"
import { addCatchNotification } from "@/modules/notifications"
import { FaceIdScreenTab, StaffWorkStatus } from "@/pages/Controls/types"
import { IStaffInfo } from "../auth/types"
import {
  ControlFilter,
  IControlValues,
  ICreateUserRequest,
  IEditDeviceRequest,
  IEditRequest,
  IFaceId,
  IGetDevices,
  IGetDeviceScreenLogs,
  IGetLastScreenInDeviceReq,
  IGetRolesParams,
  IGetRoleUsers,
  IMakeDevice,
  IOpenDoor,
  IUserChangeStatusRequest,
  IUserEditRequest,
} from "./type"

class ControlsStore {
  addUserModal = false
  isEdit = false
  resetModal = false
  permissions: IPermissionResponse | null = null
  roles: IRolesResponse | null = null
  singleRole: ISingleRoleResponse | null = null
  roleUsers: IRoleUsersResponse | null = null
  userInfoModal = false
  userInfo: ICreateUserResponse | null = null
  isActive = true
  workStatus: StaffWorkStatus = StaffWorkStatus.Working
  userPerPage: number | null = null
  newPassword: string | null = null
  singleUser: IStaffInfo | null = null
  searchUser: string | null = null
  roleInfoModal = false
  singleRolePermissions: IGetPermissionResponse[] | null = null
  faceIdScreenTab: FaceIdScreenTab | string = FaceIdScreenTab.Single
  faceIdSingleScreenResults: IGetFaceScreens | null = null
  faceIdDeviceMake = false
  infoModal = false
  deviceFilters: IGetDevices | null = null
  deviceLoading = true
  devices: IGetDeviceResponse | null = null
  singleDevice: IDevice | null = null
  branchId: number | null = null
  staffSystemStatus: number | null = null
  selectedScreenResult: GetAllScreensListRes | null | GetLastScreenInDeviceRes = null
  systemUserLogId: null | number = null
  systemImageModal = false
  faceScreens: GetLastScreenInDeviceRes[] | null = null
  faceScreenImg: string | null = null
  selectedFaceIdFilterValues: IFaceId | null = null
  usersLoader = false
  usersPage = 1
  filter: ControlFilter = {
    fullName: "",
    status: StaffWorkStatus.Working,
    isWorking: true,
  }

  currentPage = 1

  activeDeviceId: null | number = null

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentPage = (params: number) => {
    this.currentPage = params
  }

  setRoleUsers = () => {
    this.roleUsers = null
    this.setCurrentPage(1)
    this.filter = {
      fullName: "",
      status: StaffWorkStatus.Working,
      isWorking: true,
    }
  }

  setUsersPage = (params: number) => {
    this.usersPage = params
  }

  setUsersLoader = (params: boolean) => {
    this.usersLoader = params
  }

  setSelectedFaceIdFilterValues = (params: IFaceId | null) => {
    this.selectedFaceIdFilterValues = params
  }

  setFaceIdSingleScreenResults = (params: IGetFaceScreens | null) => {
    this.faceIdSingleScreenResults = params
  }

  setActiveDeviceId = (params: null | number) => {
    this.activeDeviceId = params
  }

  setFaceScreens = (params: GetLastScreenInDeviceRes[] | null) => {
    this.faceScreens = params
  }

  setFaceScreen = (params: string | null) => {
    this.faceScreenImg = params
  }

  setSystemImageModal = (params: boolean) => {
    this.systemImageModal = params
  }

  setSystemImage = (params: null | number) => {
    this.systemUserLogId = params
  }

  setSelectedScreenResults = (params: GetAllScreensListRes | GetLastScreenInDeviceRes | null) => {
    this.selectedScreenResult = params
  }

  setFaceIdScreenTab = (params: FaceIdScreenTab | string) => {
    this.faceIdScreenTab = params
  }

  resetFilter = () => {
    this.filter = {
      fullName: "",
      status: StaffWorkStatus.Working,
      isWorking: true,
    }
  }

  setFilter = (filterValues: Partial<ControlFilter>) => {
    this.filter = { ...this.filter, ...filterValues }
  }

  setBranchId = (id: number) => {
    this.branchId = id
  }

  setUserPerPage = (perPage: number) => {
    this.userPerPage = perPage
  }

  setInfoModal = (params: boolean) => {
    this.infoModal = params
  }

  setSingleDevice = (params: IDevice | null) => {
    this.singleDevice = params
  }

  editDevice = (params: IEditDeviceRequest) => controlsApi.editDevice(params)

  setFaceIdDeviceMake = (params: boolean) => {
    this.faceIdDeviceMake = params
  }

  setRoleInfoModal = (params: boolean) => {
    this.roleInfoModal = params
  }

  setIsActive = (params: boolean) => {
    this.isActive = params
  }

  setNewPassword = (params: string | null) => {
    this.newPassword = params
  }

  setSearchUser = (params: string) => {
    this.searchUser = params
  }

  setSingleUser = (params: IStaffInfo | null) => {
    this.singleUser = params
  }

  openDoor = (params: IOpenDoor) => controlsApi.openDoor(params)

  getLastScreenInDevice = (params: IGetLastScreenInDeviceReq) =>
    controlsApi
      .getLastScreenInDevice(params)
      .then((res) => {
        if (res.success) {
          this.setFaceScreens(res.data)
        }
      })
      .catch(addCatchNotification)

  getFaceScreens = (params: IGetDeviceScreenLogs) =>
    controlsApi
      .getFaceScreens(params)
      .then((res) => {
        this.setFaceIdSingleScreenResults(res.data)
        this.setSelectedScreenResults(res?.data?.getScreensList?.[0])
      })
      .catch(addCatchNotification)

  getSingleUser = (params: string) =>
    controlsApi
      .getSingleUser(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.singleUser = res.data
          })
        }

        return res
      })
      .catch(addCatchNotification)

  setWorkStatus = (status: StaffWorkStatus) => {
    this.workStatus = status
  }

  resetPassword = (params: number) => controlsApi.resetPassword(params)

  setUserInfoModal = (params: boolean) => {
    this.userInfoModal = params
  }

  addUser = (params: ICreateUserRequest) => controlsApi.createUser(params)

  editUser = (params: IUserEditRequest) => controlsApi.editUser(params)

  setUserInfo = (params: ICreateUserResponse | null) => {
    this.userInfo = params
  }

  setDeviceFilter = (params: IGetDevices) => {
    this.deviceFilters = params
  }

  deleteDevice = (id: number) => controlsApi.deleteDevice(id)

  setDeviceLoading = (params: boolean) => {
    this.deviceLoading = params
  }

  getDevices = (params?: IGetDevices) =>
    controlsApi
      .getDevices(params)
      .then((res) => {
        if (res.success) {
          this.devices = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => (this.deviceLoading = false))

  addDevice = (params: IMakeDevice) => controlsApi.addDevice(params)

  getRoleUsers = (params: IGetRoleUsers) =>
    controlsApi
      .getRoleUsers(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.roleUsers = res.data
          })
        }
      })
      .catch(addCatchNotification)

  editUserStatus = (params: IUserChangeStatusRequest) => controlsApi.editUser(params)

  getPermissions = () =>
    controlsApi
      .getPermissions()
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.permissions = res.data
          })
        }
      })
      .catch(addCatchNotification)

  deleteRole = (id: number) => controlsApi.deleteRole(id)

  setSingleRolePermissions = (singleRolePermissions: IGetPermissionResponse[] | null) => {
    this.singleRolePermissions = singleRolePermissions
  }

  getPermissionRoles = (id: number) =>
    controlsApi
      .getSinglePermission(id)
      .then((res) => {
        if (res.success) {
          this.setSingleRolePermissions(res.data)
        }
      })
      .catch(addCatchNotification)

  setSingleRole = (singleRole: ISingleRoleResponse | null) => {
    this.singleRole = singleRole
  }

  getRoleById = (id: number) =>
    controlsApi
      .getSingleRole(id)
      .then((response) => {
        if (response.success) {
          this.setSingleRole(response.data)
        }

        return response
      })
      .catch(addCatchNotification)

  resetSingleRole = () => {
    this.singleRole = null
  }

  editRole = (values: IEditRequest) => controlsApi.editRole(values)

  getRoles = (params?: IGetRolesParams) =>
    controlsApi
      .getRoles(params)
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.roles = res.data
          })
        }
      })
      .catch(addCatchNotification)

  createRole = (values: IControlValues) => controlsApi.createRole(values)

  setUserIsEdit = (status: boolean) => {
    this.isEdit = status
  }

  setUserAddModal = (status: boolean) => {
    this.addUserModal = status
  }

  setResetLogin = (status: boolean) => {
    this.resetModal = status
  }

  setStaffSystemStatus = (value: number | null) => {
    this.staffSystemStatus = value
  }

  reset = () => {
    this.workStatus = StaffWorkStatus.Working
    this.roles = null
    this.userInfo = null
    this.roleUsers = null
    this.singleRole = null
    this.searchUser = null
    this.singleUser = null
    this.permissions = null
    this.newPassword = null
    this.isEdit = false
    this.isActive = true
    this.resetModal = false
    this.addUserModal = false
    this.userInfoModal = false
    this.staffSystemStatus = null
  }
}

export const controlsStore = new ControlsStore()
