export enum AppEndpoints {
  Roles = "/roles",
  AccessControlMenus = "/access-control/menus",
  AccessControlPermissions = "/access-control/permissions",
  Initial = "/initial",
  Profile = "/profile",
  Synchronization = "/synchronization",
  Students = "/students",
  Groups = "/groups",
  GroupStudents = "/group-students",
  Reports = "/reports",
  Count = "/count",
  SenderLog = "/sender/log",
  MessageCount = "/message-count",
  Notifications = "/notifications",
  Teachers = "/teachers",
}
