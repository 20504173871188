import { observer } from "mobx-react"
import { Alert, Button, Modal } from "antd"
import classnamesBind from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import LogOutIcon from "@/assets/icons/logout.svg?react"
import { STAFF_INFO, TOKEN_NAME } from "@/constants"
import mainDictionary from "@/dictionary"
import { resetStores, useStores } from "@/stores"
import { storage } from "@/utils"
import { useCheckMyGroup } from "@/utils/hooks"

import styles from "./logout.module.scss"

const cn = classnamesBind.bind(styles)

type Props = {
  hasUploadingFiles?: boolean
  isMini?: boolean
}

export const LogOut = observer(({ hasUploadingFiles, isMini }: Props) => {
  const { kinescopeStore, authStore } = useStores()
  const isAcademic = useCheckMyGroup()
  const { value: isLoading, setTrue: setIsLoadingTrue } = useBoolean(false)
  const { value: isModalOpen, setTrue: setIsModalOpenTrue, setFalse: setIsModalOpenFalse } = useBoolean(false)

  const handleOk = async () => {
    setIsLoadingTrue()
    kinescopeStore.abortAllUploadingFiles()

    const token = storage.get("fcmToken")

    if (token) {
      await authStore.logOut()
    }

    await kinescopeStore
      .deleteUploadingFiles(isAcademic)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        resetStores()
        storage.removeItems([STAFF_INFO, TOKEN_NAME.refreshToken, TOKEN_NAME.accessToken])
        setIsModalOpenFalse()
      })
  }

  return (
    <>
      <Button
        icon={<LogOutIcon />}
        type="text"
        iconPosition="start"
        onClick={setIsModalOpenTrue}
        className={cn("logout")}
        size="large"
        danger
      >
        {!isMini ? mainDictionary.exit : ""}
      </Button>

      <Modal
        title={mainDictionary.exit}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={setIsModalOpenFalse}
        okText={mainDictionary.yes}
        cancelText={mainDictionary.cancel}
        confirmLoading={isLoading}
      >
        <div className={cn("logout__modal-test")}>{mainDictionary.logOut}</div>

        {hasUploadingFiles && <Alert message={mainDictionary.hasUploadingFiles} type="error" showIcon />}
      </Modal>
    </>
  )
})
