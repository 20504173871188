import {
  CategoryAddParams,
  CategoryEditParams,
  ChangeStatusParams,
  ChangeTransactionStatusParams,
  EditProductParams,
  GetCategoriesParams,
  GetProductsParams,
  GetTransactionsParams,
  MakeProductParams,
} from "@/stores/market"
import { Endpoints } from "../endpoints"
import { INetworkConfig, Instance } from "../instance"
import { IResponse } from "../types"
import { MarketEndpoints } from "./endpoints"
import { GetCategoriesResponse, GetProductsRes, GetTransactionsRes, Product, Transaction } from "./types"

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}`,
}

class MarketApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getCategories = (params: GetCategoriesParams): Promise<IResponse<GetCategoriesResponse>> =>
    this.get(`${MarketEndpoints.Store}${MarketEndpoints.Categories}`, { params })

  addCategory = (params: CategoryAddParams): Promise<IResponse<GetCategoriesResponse>> =>
    this.post(`${MarketEndpoints.Store}${MarketEndpoints.Categories}`, params)

  editCategory = (params: CategoryEditParams): Promise<IResponse<GetCategoriesResponse>> =>
    this.put(`${MarketEndpoints.Store}${MarketEndpoints.Categories}/${params.id}`, params.data)

  deleteCategory = (id: number): Promise<IResponse<GetCategoriesResponse>> =>
    this.delete(`${MarketEndpoints.Store}${MarketEndpoints.Categories}/${id}`)

  getProducts = (params: GetProductsParams): Promise<IResponse<GetProductsRes>> =>
    this.get(`${MarketEndpoints.Store}${MarketEndpoints.Items}`, { params })

  changeStatus = (params: ChangeStatusParams): Promise<IResponse<GetProductsRes>> =>
    this.patch(`${MarketEndpoints.Store}${MarketEndpoints.Items}/${params.id}${MarketEndpoints.Status}`, params.data)

  addProduct = (params: MakeProductParams): Promise<IResponse<Product>> =>
    this.post(`${MarketEndpoints.Store}${MarketEndpoints.Items}`, params)

  editProduct = (params: EditProductParams): Promise<IResponse<Product>> =>
    this.put(`${MarketEndpoints.Store}${MarketEndpoints.Items}/${params.id}`, params.data)

  getTransactions = (params: GetTransactionsParams): Promise<IResponse<GetTransactionsRes>> =>
    this.get(`${MarketEndpoints.Store}${MarketEndpoints.Transactions}`, { params })

  editTransactionStatus = (params: ChangeTransactionStatusParams): Promise<IResponse<Transaction>> =>
    this.patch(
      `${MarketEndpoints.Store}${MarketEndpoints.Transactions}/${params.id}${MarketEndpoints.State}`,
      params.info,
    )
}

export const marketApi = new MarketApi(config)
