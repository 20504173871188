export const teacherSingleDictionary = {
  headerTitle: "Guruhlar",
  groupTitle: "Guruh nomi",
  theme: "Mavzu",
  date: "Sana",
  lessonTime: "Dars vaqti",
  startedAt: "Boshlangan sana",
  endedAt: "Tuga(ydi)gan sana",
  studentsCount: "O'quvchlar soni",
  failedStudentsCount: "Yiqilganlar soni",
  iAssistants: "Assistantlari",
  iAssistantsCount: "Assistantlari soni",
  eCreatedAt: "Yaratilgan vaqti",
  createdAtTime: "Yo'qlama vaqti",
  iPhoto: "Rasmi",
  photoTime: "Rasm vaqti",
  iFirstName: "Ismi",
  iLastName: "Familiyasi",
  attachedPerson: "Biriktirgan shaxs",
  ratingResult: "Guruhdagi reyting natijasi",
  nowInActive: "Boshlanmagan",
  nowActive: "Davom etmoqda",
  ended: "Tugagan",
}
