import { IResponse } from "@/api/types"
import { dictionaryWithKeys } from "@/utils"
import { Endpoints } from "../endpoints"
import { INetworkConfig, Instance } from "../instance"
import { UsersEndpoints } from "./endpoints"
import {
  IBlockStaffRequest,
  IResetPasswordRequest,
  IResetPasswordResponse,
  ISuccessResponse,
  ITeacherPassword,
  ITeacherProfileAdd,
  ITeacherResponse,
  ITeacherRoles,
  ITeachersParams,
  ITeachersSignUp,
} from "./types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class TeacherApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getTeachers = (params: ITeachersParams): Promise<IResponse<ITeacherResponse>> =>
    this.get(dictionaryWithKeys(UsersEndpoints.Teachers), {
      params: {
        page: params.page,
        perPage: params.perPage,
        keys: params.keys,
        isActive: params.isActive,
        workStatus: params.workStatus,
        value: params.value,
        orderBy: params.orderBy,
      },
    })

  getTeacherRoles = (params: ITeachersParams): Promise<IResponse<ITeacherRoles>> =>
    this.get(dictionaryWithKeys(UsersEndpoints.TeacherRoles), {
      params: {
        page: params.page,
        perPage: params.perPage,
        keys: params.keys,
        value: params.value,
      },
    })

  addProfileTeacher = (params: ITeacherProfileAdd): Promise<IResponse<ITeacherPassword>> =>
    this.post(`${UsersEndpoints.TeachersSignUp}/${params.id}`, {
      login: params.login,
      roleId: params.roleId,
    })

  editTeacher = (params: IBlockStaffRequest): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${UsersEndpoints.Teacher}/${params.id}`, {
      isActive: params.isActive,
      login: params.login,
    })

  loginGenerated = (params: IBlockStaffRequest): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${UsersEndpoints.Teacher}/${params.id}`, {
      login: params.login,
    })

  signUpTeachers = (params: ITeachersSignUp): Promise<IResponse<ITeacherResponse>> =>
    this.put(`${UsersEndpoints.TeachersSignUp}/${params.id}`, {
      login: params.login,
    })

  resetPassword = (params: IResetPasswordRequest): Promise<IResponse<IResetPasswordResponse>> =>
    this.put(dictionaryWithKeys(`${UsersEndpoints.TeachersResetPassword}/${params.userId}`))
}

export const teacherApi = new TeacherApi(config)
