import { observer } from "mobx-react"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Avatar, Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import DefaultUserImg from "@/assets/img/img_avatar.png"
import { CalendarDay } from "@/components/CalendarDay"
import mainDictionary from "@/dictionary"
import { makeFileUrl } from "@/utils"
import MoreIcon from "@/assets/icons/more-icon.svg"

import styles from "./teacher.module.scss"

const cn = classNames.bind(styles)

type Props = {
  img?: string
}

export const Teacher = observer(({ img }: Props) => {
  console.log("")

  return (
    <Flex className={cn("teacher")} align="flex-start">
      <Flex className={cn("teacher__wrapper")} vertical gap={20}>
        <Flex justify="space-between" className={cn("teacher__left")} align="center">
          <Flex gap={12} align="center">
            <Avatar
              src={makeFileUrl(img || "")}
              size={60}
              icon={<img src={DefaultUserImg} alt="avatar" width={60} height={60} />}
            />

            <Typography.Title className={cn("teacher__name")} level={5}>
              Azizbek Tursunov
            </Typography.Title>
          </Flex>

          <Button icon={<img src={MoreIcon} />} type="text" shape="circle" />
        </Flex>

        <Flex vertical gap={10}>
          <Typography.Text>{mainDictionary.payed} 1160 s/10 600 000 so‘m</Typography.Text>
          <Typography.Text>{mainDictionary.didNotPay} 1160 s/10 600 000 so‘m</Typography.Text>
        </Flex>
      </Flex>

      <Flex className={cn("teacher__right")} vertical gap={16}>
        <Flex align="center" gap={20}>
          <Button icon={<LeftOutlined />} shape="circle" />

          <Typography.Text>2-o‘quv oyi</Typography.Text>

          <Button icon={<RightOutlined />} shape="circle" />
        </Flex>

        <Flex wrap align="center" gap={10}>
          {Array(31)
            .fill("2")
            .map((item, index) => (
              <CalendarDay key={index} category="came" />
            ))}
        </Flex>
      </Flex>
    </Flex>
  )
})
