import mainDictionary from "@/dictionary"

const pointerStyle = {
  cursor: "pointer",
}

export const conditionalRowStyles = [
  {
    when: (row: any) => row,
    style: {
      backgroundColor: "#fa6e77",
      "&:hover": pointerStyle,
      color: "#fff",
    },
  },
]

export const conditionalRowStylesForHomework = [
  {
    when: (row: any) => row,
    style: {
      backgroundColor: "#FFF1CC",
      "&:hover": pointerStyle,
    },
  },
]

export const AllOption = {
  label: mainDictionary.allOfThem,
  value: null,
}

export const UNIQUE_KEY = "UNIQUE_KEY"
