import { TabsProps, TreeSelect } from "antd"
import { GroupStatus } from "@/api/gamification"
import mainDictionary from "@/dictionary"
import { StudentStatus } from "../Users/Students/types"
import { ByAction } from "./GroupActions/ByAction"
import { ByGroup } from "./GroupActions/ByGroup"
import { SortStatistic } from "./types"

export const ONLY_NUMBER = /^[0-9]*$/
export const MIN_LEVEL = 0

export const groupActionTabs: TabsProps["items"] = [
  {
    key: "1",
    label: mainDictionary.byGroup,
    children: <ByGroup />,
  },
  {
    key: "2",
    label: mainDictionary.byAction,
    children: <ByAction />,
  },
]

export const groupActionsStatus = [
  {
    label: mainDictionary.all,
    value: null,
  },
  {
    label: mainDictionary.notStarted,
    value: GroupStatus.NotStarted,
  },
  {
    label: mainDictionary.active,
    value: GroupStatus.ACTIVE,
  },
  {
    label: mainDictionary.ended,
    value: GroupStatus.ENDED,
  },
]

export const groupStatusColor = ["secondary", "warning", "success", "danger"]

export const { SHOW_PARENT } = TreeSelect

export const statuses = [
  {
    label: mainDictionary.xp,
    value: SortStatistic.XP,
  },
  {
    label: mainDictionary.level,
    value: SortStatistic.LEVEL,
  },
  {
    label: mainDictionary.silver,
    value: SortStatistic.COIN,
  },
]

// Custom id for all
export const ALL_ID = "all"

export const studentStatuses = [
  { label: mainDictionary.all, value: null },
  { label: mainDictionary.blocked, value: StudentStatus.InActive },
  { label: mainDictionary.didNotBlock, value: StudentStatus.Active },
]
