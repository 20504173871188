export const generalDictionary = {
  okText: "Ha",
  cancelText: "Yo'q",
  login: "Kirish",
  noInternet: "Internet bilan biron muammo bor.",
  somethingWentWrong: "Biror narsa noto'g'ri ketdi",
  fullname: "Ism-familiya",
  password: "Parol",
  action: "Harakatlar",
  active: "Faol",
  activeUsers: "Faollar",
  finishedUsers: "Tugatganlar",
  disActive: "Faol emas",
  photo: "Rasm",
  status: "Status",
  erpStatus: "Erp holat",
  users: "Foydalanuvchilar",
  students: "O`quvchilar",
  teachers: "O'qituvchilar",
  nope: "Yo’q",
  add: "Qo’shish",
  save: "Saqlash",
  yes: "Ha",
  no: "Yo'q",
  cancel: "Bekor qilish",
  unknown: "Noma'lum",
  branches: "Filiallar",
  all: "Barchasi",
  userPhoto: "Foydalanuvchi surati",
  order: "#",
  filter: "Filterlash",
  calculatedKPIDate: "KPI hisoblangan sana",
  switchOn: "Yoqish",
  device: "Qurilma",
  studentStatus: "Statusi",
  addNewStudent: `Yangi o'quvchi qo'shish`,
  errorDownloadingFile: "Error downloading file",
}
