import { Endpoints, INetworkConfig, Instance, IResponse } from "@/api"
import { PaymentsRes, RevenueAndPaymentsReqParams } from "@/types"
import { PaymentEndpoints } from "./endpoints"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class PaymentsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getRevenueAndPayments = (params: RevenueAndPaymentsReqParams): Promise<IResponse<PaymentsRes>> =>
    this.get(PaymentEndpoints.Payments, { params })
}

export const paymentsApi = new PaymentsApi(config)
