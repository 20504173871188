import { makeAutoObservable } from "mobx"
import { profileApi } from "@/api/profile"
import { IChangeLoginRequest, IChangePasswordValues } from "./types"

class ProfileStore {
  changeLogin = false
  changePassword = false

  constructor() {
    makeAutoObservable(this)
  }

  changeLoginRequest = (params: IChangeLoginRequest) => profileApi.changeLogin(params)

  changePasswordRequest = (params: IChangePasswordValues) => profileApi.changePassword(params)

  setChangeLogin = (params: boolean) => {
    this.changeLogin = params
  }

  setChangePassword = (params: boolean) => {
    this.changePassword = params
  }

  reset = () => {
    this.changeLogin = false
    this.changePassword = false
  }
}

export const profileStore = new ProfileStore()
