import { useEffect, useMemo, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import { CloseOutlined, SearchOutlined } from "@ant-design/icons"
import { Badge, Button, Carousel, Drawer as AntdDrawer, Flex, Input, Layout as AntdLayout } from "antd"
import { CarouselRef } from "antd/lib/carousel"
import classnamesBind from "classnames/bind"
import BellIcon from "@/assets/icons/bell.svg?react"
import { MyNotification } from "@/components/MyNotification"
import { Message } from "@/components/MyNotification/Message"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { useDeviceMediaQuery } from "@/utils"
import { Notification } from "../../Notification"
import { Drawer } from "../Drawer"

import styles from "./header.module.scss"

const cn = classnamesBind.bind(styles)

const INTERVAL_DELAY = 5_000

export const Header = observer(() => {
  const { appStore, kinescopeStore, notificationStore } = useStores()
  const carouselRef = useRef<CarouselRef>(null)
  const [hasUploadingFiles, setHasUploadingFiles] = useState(false)
  const [isAdaptiveHeight, setIsAdaptiveHeight] = useState(false)
  const { isMobile } = useDeviceMediaQuery()
  const [uploadingFilesLinks, setUploadingFilesLinks] = useState(kinescopeStore.getUploadingFilesLinks())

  const menuItems = useMemo(
    () =>
      uploadingFilesLinks.map((page) => ({
        key: page.link!,
        label: <Link to={page.link!}>{page.name || page.link}</Link>,
      })),
    [uploadingFilesLinks?.length],
  )

  const handleBadgeClick = () => {
    notificationStore.setIsDrawerOpen(true)
  }

  const handleClose = () => {
    notificationStore.setIsDrawerOpen(false)
  }

  useEffect(() => {
    const id = window.setInterval(() => {
      const hasUploading = kinescopeStore.hasUploadingFiles()

      setHasUploadingFiles(hasUploading)

      if (hasUploading) {
        setUploadingFilesLinks(kinescopeStore.getUploadingFilesLinks())
      }
    }, INTERVAL_DELAY)

    return () => {
      clearInterval(id)
    }
  }, [])

  useEffect(() => {
    if (notificationStore.singleNotificationId) {
      carouselRef.current?.next()
      setIsAdaptiveHeight(true)
    } else {
      carouselRef.current?.prev()
      if (carouselRef.current?.prev()) {
        setIsAdaptiveHeight(false)
      }
    }
  }, [notificationStore.singleNotificationId])

  return (
    <AntdLayout.Header className={cn("header__wrapper")}>
      <Flex justify="space-between" align="center" gap="middle" className={cn("header")}>
        <div className={cn("header__left")}>
          {isMobile && <Drawer />}

          <Flex gap="8px" align="center">
            <SearchOutlined />
            <Input variant="borderless" placeholder={mainDictionary.search} />
          </Flex>
        </div>

        <Flex gap="middle">
          <Badge overflowCount={9} count={appStore.notificationsCount}>
            <Button icon={<BellIcon />} type="text" onClick={handleBadgeClick} size="large" />
          </Badge>

          {hasUploadingFiles && (
            <Notification hasUploadingFiles={hasUploadingFiles} dropdownMenu={{ items: menuItems }} />
          )}
        </Flex>
      </Flex>
      <AntdDrawer
        title={mainDictionary.notifications}
        closeIcon={<CloseOutlined className={cn("header__close-icon")} />}
        placement="right"
        className={cn("header__drawer")}
        onClose={handleClose}
        open={notificationStore.isDrawerOpen}
        width={653}
      >
        <Carousel ref={carouselRef} adaptiveHeight={isAdaptiveHeight}>
          <MyNotification />
          <Message />
        </Carousel>
      </AntdDrawer>
    </AntdLayout.Header>
  )
})
