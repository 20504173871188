import { observer } from "mobx-react"
import { Modal } from "antd"
import mainDictionary from "@/dictionary"
import { AddVideo, AddVideoProps } from "./AddVideo"

type Props = AddVideoProps & {
  isOpen?: boolean
  onOk?: () => void
  onClose?: () => void
}

export const AddVideoModal = observer(({ isOpen = true, onOk, onClose, groupId, ...rest }: Props) => (
  <Modal open={isOpen} title={mainDictionary.add} width="70%" footer={null} onCancel={onClose}>
    <AddVideo {...rest} onClose={onClose} groupId={groupId} />
  </Modal>
))
