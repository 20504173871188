import { isValidElement } from "react"
import { message, MessageArgsProps } from "antd"
import { isAxiosError } from "axios"
import mainDictionary from "@/dictionary"
import { ErrorMessage } from "./ErrorMessage"
import { NotificationMessageType, NotificationParams } from "./types"

const TYPE_ERROR = "TypeError"

export const addNotification = ({ messageType, message: content, ...rest }: NotificationParams) => {
  const isError = content instanceof Error

  if (isError && content.name === TYPE_ERROR) {
    return
  }

  const isAxiosErrors = isAxiosError(content)
  const hasError = isError || isAxiosErrors
  const notificationType: NotificationMessageType = hasError
    ? NotificationMessageType.Error
    : messageType || NotificationMessageType.Error

  const renderMessageContent = () => {
    if (hasError) {
      return <ErrorMessage error={content} />
    }

    if (isValidElement(content)) {
      return content
    }

    return content ? <code>{content.toString()}</code> : mainDictionary.unknownError
  }

  message.open({
    ...(rest as MessageArgsProps),
    content: renderMessageContent(),
    type: notificationType,
    icon: null,
  })
}
