export const settingsDictionary = {
  auto: "Avto",
  block: "Bloklanadi",
  blocking: "Bloklash",
  byHand: "Qo'lda",
  conditionInStatistics: "Statistikalardagi holati",
  confirmDelete:
    "Ushbu status o’chirilsa, bu statusdagi o’quvchilar ham boshqa statusga o’tib qoladi. O’chirishga aminmisiz?",
  fulfillmentType: "Bajarilish turi",
  groupOver: "Guruh tugasa",
  finishThemSelves: "O’zi tugatsa",
  finishGroupAndThemselves: "Guruh ham o'zi ham tugamasa",
  didNotStudy: "Bir kunam o'qimasa",
  debtor: "Qarzdor bo'lsa",
  settingsDeadline: "Qancha muddat(kun)gacha (agar kiritilmasa, doimiy sifatida qabul qilinadi)",
  notBlocked: "Bloklanmaydi",
  notCount: "Hisoblanmaydi",
  privateAccount: "Shaxsiy kabinet",
  privateAccountBlock: "Shaxsiy kabinetda bloklanishi",
  countInStatistics: "Statistikalarda hisoblanishi",
  studentCount: "Hisoblanadi",
  designation: "Belgilanishi",
  faceId: "Face Id",
  settings: "Sozlamalar",
  stopStudying: "O'qishni to'xtatish",
  studentsStatisticsInGroup: "O'quvchilarning guruhlardagi statuslari",
  studentStatisticsInGroup: "O'quvchilarning guruhlardagi statuslari",
  until: "Muddat",
  permanent: "Doimiy",
  contracts: "Shartnomalar",
  addingContract: "Shartnoma qo‘shish",
  editingContract: "Shartnoma o‘zgartirish",
  contractDocxRequirement: "Faqat DOCX formatdagi file yuklay olasiz",
  contractFile: "Shartnoma fayli",
  downloader: "Yuklovchi",
  contractHistory: "Shartnoma Tarixi",
  contract: "Shartnoma",
  signedPerson: "Imzolaltgan shaxs",
  signingContract: "Shartnoma imzolatish",
  signedBySelf: "O`ziga imzolatish",
  signedByAgent: "Vakiliga imzolatish",
  passportSeries: "Passport seriyasi",
  passportNumber: "Passport raqami",
  givenPerson: "Kim tomonidan berilgan",
  address: "Yashash manzili",
  agent: "Ma`sul shaxs",
  agentPhoneNumber: "Ma`sul shaxs telefon raqami",
  signing: "Imzolatish",
  whoIsAgent: "O`quvchiga kim bo`ladi",
  singed: "Imzolangan",
  contractWaiting: "Imzolashni kutyabti",
  successfullySent: "Muvaffaqiyatli jo`natildi",
  requiredField: "To`ldirish shart",
  requiredFileUpload: "File yuklash majburiy",
  self: "O`ziniki",
  finance: "Moliya",
  prices: "Narxlar",
  discounts: "Chegirmalar",
  additionalFees: "Qo‘shimcha to‘lovlar",
  additionalFeesAmount: "Summa",
  enterAmount: "Summani kiriting",
  payments: "To`lovlar",
  tariffs: "Tariflar",
  autoRelease: "Hisob. avto. chiqarish",
  otherInfo: "Boshqa ma`lumotlar",
  enterName: "Nom kiriting",
  forHoursPrice: "Soatlar bo`y. narxlar",
  forDaysPrice: "Kunlar bo`y. narxlar",
  forCalendarPrice: "Kalendar bo`y. narxlar",
  tableName: "Ism",
  perDayCost: "Bir kunlik xarajat",
  settingsDaily: "Kunli",
  settingsPackage: "Paketli",
  days: "Kunlar",
  deadlineDay: "Amal qilish muddati (kun)",
  canBePaidPart: "Qisman to`lanishi mumkin",
  discount: "Chegirma",
  add: "Qo‘shish",
  type: "Turi",
  size: "Hajmi",
  cancellation: "Bekor qilish",
  save: "Saqlash",
  name: "Nomi",
  amount: "Summa",
  choose: "Tanlang",
  paymentType: "To‘lov turi",
  paymentMethod: "To‘lov usuli",
  tag: "Teg",
  unity: "Birlik",
  tariffForAcademicHour: "Akademik uchun tarif. soat",
  enableAutoRelease: "Avtomatik hisob-kitobni yoqish:",
  invoices: "Hisob-fakturalar",
  daysBeforeInvoice: "Hisob-fakturani rasmiylashtirish uchun zarur bo`lgan qarzni to`lashdan oldingi kunlar soni",
  invoiceDescription:
    "Guruhdagi talabaning narxi ma`lum bo`lsa, har kecha hisob-faktura beriladi. Narx paket yoki kalendar narxi bo`lishi kerak.     “Berish uchun hisobvaraq-fakturalar” tugmasini bosish orqali yaqin vaqt ichida beriladigan schyot-fakturalarni ko‘rishingiz mumkin",
  productCategory: "Mahsulot toifasi",
  forename: "Nom",
  printReceipts: "Kvitansiyalarni chop etish",
  creditRecipient: "Qabul qiluvchining hisobiga kredit",
  defaultPurchasing: "Sotib olayotganda sukut bo‘yicha foydalaning",
  changeDefaultCalendar: "Kalendar to‘lovlari uchun standart muddatni oyning birinchi kuniga o‘tkazing",
  allowBookSales: "Kitoblarni sotishda yo‘qotishlarga yo‘l qo‘yish",
  newPaymentsPaidDefault: 'Yangi to‘lovlar uchun sukut bo‘yicha "To‘langan" katagiga belgi qo‘ying',
  newPaymentAmounts: "Yangi to‘lov summalarini yaxlitlash",
  teachersAdvances: "O‘qituvchilarning avanslari miqdori (%)",
  defaultPaymentMethod: "Birlamchi toʻlov usuli",
  billingAddress: "Toʻldirish uchun toʻlov manzili*",
  paymentAddressCourses: "Kurslar uchun toʻlovni amalga oshirishda toʻlov manzili*",
  enterPercentage: "Foizni kiriting",
  enterAddress: "Manzilni kiriting",
  fixed: "Fiksirlangan",
  percent: "Foizda",
  sureDeleteDiscount: "Chegirmani o`chirishni tasdiqlaysizmi",
  percentValidationMessage: "Foizning qiymati 1 va 100 orasida bo`lishi kerak",
  minutes: (minutes: number) => `(${minutes} min.)`,
  academicHour: "Akademik soat",
  calculatePayment: "To`lovni hisoblash",
  dayTypeLesson: "dars",
  freeLesson: "Bepul dars",
  chargeableAbsence: "haq to`lanadigan yo`qligi",
  nonChargeableAbsence: "to`lanmagan yo`qligi",
  addTariff: "Stavka qo`shish",
  tariffAmount: "Stavka (so`m)",
  minCountOfStudents: "Minimum talabalar soni",
  studentForTariff: "talaba",
  mustBeGreaterThanPrevious: "Avvalgi qiymatdan katta bo`lishi kerak",
}
