export const profileDictionary = {
  changePassword: "Parolni o`zgartirish",
  changeLogin: "Loginni o`zgartirish",
  enterPassword: "Yangi parolni tasdiqlash",
  enterLogin: "Login ni kiriting",
  enterCurrentPassword: "Hozirgi parol",
  enterNewPassword: "Yangi parolni kiriting",
  successfullyChangedPassword: "Parol muvaffaqiyatli o`zgartirildi",
  successfullyChangedLogin: "Login muvaffaqiyatli o`zgartirildi",
}
