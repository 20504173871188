import { useNavigate } from "react-router-dom"
import { Flex, Tag, Typography } from "antd"
import classNames from "classnames/bind"
import { NotificationActionTypes, NotificationBodyTypes } from "@/api/notifications"
import { ROUTES } from "@/constants"
import { addCatchNotification } from "@/modules/notifications"
import { GroupPageState } from "@/pages/Groups"
import { useStores } from "@/stores"
import { groupsStore } from "@/stores/groups"
import { formatDate } from "@/utils"

import styles from "./notification.module.scss"

const cx = classNames.bind(styles)

type Props = {
  title: string
  isRead: boolean
  id: number
  createdAt?: string
  bodyType: NotificationBodyTypes
  notificationActionType?: NotificationActionTypes
  body?: string
}

export const Notification = ({ title, isRead, id, createdAt, bodyType, notificationActionType, body }: Props) => {
  const { notificationStore, appStore } = useStores()
  const navigate = useNavigate()

  const handleClick = async () => {
    if (!isRead) {
      await notificationStore.readNotification(id)
      notificationStore.setMyNotificationLoader(true)
      await appStore.getNotificationsCount()

      notificationStore.getAllNotifications({
        page: notificationStore?.myNotificationsPage,
        perPage: 10,
      })
    }

    if (bodyType === NotificationBodyTypes.HTML) {
      notificationStore.setSingleNotificationId(id)

      return
    }

    try {
      const parsedBody = JSON.parse(body || "")

      if (notificationActionType === NotificationActionTypes.ADD_GROUP_STAFF) {
        notificationStore.setIsDrawerOpen(false)
        navigate(ROUTES.myGroups)

        return
      }

      if (!(parsedBody?.scheduleItemId && parsedBody?.groupId)) {
        return
      }

      const link = ROUTES.myGroupsSingle
        .replace(":id", parsedBody?.groupId)
        .replace(":staffId", "7")
        .replace(":scheduleId", parsedBody?.scheduleItemId)

      if (notificationActionType === NotificationActionTypes.ATTENDANCE_STAFF) {
        notificationStore.setIsDrawerOpen(false)
        navigate(link)

        return
      }

      if (notificationActionType === NotificationActionTypes.HOMEWORK_STAFF) {
        groupsStore.setGroupSubPage(GroupPageState.Homework)
        notificationStore.setIsDrawerOpen(false)
        navigate(link)

        return
      }

      if (
        notificationActionType === NotificationActionTypes.CHECK_HOMEWORK_STAFF &&
        parsedBody?.scheduleItemId &&
        parsedBody?.groupId &&
        parsedBody?.lessonId &&
        parsedBody?.homeworkId &&
        parsedBody?.homeworkChatId &&
        parsedBody?.senderId
      ) {
        const homeworkViewLink = ROUTES.myHomeworkSingleView
          .replace(":id", parsedBody?.groupId)
          .replace(":staffId", "7")
          .replace(":scheduleId", parsedBody?.scheduleItemId)
          .replace(":lessonId", parsedBody?.lessonId)
          .replace(":homeworkId", parsedBody?.homeworkId)
          .replace(":chatId", parsedBody?.homeworkChatId)
          .replace(":senderId", parsedBody?.senderId)

        notificationStore.setIsDrawerOpen(false)
        navigate(homeworkViewLink)

        return
      }

      if (
        notificationActionType === NotificationActionTypes.CHECK_EXAM_STAFF &&
        parsedBody?.scheduleItemId &&
        parsedBody?.groupId &&
        parsedBody?.examId &&
        parsedBody?.examChatId &&
        parsedBody?.examChatId &&
        parsedBody?.studentId
      ) {
        const examViewLink = ROUTES.myExamView
          .replace(":id", parsedBody?.groupId)
          .replace(":staffId", "7")
          .replace(":scheduleId", parsedBody?.scheduleItemId)
          .replace(":chatId", parsedBody?.examChatId)
          .replace(":id", parsedBody?.examId)
          .replace(":senderId", parsedBody.studentId)

        notificationStore.setIsDrawerOpen(false)
        navigate(examViewLink)

        return
      }

      if (
        notificationActionType === NotificationActionTypes.EXAM_STAFF ||
        notificationActionType === NotificationActionTypes.CREATE_EXAM_STAFF ||
        notificationActionType === NotificationActionTypes.EXAM_HOMEWORK_STAFF
      ) {
        groupsStore.setGroupSubPage(GroupPageState.Attendance)
        notificationStore.setIsDrawerOpen(false)
        navigate(link)
      }
    } catch (err: Error | unknown) {
      addCatchNotification(err)
    }
  }

  return (
    <div onClick={handleClick} className={cx("notification", { "not-read": !isRead })}>
      <Flex align="center" justify="space-between" className={cx("notification__wrapper")}>
        <Flex vertical gap={5}>
          <h4 className={cx("notification__title", { "not-read": !isRead })}>{title}</h4>

          <Typography.Text type="secondary">{formatDate(createdAt, undefined, true)}</Typography.Text>
        </Flex>

        <Tag color="red" className={cx("notification__badge", { "not-read": isRead })} />
      </Flex>
    </div>
  )
}
