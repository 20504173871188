import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const TeacherSingle = lazy(() =>
  import("./Teachers/TeacherSingle")
    .then(({ TeacherSingle }) => ({ default: TeacherSingle }))
    .catch(handleCatchChunkError),
)

export const Assistants = lazy(() =>
  import("./Assistants").then(({ Assistants }) => ({ default: Assistants })).catch(handleCatchChunkError),
)

export const AssistantSingle = lazy(() =>
  import("./Assistants/AssistantSingle")
    .then(({ AssistantSingle }) => ({ default: AssistantSingle }))
    .catch(handleCatchChunkError),
)

export const Teachers = lazy(() =>
  import("./Teachers").then(({ Teachers }) => ({ default: Teachers })).catch(handleCatchChunkError),
)

export const UsersAcademic = lazy(() =>
  import("./Academic").then(({ Academic }) => ({ default: Academic })).catch(handleCatchChunkError),
)

export const UsersAcademicEdit = lazy(() =>
  import("./Academic/AcademicEdit")
    .then(({ AcademicEdit }) => ({ default: AcademicEdit }))
    .catch(handleCatchChunkError),
)

export const UsersAcademicTeacherGroup = lazy(() =>
  import("./Academic/AcademicSingleTeacher")
    .then(({ AcademicSingleTeacher }) => ({ default: AcademicSingleTeacher }))
    .catch(handleCatchChunkError),
)

export const TeacherAttendance = lazy(() =>
  import("./Teachers/TeacherSingle/TeacherAttendance")
    .then(({ TeacherAttendance }) => ({ default: TeacherAttendance }))
    .catch(handleCatchChunkError),
)

export const UsersStaff = lazy(() =>
  import("./Staff").then(({ Staff }) => ({ default: Staff })).catch(handleCatchChunkError),
)

export const UsersStaffEdit = lazy(() =>
  import("./Staff/StaffEdit").then(({ StaffEdit }) => ({ default: StaffEdit })).catch(handleCatchChunkError),
)

export const Students = lazy(() =>
  import("./Students").then(({ Students }) => ({ default: Students })).catch(handleCatchChunkError),
)

export const StudentMore = lazy(() =>
  import("./Students/SingleStudent")
    .then(({ SingleStudent }) => ({ default: SingleStudent }))
    .catch(handleCatchChunkError),
)

export const StudentAdd = lazy(() =>
  import("./Students/StudentAdd").then(({ StudentAdd }) => ({ default: StudentAdd })).catch(handleCatchChunkError),
)

export const PaymentHistory = lazy(() =>
  import("./Students/SingleStudent/PaymentHistory")
    .then(({ PaymentHistory }) => ({ default: PaymentHistory }))
    .catch(handleCatchChunkError),
)
