import { API_URL, APP_PREFIX, CDN_HOST, STUDENT_APP_URL } from "@/constants/env.ts"

export enum Endpoints {
  Base = "/api/staff",
  Tus = "/tus",
  CreateVideoForAdmin = "/video/create-for-admin",
  CreateVideoForAcademic = "/video/create-for-academic",
  VideoUpdateStatusForAdmin = "/video/update-status-for-admin",
  VideoUpdateStatusForAcademic = "/video/update-status-for-academic",
  FaceControl = "/face-control",
  GetLogImage = "/get-log-image",
  HikvisionDevicePhotoLog = "/face-control/get-log-image",
  ControlActions = "/control-actions",
  UpdateStudentGroupActionControl = "/update-student-group-action-control",
  Results = "/results",
  Announce = "/announce",
  Store = "/store",
  Items = "/items",
  Status = "/status",
  Transactions = "/transactions",
  State = "/state",
  Certificates = "/certificates",
  Notifications = "/notifications",
  GetAllForAdmin = "/get-all-for-admin",
  GetForAdmin = "/get-for-admin",
  UploadNotificationBanner = "/upload/notification-banners",
  uploadEventBanner = "/upload/events-banners",
  Delete = "/delete",
  TemplatesGetAll = "/templates/get-all",
  TemplatesCreate = "/templates/create",
  Templates = "/templates",
  GetAll = "/get-all",
  Read = "/read",
  AuthLogout = "/auth/logout",
  callHistoriesAll = "/call-histories/all",
  StudentGroupsInfoFile = "/student-groups/info-file",
  AuditsPermissionNames = "/audits/permission/names",
  HomeworkDbLessons = "/homework-db/lessons",
  HomeworkDb = "/homework-db",
  SetLessons = "/set-lessons",
  ContractsAll = "/contracts/all",
  Contracts = "/contracts",
  ContractAttachment = "/contract/attachment",
  ContractsCreate = "/contracts/create",
  History = "/history",
  Settings = "/settings",
  Submissions = "/submissions",
  Move = "/move",
  ByCurriculumId = "/by-curriculum-id",
  AttendanceSaveAcademic = "/attendance/save-academic",
  MobileAppVersions = "/versions/all",
  CreateMobileAppVersion = "/versions/create",
}

export const stage = {
  apiUrl: API_URL,
  cdnHost: CDN_HOST,
  crmLink: APP_PREFIX,
  studentAppUrl: STUDENT_APP_URL,
}
