import { useEffect } from "react"
import { observer } from "mobx-react"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { SingleNotification } from "@/modules/SingleNotification"
import { useStores } from "@/stores"
import { breadcrumbStore } from "@/stores/breadcrumb"

export const Message = observer(() => {
  const { notificationStore } = useStores()
  const singleNotification = notificationStore?.singleNotification

  useEffect(() => {
    if (!notificationStore.singleNotificationId) return

    notificationStore.getAcademicNotification(notificationStore.singleNotificationId)

    return () => {
      notificationStore.setNotification(null)
    }
  }, [notificationStore.singleNotificationId])

  useEffect(() => {
    const details = [
      {
        label: mainDictionary.notifications,
        link: ROUTES.myNotification,
      },
      {
        label: singleNotification?.title,
      },
    ]

    breadcrumbStore.addBreadcrumbs(details)

    return () => {
      breadcrumbStore.clearBreadcrumbs()
    }
  }, [singleNotification?.title])

  return (
    <SingleNotification
      title={singleNotification?.title || ""}
      banner={singleNotification?.banner || ""}
      body={singleNotification?.body || ""}
      createdAt={singleNotification?.createdAt || ""}
    />
  )
})
