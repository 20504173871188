import { makeAutoObservable, observable } from "mobx"
import { authApi } from "@/api"
import { STAFF_INFO, TOKEN_NAME } from "@/constants"
import { storage } from "@/utils/storage"
import { SignInParamsType, TokenType } from "./types"

class AuthStore {
  isAuth: boolean | null = null
  token: TokenType | null = null
  requestLoading = false

  constructor() {
    makeAutoObservable(this, {
      isAuth: observable,
    })

    const accessToken = storage.get(TOKEN_NAME.accessToken)
    const refreshToken = storage.get(TOKEN_NAME.refreshToken)

    if (accessToken && refreshToken) {
      this.setToken({
        accessToken,
        refreshToken,
      })

      this.setIsAuth(true)
    }
  }

  logOut = () => authApi.logOut()

  setRequestLoading = (params: boolean) => {
    this.requestLoading = params
  }

  getRefreshToken = () => authApi.getRefreshToken()

  getSignIn = ({ login, password, fcmToken }: SignInParamsType) =>
    authApi.getSignIn({ login, password, fcmToken }).then((res) => {
      if (res.success) {
        this.setToken({
          accessToken: res.data.accessToken,
          refreshToken: res.data.refreshToken,
        })

        storage.set(TOKEN_NAME.accessToken, res.data.accessToken)
        storage.set(TOKEN_NAME.refreshToken, res.data.refreshToken)
        storage.set(STAFF_INFO, JSON.stringify(res.data.staffInfo))

        this.setIsAuth(true)
      }

      return res
    })

  setToken = (token: TokenType) => {
    this.token = token
  }

  setIsAuth = (isAuth: boolean) => {
    this.isAuth = isAuth
  }

  clearTokensLocalStorage = () => {
    storage.removeItems([STAFF_INFO, TOKEN_NAME.refreshToken, TOKEN_NAME.accessToken])
  }

  reset = () => {
    this.isAuth = null
    this.token = null
    this.clearTokensLocalStorage()
  }
}

export const authStore = new AuthStore()
