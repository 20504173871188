import { Endpoints, INetworkConfig, Instance, IResponse } from "@/api"
import { AddNewCurrentBranchOfficesParams, OfficesHhResponse, OfficesResponse } from "../types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

const endpoints = {
  offices: "/offices",
  officesHh: "/offices/hh",
}

class BranchOfficeApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getOffices = () => this.get<IResponse<OfficesResponse>>(endpoints.offices)

  changeOffices = (id: number, params: AddNewCurrentBranchOfficesParams) =>
    this.put<IResponse<any>>(`${endpoints.offices}/${id}`, params)

  deleteOffices = (id: number) => this.delete<IResponse<any>>(`${endpoints.offices}/${id}`)

  addNewCurrentBranchOffices = (params: AddNewCurrentBranchOfficesParams) =>
    this.post<IResponse<any>>(endpoints.offices, params)

  getOfficesHh = () => this.get<IResponse<OfficesHhResponse>>(endpoints.officesHh)
}

export const branchOfficeApi = new BranchOfficeApi(config)
