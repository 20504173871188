import { useStores } from "@/stores"
import { Divider, Flex, Form, Input, InputNumber, Modal, Select } from "antd"
import { observer } from "mobx-react"
import mainDictionary from "@/dictionary"
import styles from "./group-make.module.scss"
import classNames from "classnames/bind"
import { useEffect, useMemo, useState } from "react"
import { branchOfficeStore } from "@/pages/Controls/BranchOffice"
import { useBoolean } from "usehooks-ts"

const cn = classNames.bind(styles)

export const GroupMake = observer(() => {
  const { groupsStore, gamificationStore, studyDepartment, notificationStore } = useStores()
  const [form] = Form.useForm()
  const { value: isFetchingCourses, setTrue: setFetchingCourses, setFalse: setNotFetchingCourses } = useBoolean(false)
  const [categoryId, setCategoryId] = useState<number>()

  const branches = useMemo(
    () =>
      branchOfficeStore.branchOffices?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [branchOfficeStore.branchOffices],
  )

  const users = useMemo(
    () =>
      notificationStore.users?.map((item) => ({
        label: item.name,
        value: item.userId,
      })),
    [notificationStore.users],
  )

  const courses = useMemo(() => {
    const courses = studyDepartment.allCourses?.courses?.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    return courses || []
  }, [studyDepartment.allCourses])

  const categories = useMemo(
    () =>
      gamificationStore.categories?.categories?.map((item) => ({
        label: item.displayName,
        value: item.id,
      })),
    [gamificationStore.categories],
  )

  const handleCategoryChange = (value: number) => {
    setCategoryId(value)

    form.setFieldValue("courseId", [])
  }

  const handleCancel = () => {
    groupsStore.setGroupMakeModal(false)
  }

  const prices = useMemo(
    () =>
      groupsStore.coursePrices?.data?.map((item) => ({
        label: item.value.toLocaleString("Fr-fr"),
        value: item.id,
      })),
    [groupsStore.coursePrices],
  )

  useEffect(() => {
    branchOfficeStore.getBranchOffices()
    gamificationStore.getCategories({
      page: 1,
      perPage: 100,
    })

    notificationStore.getAcademics({
      page: 1,
      perPage: 10,
    })

    groupsStore.getCoursePrices({
      page: 1,
      perPage: 100,
    })
  }, [])

  useEffect(() => {
    if (categoryId) {
      setFetchingCourses()

      studyDepartment
        .getCourses({
          page: 1,
          perPage: 100,
          categoryIds: [categoryId],
        })
        .finally(() => setNotFetchingCourses())
    }
  }, [categoryId])

  return (
    <Modal
      width={600}
      className={cn("group-make")}
      title={mainDictionary.addGroup}
      onCancel={handleCancel}
      open={groupsStore.groupMakeModal}
    >
      <Divider />
      <Form layout="vertical">
        <Flex gap={17} justify="space-between">
          <Form.Item className={cn("group-make__item")} name="name" label={mainDictionary.groupName}>
            <Input className={cn("group-make__input")} placeholder={mainDictionary.group} />
          </Form.Item>

          <Form.Item className={cn("group-make__item")} name="officeId" label={mainDictionary.branch}>
            <Select options={branches} placeholder={mainDictionary.branch} />
          </Form.Item>
        </Flex>

        <Flex gap={17} justify="space-between">
          <Form.Item className={cn("group-make__item")} name="category" label={mainDictionary.category}>
            <Select
              onChange={handleCategoryChange}
              options={categories}
              className={cn("group-make__input")}
              placeholder={mainDictionary.category}
            />
          </Form.Item>

          <Form.Item className={cn("group-make__item")} name="courseId" label={mainDictionary.course}>
            <Select
              disabled={isFetchingCourses}
              options={courses}
              className={cn("group-make__input")}
              placeholder={mainDictionary.course}
            />
          </Form.Item>
        </Flex>

        <Flex gap={17} justify="space-between">
          <Form.Item className={cn("group-make__item")} name="assistantId" label={mainDictionary.agent}>
            <Select
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              showSearch
              options={users}
              className={cn("group-make__input")}
              placeholder={mainDictionary.agent}
            />
          </Form.Item>

          <Form.Item className={cn("group-make__item")} name="price" label={mainDictionary.primaryPrice}>
            <Select options={prices} placeholder={mainDictionary.primaryPrice} />
          </Form.Item>
        </Flex>

        <Flex gap={17} justify="space-between">
          <Form.Item className={cn("group-make__item")} name="studentsCount" label={mainDictionary.countOfSeats}>
            <InputNumber min={1} className={cn("group-make__input")} placeholder={mainDictionary.countOfSeats} />
          </Form.Item>

          <Form.Item className={cn("group-make__item")} name="course" label={mainDictionary.groupStatus}>
            <Select placeholder={mainDictionary.groupStatus} />
          </Form.Item>
        </Flex>

        <Form.Item className={cn("group-make__status")} name="course" label={mainDictionary.type}>
          <Select placeholder={mainDictionary.type} />
        </Form.Item>

        <Form.Item label={mainDictionary.description}>
          <Input.TextArea className={cn("group-make__area")} rows={3} />
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  )
})
