import { RcFile } from "antd/es/upload"
import { IUpload, uploadsApi } from "@/api/upload"

export const uploadStaffProfile = async (file: RcFile): Promise<IUpload[]> => {
  const formData = new FormData()

  formData.append("files", file)

  const { data } = await uploadsApi.uploadStaffProfile(formData)

  return data
}

export const uploadContract = async (file: RcFile, onProgress?: (percentage: number) => void): Promise<IUpload[]> => {
  const formData = new FormData()

  formData.append("files", file)

  const { data } = await uploadsApi.uploadContract(formData, onProgress)

  return data
}

export const uploadProductImage = async (file: RcFile): Promise<IUpload[]> => {
  const formData = new FormData()

  formData.append("files", file)

  const { data } = await uploadsApi.uploadProductImage(formData)

  return data
}

export const uploadFile = async (file: RcFile): Promise<IUpload[]> => {
  const formData = new FormData()

  formData.append("files", file)

  const { data } = await uploadsApi.upload(formData)

  return data
}

export const uploadCourseImage = async (file: RcFile): Promise<IUpload[]> => {
  const formData = new FormData()

  formData.append("files", file)

  const { data } = await uploadsApi.uploadCourseImage(formData)

  return data
}
