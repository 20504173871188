import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { GroupSingle } from "./GroupSingle"
import { Groups } from "./lazy"
import { StudentDuplications } from "./StudentDuplications"

export const groupRouter = [
  {
    path: ROUTES.groups,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.schooling}>
        <Groups />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.studentDuplications,
    element: (
      // TODO
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <StudentDuplications />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.singleGroup,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <GroupSingle />
      </AvailableComponent>
    ),
  },
]
