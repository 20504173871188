import { Endpoints, INetworkConfig, Instance, IResponse, ISuccessResponse } from "@/api"
import { IStaffInfo } from "@/stores/auth/types"
import {
  ChangeStudentReq,
  ContractFormValues,
  EditContractReq,
  EditStudentReq,
  GetContractHistoryReq,
  GetContractsAll,
} from "@/stores/settings"
import { MobileAppVersionReq, MobileAppVersionRes } from "@/types"
import { SettingsEndpoints } from "./endpoints"
import {
  AcademicHoursRes,
  AcademicPriceEdit,
  AcademicPrices,
  Contract,
  ContractHistoryRes,
  ContractRes,
  EditFinanceDiscount,
  FinanceDiscounts,
  GamificationOfferingParams,
  GamificationOfferingResponseTypes,
  StudentStatus,
  StudentStatusesRes,
} from "./types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class SettingsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getAcademicHours = (): Promise<IResponse<AcademicHoursRes[]>> => this.get(SettingsEndpoints.AcademicHours)

  getAcademicPrices = (): Promise<IResponse<AcademicPrices[]>> => this.get(SettingsEndpoints.AcademicPrices)

  editAcademicPrice = (params: AcademicPriceEdit): Promise<IResponse<AcademicPrices>> =>
    this.put(`${SettingsEndpoints.AcademicPrices}/${params.id}`, params.data)

  deteteAcademicPrice = (id: number): Promise<IResponse<AcademicPrices>> =>
    this.delete(`${SettingsEndpoints.AcademicPrices}/${id}`)

  addAcademicPrice = (params: AcademicPrices): Promise<IResponse<AcademicPrices>> =>
    this.post(SettingsEndpoints.AcademicPrices, params)

  addFinanceDiscount = (params: FinanceDiscounts): Promise<IResponse<FinanceDiscounts>> =>
    this.post(SettingsEndpoints.FinanceDiscounts, params)

  editFinanceDiscoiunt = (params: EditFinanceDiscount): Promise<IResponse<FinanceDiscounts>> =>
    this.put(`${SettingsEndpoints.FinanceDiscounts}/${params.id}`, params.data)

  deleteFinanceDiscount = (id: number): Promise<IResponse<FinanceDiscounts>> =>
    this.delete(`${SettingsEndpoints.FinanceDiscounts}/${id}`)

  getFinanceDiscounts = (): Promise<IResponse<FinanceDiscounts[]>> => this.get(SettingsEndpoints.FinanceDiscounts)

  changeStudentStatus = (params: ChangeStudentReq): Promise<IResponse<StudentStatus>> =>
    this.put(`${SettingsEndpoints.ControlActions}${SettingsEndpoints.UpdateStudentGroupActionControl}`, params)

  getGamificationOffering = (): Promise<IResponse<GamificationOfferingResponseTypes>> =>
    this.get(SettingsEndpoints.SettingsGamificationOffering)

  getStudentStatuses = (): Promise<IResponse<StudentStatusesRes>> => this.get(SettingsEndpoints.ControlActions)

  getStudentStatus = (id: number): Promise<IResponse<StudentStatus>> =>
    this.get(`${SettingsEndpoints.ControlActions}/${id}`)

  editStudentStatus = (info: EditStudentReq): Promise<IResponse<StudentStatus>> =>
    this.put(`${SettingsEndpoints.ControlActions}/${info.id}`, info.data)

  updateGamificationOffering = (params: GamificationOfferingParams): Promise<IResponse<IStaffInfo>> =>
    this.put(SettingsEndpoints.SettingsGamificationOfferingUpdate, params)

  getContracts = (params: GetContractsAll): Promise<IResponse<ContractRes>> =>
    this.get(Endpoints.ContractsAll, { params })

  addContract = (params: ContractFormValues): Promise<IResponse<ContractRes>> =>
    this.post(Endpoints.ContractsCreate, params)

  editContract = (params: EditContractReq): Promise<IResponse<ContractRes>> =>
    this.put(`${Endpoints.Contracts}/${params.id}`, params.data)

  getContractHistory = (params: GetContractHistoryReq): Promise<IResponse<ContractHistoryRes>> =>
    this.get(`${Endpoints.Contracts}/${params.id}${Endpoints.History}`, { params: params.params })

  getContract = (params: number): Promise<IResponse<Contract>> => this.get(`${Endpoints.Contracts}/${params}`)

  getMobileAppVersions = (params: Partial<MobileAppVersionReq>): Promise<IResponse<MobileAppVersionRes>> =>
    this.get(Endpoints.MobileAppVersions, { params })

  addNewMobileAppVersion = (params: MobileAppVersionReq): Promise<IResponse<ISuccessResponse>> =>
    this.post(Endpoints.CreateMobileAppVersion, params)
}

export const settingsApi = new SettingsApi(config)
