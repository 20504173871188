import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import {
  ByDoneHomeworkStudent,
  Draft,
  HomeworkDetail,
  Lessons,
  LessonsHomework,
  SingleHome,
  SkillClasses,
  StudyDepartment,
  StudyDepartmentCourse,
  StudyDepartmentCourseDragDrop,
  StudyDepartmentCourses,
  StudyDepartmentHomeworkDatabaseChapters,
  StudyDepartmentHomeworkDatabaseChaptersHomework,
  StudyDepartmentHomeworkDatabaseChaptersHomeworkMake,
  StudyDepartmentSource,
  StudyDepartmentTeachersKPI,
  SwitchHomework,
  TeacherStatistics,
} from "./lazy"

export const studyDepartmentRouters = [
  {
    path: ROUTES.studyDepartment,
    children: [
      {
        path: ROUTES.studyDepartmentHomeworks,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.study}>
            <StudyDepartment />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentCoursesSingleSwitchHomework,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.studyCourses}>
            <SwitchHomework />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentTeacherStatistics,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkStatistics}>
            <TeacherStatistics />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentCourses,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.studyCourses}>
            <StudyDepartmentCourses />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentCoursesSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.studyCourses}>
            <StudyDepartmentCourse />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentCoursesSingleDragDrop,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.studyCourses}>
            <StudyDepartmentCourseDragDrop />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentHome,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.allGroupsAllHomeworks}>
            <SingleHome />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentSingleStudent,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkStatistics}>
            <ByDoneHomeworkStudent />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentHomeworkChat,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.allGroupsCheckHomeworks}>
            <HomeworkDetail />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentTeachersKPI,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkStatistics}>
            <StudyDepartmentTeachersKPI />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.studyDepartmentSource,
        children: [
          {
            path: ROUTES.studyDepartmentHomeworkDataBase,
            index: true,
            element: (
              <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkDb}>
                <StudyDepartmentSource />
              </AvailableComponent>
            ),
          },
          {
            path: ROUTES.studyDepartmentHomeworkDataBaseChapters,
            element: (
              <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkDb}>
                <StudyDepartmentHomeworkDatabaseChapters />
              </AvailableComponent>
            ),
          },
          {
            path: ROUTES.studyDepartmentHomeworkDataBaseChaptersHomework,
            element: (
              <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkDb}>
                <StudyDepartmentHomeworkDatabaseChaptersHomework />
              </AvailableComponent>
            ),
          },
          {
            path: ROUTES.studyDepartmentHomeworkDataBaseChaptersHomeworkAdd,
            element: (
              <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkDb}>
                <StudyDepartmentHomeworkDatabaseChaptersHomeworkMake />
              </AvailableComponent>
            ),
          },
          {
            path: ROUTES.studyDepartmentHomeworkDataBaseChaptersHomeworkEdit,
            element: (
              <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkDb}>
                <StudyDepartmentHomeworkDatabaseChaptersHomeworkMake />
              </AvailableComponent>
            ),
          },
          {
            path: ROUTES.studyDepartmentHomeworkDataBaseDraft,
            index: true,
            element: (
              <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkDb}>
                <Draft />
              </AvailableComponent>
            ),
          },
          {
            path: ROUTES.studyDepartmentHomeworkDataBaseDraftLessons,
            index: true,
            element: (
              <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkDb}>
                <Lessons />
              </AvailableComponent>
            ),
          },
          {
            path: ROUTES.studyDepartmentHomeworkDataBaseDraftLessonsHomework,
            index: true,
            element: (
              <AvailableComponent permissionName={permissionObjectTypes.studyHomeworkDb}>
                <LessonsHomework />
              </AvailableComponent>
            ),
          },
        ],
      },
      {
        path: ROUTES.studyDepartmentSkillClasses,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.skillClasses}>
            <SkillClasses />
          </AvailableComponent>
        ),
      },
    ],
  },
]
