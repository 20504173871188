import { EditOutlined } from "@ant-design/icons"
import { Avatar, Button, Flex, Typography } from "antd"
import DefaultUserImg from "@/assets/img/img_avatar.png"
import mainDictionary from "@/dictionary"
import { makeFileUrl } from "@/utils"

type Props = {
  img?: string
  role?: string
  fullName: string
  startedDate?: string
}

export const Teacher = (props: Props) => (
  <Flex gap={40}>
    <Flex gap={12}>
      <Avatar
        src={makeFileUrl(props.img || "")}
        size={60}
        icon={<img src={DefaultUserImg} alt="avatar" width={60} height={60} />}
      />

      <Flex vertical gap={4}>
        <Typography.Text type="success">{props.role}</Typography.Text>

        <Typography.Title level={5}>{props.fullName}</Typography.Title>

        <Typography.Text type="secondary">
          {props.startedDate} {mainDictionary.dateFrom}
        </Typography.Text>
      </Flex>
    </Flex>

    <Button shape="circle" icon={<EditOutlined />} type="text" />
  </Flex>
)
