import { useRoutes } from "react-router-dom"
import { NotFound } from "@/components"
import { ROUTES } from "@/constants"
import { LayoutProtected } from "@/Layout/LayoutProtected"
import { contractsRouter } from "@/pages/Contracts"
import { controlsRouters } from "@/pages/Controls"
import { eventRouter } from "@/pages/Events"
import { financeRouters } from "@/pages/Finance"
import { gamificationRouters } from "@/pages/Gamification"
import { groupRouter } from "@/pages/Groups"
import { Login } from "@/pages/Login"
import { managementRoute } from "@/pages/Management"
import { marketRouter } from "@/pages/Market"
import { notificationsRouters } from "@/pages/Notifications"
import { profileRouters } from "@/pages/Profile"
import { settingsRouters } from "@/pages/Settings"
import { studyDepartmentRouters } from "@/pages/StudyDepartment"
import { userRouters } from "@/pages/Users"
import { ProtectedRoutes } from "./ProtectedRoutes"
import { PublicRoutes } from "./PublicRoutes"
import { LayoutPublic } from "@/Layout/LayoutPublic"
import { analyticsRouter } from "@/pages/Analytics"
import { specialRouter } from "@/pages/Special"

type Props = {
  isAuth: boolean | null
}

export const Router = ({ isAuth }: Props) =>
  useRoutes([
    {
      path: ROUTES.home,
      element: <ProtectedRoutes isAuth={isAuth} />,
      children: [
        {
          path: ROUTES.home,
          element: <LayoutProtected />,
          children: [
            ...managementRoute,
            ...userRouters,
            ...groupRouter,
            ...studyDepartmentRouters,
            ...notificationsRouters,
            ...controlsRouters,
            ...gamificationRouters,
            ...marketRouter,
            ...settingsRouters,
            ...profileRouters,
            ...contractsRouter,
            ...eventRouter,
            ...analyticsRouter,
            ...financeRouters,
            ...specialRouter,
            {
              path: "*",
              element: <NotFound />,
            },
          ],
        },
      ],
    },
    {
      path: ROUTES.signIn,
      element: (
        <LayoutPublic>
          <PublicRoutes isAuth={isAuth} />
        </LayoutPublic>
      ),
      children: [
        {
          index: true,
          element: <Login />,
        },
      ],
    },
  ])
