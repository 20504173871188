import { makeAutoObservable } from "mobx"
import {
  AcademicHoursRes,
  AcademicPriceEdit,
  AcademicPrices,
  Contract,
  ContractHistoryRes,
  ContractRes,
  EditFinanceDiscount,
  FinanceDiscounts,
  GamificationOfferingParams,
  GamificationOfferingResponseTypes,
  settingsApi,
  StudentStatus,
  StudentStatusesRes,
} from "@/api/settings"
import { addCatchNotification } from "@/modules/notifications"
import { PaymentSegmentedValues, pricesFilterSegmentedValue } from "@/pages/Settings/SettingsFinance/constants"
import { MobileAppVersionReqParams, MobileAppVersionRes } from "@/types"
import {
  ChangeStudentReq,
  ContractFormValues,
  EditContractReq,
  EditStudentReq,
  GetContractHistoryParams,
  GetContractHistoryReq,
  GetContractsAll,
} from "./types"

class SettingsStore {
  isUpdateGamificationOffering = false
  isGamificationModal = false
  gamificationOffer: GamificationOfferingResponseTypes | null = null
  editorContent = ""
  studentStatuses: StudentStatusesRes | null = null
  studentStatus: StudentStatus | null = null
  contracts: ContractRes | null = null
  contractsLoading = false
  makeContractModal = false
  selectedContract: Contract | null = null
  contractHistory: ContractHistoryRes | null = null
  contractHistoryLoading = false
  singleContractHistory: Contract | null = null
  contractsFilter: GetContractsAll = {
    page: 1,
    perPage: 10,
  }

  isNewVersionModal = false
  mobileAppVersions: MobileAppVersionRes | null = null
  totalMobileAppVersions = 0
  mobileVersionsPage = 1
  mobileVersionsPerPage = 10
  mobileAppFilterOptions: Partial<MobileAppVersionReqParams> = {
    page: 1,
    perPage: 10,
  }

  contractHistoryFilter: GetContractHistoryParams = {
    page: 1,
    perPage: 10,
  }

  additionalFeesModal = false
  finanPriceModal = false
  pricesFilterSegmentedValue = pricesFilterSegmentedValue.Daily
  isSettingsDiscountModal = false
  tariffsModal = false
  paymentSegmentedValue = PaymentSegmentedValues.PaymentType
  isSettingsPaymentsModal = false
  cagetoriesModal = false
  financeDiscounts: FinanceDiscounts[] = []
  financeDiscountsLoading = false
  financeDiscountsEditData: FinanceDiscounts | null = null
  academicPrices: AcademicPrices[] = []
  academicPricesLoading = false
  academicPriceEditData: AcademicPrices | null = null
  academicHours: AcademicHoursRes[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setAcademicHours = (params: AcademicHoursRes[]) => {
    this.academicHours = params
  }

  setAcademicPrices = (params: AcademicPrices[]) => {
    this.academicPrices = params
  }

  setAcademicPricesLoading = (value: boolean) => {
    this.academicPricesLoading = value
  }

  setAcademicPricesEditData = (params: AcademicPrices | null) => {
    this.academicPriceEditData = params
  }

  setFinanceDiscountsEditData = (params: FinanceDiscounts | null) => {
    this.financeDiscountsEditData = params
  }

  setFinanceDiscountsLoading = (value: boolean) => {
    this.financeDiscountsLoading = value
  }

  setFinanceDiscounts = (params: FinanceDiscounts[]) => {
    this.financeDiscounts = params
  }

  setCategoriesModal = (params: boolean) => {
    this.cagetoriesModal = params
  }

  setIsSettingsPaymentsModal = (params: boolean) => {
    this.isSettingsPaymentsModal = params
  }

  setPaymentSegmentedValue = (params: PaymentSegmentedValues) => {
    this.paymentSegmentedValue = params
  }

  setTariffsModal = (value: boolean) => {
    this.tariffsModal = value
  }

  setAdditionalFeesModal = (value: boolean) => {
    this.additionalFeesModal = value
  }

  setPricesFilterSegmentedValue = (value: pricesFilterSegmentedValue) => {
    this.pricesFilterSegmentedValue = value
  }

  setFinanPriceModal = (value: boolean) => {
    this.finanPriceModal = value
  }

  setIsSettingsDiscountModal = (params: boolean) => {
    this.isSettingsDiscountModal = params
  }

  setContractHistory = (params: ContractHistoryRes | null) => {
    this.contractHistory = params
  }

  setContractHistoryFilter = (params: GetContractHistoryParams) => {
    this.contractHistoryFilter = params
  }

  setSingleContractHistory = (params: Contract | null) => {
    this.singleContractHistory = params
  }

  setContractHistoryLoading = (value: boolean) => {
    this.contractHistoryLoading = value
  }

  setSelectedContract = (params: Contract | null) => {
    this.selectedContract = params
  }

  setMakeContractModal = (value: boolean) => {
    this.makeContractModal = value
  }

  setContractsFilter = (params: GetContractsAll) => {
    this.contractsFilter = params
  }

  setContractsLoading = (value: boolean) => {
    this.contractsLoading = value
  }

  setContracts = (params: ContractRes | null) => {
    this.contracts = params
  }

  setStudentStatuses = (params: StudentStatusesRes | null) => {
    this.studentStatuses = params
  }

  setStudentStatus = (params: StudentStatus | null) => {
    this.studentStatus = params
  }

  setGamificationOffer = (gamificationOffer: GamificationOfferingResponseTypes | null) => {
    this.gamificationOffer = gamificationOffer
  }

  addContract = (params: ContractFormValues) => settingsApi.addContract(params)

  editContract = (params: EditContractReq) => settingsApi.editContract(params)

  setNewVersionModal = (value: boolean) => {
    this.isNewVersionModal = value
  }

  setMobileAppVersions = (versions: MobileAppVersionRes) => {
    this.mobileAppVersions = versions
  }

  setTotalMobileVersions = (total: number) => {
    this.totalMobileAppVersions = total
  }

  setMobileVersionsPage = (page: number) => {
    this.mobileVersionsPage = page
  }

  setMobileVersionsPerPage = (perPage: number) => {
    this.mobileVersionsPerPage = perPage
  }

  setMobileVersionFilterOptions = (options: Partial<MobileAppVersionReqParams>) => {
    this.mobileAppFilterOptions = options
  }

  getAcdemicHours = () =>
    settingsApi
      .getAcademicHours()
      .then((res) => {
        if (res.success) {
          this.setAcademicHours(res.data)
        }
      })
      .catch(addCatchNotification)

  getAcademicPrices = () =>
    settingsApi
      .getAcademicPrices()
      .then((res) => {
        if (res.success) {
          this.setAcademicPrices(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setAcademicPricesLoading(false))

  editAcademicPrice = (params: AcademicPriceEdit) => settingsApi.editAcademicPrice(params)

  addAcademicPrice = (params: AcademicPrices) => settingsApi.addAcademicPrice(params)

  deleteAcademicPrice = (id: number) =>
    settingsApi
      .deteteAcademicPrice(id)
      .then((res) => {
        if (res.success) {
          this.setAcademicPricesLoading(true)
          this.getAcademicPrices()
        }
      })
      .catch(addCatchNotification)

  getFinanceDicounts = () =>
    settingsApi
      .getFinanceDiscounts()
      .then((res) => {
        if (res.success) {
          this.setFinanceDiscounts(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setFinanceDiscountsLoading(false))

  addFinanceDiscount = (params: FinanceDiscounts) => settingsApi.addFinanceDiscount(params)

  editFinanceDiscoiunt = (params: EditFinanceDiscount) => settingsApi.editFinanceDiscoiunt(params)

  deleteFinanceDiscount = (id: number) =>
    settingsApi
      .deleteFinanceDiscount(id)
      .then((res) => {
        if (res.success) {
          this.setFinanceDiscountsLoading(true)
          this.getFinanceDicounts()
        }
      })
      .catch(addCatchNotification)

  getMobileAppVersions = (params: Partial<MobileAppVersionReqParams>) =>
    settingsApi
      .getMobileAppVersions(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setMobileAppVersions(response.data)
          this.setTotalMobileVersions(response.data.total)
        }
      })
      .catch(addCatchNotification)

  changeStudentStatus = (params: ChangeStudentReq) => settingsApi.changeStudentStatus(params)

  getContract = (params: number) =>
    settingsApi
      .getContract(params)
      .then((res) => {
        if (res.success) {
          this.setSingleContractHistory(res.data)
        }
      })
      .catch(addCatchNotification)

  getContractHistory = (params: GetContractHistoryReq) =>
    settingsApi
      .getContractHistory(params)
      .then((res) => {
        if (res.success) {
          this.setContractHistory(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setContractHistoryLoading(false))

  getStudentStatuses = () =>
    settingsApi
      .getStudentStatuses()
      .then((res) => {
        if (res.success) {
          this.setStudentStatuses(res.data)
        }
      })
      .catch(addCatchNotification)

  getStudentStatus = (id: number) =>
    settingsApi
      .getStudentStatus(id)
      .then((res) => {
        if (res.success) {
          this.setStudentStatus(res.data)
        }
      })
      .catch(addCatchNotification)

  getGamificationOffer = () =>
    settingsApi
      .getGamificationOffering()
      .then((res) => {
        if (res?.success) {
          this.setGamificationOffer(res.data)
          this.setEditorContent(res?.data?.body)
        }
      })
      .catch(addCatchNotification)

  updateGamificationOffering = (params: GamificationOfferingParams) =>
    settingsApi
      .updateGamificationOffering(params)
      .then((res) => {
        if (res?.success) {
          this.isUpdateGamificationOffering = res.success
        }
      })
      .catch(addCatchNotification)

  setEditorContent = (param: string) => {
    this.editorContent = param
  }

  editStudentStatus = (params: EditStudentReq) => settingsApi.editStudentStatus(params)

  setUpdateGamificationOffering = (value: boolean) => {
    this.isUpdateGamificationOffering = value
  }

  getContracts = (params: GetContractsAll) =>
    settingsApi
      .getContracts(params)
      .then((res) => {
        if (res.success) {
          this.setContracts(res.data)
        }
      })
      .catch((err: Error | unknown) => {
        addCatchNotification(err)
      })
      .finally(() => this.setContractsLoading(false))

  reset = () => {
    this.isUpdateGamificationOffering = false
    this.isGamificationModal = false
    this.editorContent = ""
    this.isNewVersionModal = false
    this.totalMobileAppVersions = 0
    this.mobileVersionsPage = 1
    this.mobileVersionsPerPage = 10
  }
}

export const settingsStore = new SettingsStore()
