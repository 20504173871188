import { PropsWithChildren } from "react"
import classnamesBind from "classnames/bind"
import { Logo } from "@/components"

import styles from "./layoutPublic.module.scss"

const cn = classnamesBind.bind(styles)

type Props = PropsWithChildren

export const LayoutPublic = ({ children }: Props) => (
  <div className={cn("layoutPublic")}>
    <div className={cn("layoutPublic__photo")} />
    <div className={cn("layoutPublic__content")}>
      <div className={cn("layoutPublic__logo")}>
        <Logo />
      </div>

      <div className={cn("layoutPublic__children")}>{children}</div>
    </div>
  </div>
)
