import { makeAutoObservable } from "mobx"
import { CancelTokenSource, isCancel } from "axios"
import { IFaceIdLogsResponse, IStaffParams, IStaffRolesParams, StaffRolesResponse } from "@/api/staff"
import { staffApi } from "@/api/staff/staff"
import { StaffWorkStatus } from "@/api/users"
import { addCatchNotification } from "@/modules/notifications"
import { StaffData } from "@/pages/Users/Staff/types"
import { OrderBy } from "@/utils"
import { IChangeStudentStatusReq, IGetFaceIdLogsRequest, KeyType, RoleTypes } from "./types"

class StaffStore {
  page = 1
  perPage = 10
  total = 0
  staffs: StaffData[] = []
  singleStaff: StaffData | null = null
  value = ""
  orderBy: OrderBy = OrderBy.DESC
  keys: KeyType = ["firstName", "lastName"]
  workStatus: StaffWorkStatus | null = StaffWorkStatus.Working
  isActive: null | boolean = null
  roleId: number | null = null
  roles: StaffRolesResponse[] = []
  rolesKeys: KeyType = ["firstName", "lastName"]
  rolesValue = ""
  rolesOrderBy: OrderBy = OrderBy.DESC
  rolesPage = 1
  rolesPerPage = 10
  rolesType = RoleTypes.STAFF
  faceIdLogsStudent: IFaceIdLogsResponse | null = null
  faceIdLogsStaff: IFaceIdLogsResponse | null = null
  faceIdLogsAcademic: IFaceIdLogsResponse | null = null
  faceIdFilter: Partial<IGetFaceIdLogsRequest> | null = null
  officeIds: number[] | null = null
  staffSystemStatus: null | number = StaffWorkStatus.Working

  constructor() {
    makeAutoObservable(this)
  }

  resetFilter = () => {
    this.page = 1
    this.value = ""
    this.roleId = null
    this.officeIds = null
    this.workStatus = StaffWorkStatus.Working
    this.isActive = null
    this.staffSystemStatus = null
  }

  changeStudentStatus = (params: IChangeStudentStatusReq) => staffApi.changeStudentStatus(params)

  setFaceIdFilter = (params: Partial<IGetFaceIdLogsRequest> | null) => {
    this.faceIdFilter = params
  }

  resetFaceIdFilter = () => {
    this.faceIdFilter = null
  }

  getStaff = (params: IStaffParams) =>
    staffApi.getStaff(params).then((res) => {
      if (res.success) {
        this.setStaffs(res.data.staff)
        this.setTotal(res.data.total)
      }

      return res
    })

  setOfficeIds = (params: number[] | null) => {
    this.officeIds = params
  }

  setFaceIdLogs = (faceIdLogs: IFaceIdLogsResponse | null, roleType: RoleTypes) => {
    if (roleType === RoleTypes.STUDENT) {
      this.faceIdLogsStudent = faceIdLogs
    } else if (roleType === RoleTypes.STAFF) {
      this.faceIdLogsStaff = faceIdLogs
    } else if (roleType === RoleTypes.ACADEMIC) {
      this.faceIdLogsAcademic = faceIdLogs
    }
  }

  getFaceIdLogs = (params: Partial<IGetFaceIdLogsRequest>, cancelTokenSource?: CancelTokenSource) =>
    staffApi
      .getFaceIdLogs(params, cancelTokenSource)
      .then((res) => {
        if (res.success && params.roleType) {
          this.setFaceIdLogs(res.data, params.roleType)
        }
      })
      .catch((error: Error | unknown) => {
        if (!isCancel(error)) {
          addCatchNotification(error)
        }
      })

  getRoles = (params: IStaffRolesParams) =>
    staffApi
      .getTeacherRoles(params)
      .then((res) => {
        if (res.success) {
          this.setRoles(res.data.roles)
        }

        return res
      })
      .catch(addCatchNotification)

  getStaffSingle = (params: number) =>
    staffApi
      .getSingleStaff(params)
      .then((res) => {
        if (res.success) {
          this.setSingleStaff(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  setStaffs = (teachers: StaffData[]) => {
    this.staffs = teachers
  }

  setSearch = (value: string) => {
    this.value = value
  }

  setWorkStatus = (value: number) => {
    if (!value) {
      this.workStatus = null

      return
    }

    this.workStatus = value
  }

  setSingleStaff = (singleStaff: StaffData | null) => {
    this.singleStaff = singleStaff
  }

  setRoles = (roles: StaffRolesResponse[]) => {
    this.roles = roles
  }

  setRoleId = (value: number) => {
    if (!value) {
      this.roleId = null

      return
    }

    this.roleId = value
  }

  setIsActive = (value: boolean) => {
    this.isActive = value
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setTotal = (total: number) => {
    this.total = total
  }

  setStaffSystemStatus = (value: number | null) => {
    this.staffSystemStatus = value
  }

  reset = () => {
    this.value = ""
    this.rolesValue = ""
    this.orderBy = OrderBy.DESC
    this.rolesType = RoleTypes.STAFF
    this.rolesOrderBy = OrderBy.DESC
    this.workStatus = StaffWorkStatus.Working
    this.page = 1
    this.perPage = 10
    this.rolesPage = 1
    this.rolesPerPage = 10
    this.total = 0
    this.roleId = null
    this.isActive = null
    this.singleStaff = null
    this.rolesKeys = ["firstName", "lastName"]
    this.keys = ["firstName", "lastName"]
    this.roles = []
    this.staffs = []
    this.staffSystemStatus = null
  }
}

export const staffStore = new StaffStore()
