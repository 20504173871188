import { observer } from "mobx-react"

export const Lessons = observer(() => {
  console.log("")

  return (
    <>
      <>Lessons</>
    </>
  )
})
