import { observer } from "mobx-react"
import { Flex, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { Teacher } from "./Teacher"

import styles from "./teacher-payments.module.scss"

const cn = classNames.bind(styles)

export const TeacherPayments = observer(() => {
  console.log()

  return (
    <Flex className={cn("teacher-payments")} vertical gap={28}>
      <Typography.Title level={5}>{mainDictionary.teacherPayments}</Typography.Title>

      <Flex gap={40} vertical>
        <Teacher />
        <Teacher />
      </Flex>
    </Flex>
  )
})
