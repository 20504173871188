import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { Events, MakeCompetition, MasterClass, SingleCompetition, SingleMasterClass, Users } from "./lazy"

export const eventRouter = [
  {
    path: ROUTES.masterClass,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.managementReports}>
        <MasterClass />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.competitions,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.managementReports}>
        <Events />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.singleMasterClass,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.managementReports}>
        <SingleMasterClass />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.masterClassParticipants,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.managementReports}>
        <Users />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.addCompetitions,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.managementReports}>
        <MakeCompetition />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.editCompetitions,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.managementReports}>
        <MakeCompetition />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.singleCompetition,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.managementReports}>
        <SingleCompetition />
      </AvailableComponent>
    ),
  },
]
