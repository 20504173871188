import { observer } from "mobx-react"
import { Flex, Form, Input, Select } from "antd"
import classNames from "classnames/bind"

import styles from "./filter.module.scss"
const cn = classNames.bind(styles)

import mainDictionary from "@/dictionary"

export const Filter = observer(() => (
  <Form layout="vertical">
    <Flex className={cn("filter")} gap={20}>
      <Form.Item label={mainDictionary.search}>
        <Input className={cn("filter__input")} inputMode="search" placeholder={mainDictionary.search} />
      </Form.Item>

      <Form.Item label={mainDictionary.branch}>
        <Select placeholder={mainDictionary.branch} className={cn("filter__input")} />
      </Form.Item>
    </Flex>
  </Form>
))
