import { observer } from "mobx-react"
import { Checkbox, DatePicker, Divider, Flex, Form, Modal, Select, TimePicker } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { colors, weekDays } from "@/pages/Groups/constants"
import { useStores } from "@/stores"

import styles from "./make-schedule.module.scss"

const cn = classNames.bind(styles)

export const MakeSchedule = observer(() => {
  const { groupsStore } = useStores()
  const [form] = Form.useForm()

  const handleCancel = () => {
    groupsStore.setScheduleModal(false)
  }

  return (
    <Modal
      width={600}
      onOk={form.submit}
      okText={mainDictionary.save}
      cancelText={mainDictionary.cancel}
      title={mainDictionary.addScheduleToGroup}
      onCancel={handleCancel}
      open={groupsStore.scheduleModal}
      className={cn("schedule")}
    >
      <Divider />
      <Form form={form}>
        <Form.Item name="name" label={mainDictionary.branch}>
          <Select className={cn("schedule__input")} placeholder={mainDictionary.choose} />
        </Form.Item>

        <Form.Item label={mainDictionary.room}>
          <Select className={cn("schedule__input")} placeholder={mainDictionary.choose} />
        </Form.Item>

        <Form.Item label={mainDictionary.period}>
          <DatePicker.RangePicker className={cn("schedule__input")} />
        </Form.Item>

        <Form.Item label={mainDictionary.lessonTime}>
          <TimePicker.RangePicker className={cn("schedule__input")} />
        </Form.Item>

        <Form.Item label={mainDictionary.weekdays}>
          <Checkbox.Group className={cn("schedule__input")} options={weekDays} />
        </Form.Item>

        <Form.Item label={mainDictionary.teacher}>
          <Select className={cn("schedule__input")} placeholder={mainDictionary.choose} />
        </Form.Item>

        <Form.Item label={mainDictionary.color}>
          <Flex className={cn("schedule__input")} gap={12} align="center">
            {colors.map((item) => (
              <div key={item.value} className={cn("schedule__color")} style={{ backgroundColor: item.color }} />
            ))}
          </Flex>
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  )
})
