import { makeAutoObservable } from "mobx"
import { IRoles } from "@/api/controls"
import { groupsApi, IGetHomeworkStatisticsByStudentRes, IHomeworkResponse, StaffGroupsDetail } from "@/api/groups"
import {
  ChapterHomework,
  ChapterHomeworkRes,
  CourseChapter,
  CourseChapterRes,
  GetHomeworkDbCoursesRes,
  GetHomeworkStatisticByCheckingRes,
  GetHomeworkStatisticsRes,
  GetSkillClassesVideosParams,
  GetTeachersStatisTicsRes,
  GetVideoUploadLink,
  HomeworkDbCourses,
  ICourse,
  ICourseResponse,
  ICurriculum,
  IDiscipline,
  IFilterObjectsResponse,
  IGetCourseCurriculumsRes,
  IGetCourseResponse,
  IHomeworkStatisticGroupsRes,
  IOption,
  SingleCurriculum,
  SkillClassesEditDataParam,
  SkillClassesUploadVideoParams,
  SKillClassesVideosItem,
  SKillClassesVideosRes,
  studyDepartmentApi,
} from "@/api/study-department"
import { addCatchNotification } from "@/modules/notifications"
import { allOption } from "@/pages/Groups/constants"
import { HomeworkStatistics } from "@/pages/Groups/types"
import { CourseTabs, ICourseFormValues, SkillClassesEditData } from "@/pages/StudyDepartment/types"
import { IGetRolesParams } from "../controls/type"
import { IGetHomeworks, IGetStudentHomeworkStatistics, ITextAreaState } from "../groups/types"
import {
  AddChapterHomeworkReq,
  AddChapterReq,
  AddCourseParams,
  EditChapterHomeworkReq,
  EditChapterReq,
  EditCourseParams,
  EditDraftParams,
  GetChapterHomeworkReq,
  GetCourseChaptersReq,
  GetHomeworkDbCoursesReq,
  GetTeachersStatisTicsReq,
  IAddCurriculum,
  IChangePagination,
  IChangeTopicPositionRequest,
  IEditCourse,
  IEditCourseByMonth,
  IGetCourseCurriculums,
  IGetCourseRequest,
  IGetFilterObjects,
  IGetGroupRequest,
  IGetGroupStudentsRequest,
  IGetHomeworkStudents,
  ISelectOption,
  MoveToCompletionParams,
  SetLessonReq,
  SwitchHomeworkParams,
  TGetGroupActionsFilterObjects,
} from "./types"
import { SkillClassesTabsKeys, SkillClassesVideosStatus } from "@/pages/StudyDepartment/constants"
import { uploadToKinescopeFile } from "@/modules/kinescope/utils"
import { DetailedError } from "tus-js-client"
import { appStore } from "../app"
import mainDictionary from "@/dictionary"

class StudyDepartment {
  homeworkStatisticGroups: IHomeworkStatisticGroupsRes | null = null
  groupStudents: GetHomeworkStatisticsRes | null = null
  studentStatistics: IGetHomeworkStatisticsByStudentRes | null = null
  homeworksByChecking: GetHomeworkStatisticByCheckingRes | null = null
  homeworksByDoneFilter: IGetGroupStudentsRequest | null = null
  studentFilter: IGetGroupStudentsRequest | null = null
  homeworkByCheckingFilter: IGetGroupStudentsRequest | null = null
  homeworkGroupsFilter: IGetGroupRequest | null = null
  filterObjects: IFilterObjectsResponse | null = null
  groupTeachers: StaffGroupsDetail | null = null
  teachersModal = false
  teachers: ISelectOption[] | null = null
  courses: ISelectOption[] | null = null
  learningTypes: ISelectOption[] | null = null
  courseMake = false
  studyMonthEditModal = false
  addTopic = false
  allCourses: IGetCourseResponse | null = null
  disciplines: IDiscipline[] | null = null
  courseLoading = false
  singleCourse: ICourse | null = null
  course: ICourseResponse | null = null
  courseCurriculum: IGetCourseCurriculumsRes | null = null
  curriculumLoading = false
  homeworkPerPage: number | null = null
  singleCurriculum: ICurriculum | null = null
  coursesPagination = 1
  coursePerPage = 100
  tableLoading = false
  editDraft = false
  courseActiveTab: CourseTabs | string = CourseTabs.LessonMonth
  homeworkTextAreaState: ITextAreaState | null = null
  teacherStatisticsFilterLoading = false

  teacherStatisticsFilter: GetTeachersStatisTicsReq = {
    page: 1,
    perPage: 10,
  }

  curriculumPagination = {
    page: 1,
    perPage: 100,
  }

  allHomeworks: IHomeworkResponse | null = null
  categories: ISelectOption[] | null = null
  homeworkStatisticsSort: HomeworkStatistics | null = null

  roles: IRoles[] | null = null
  singleHomeDefaultTabKey = "1"
  actionCourses: ISelectOption[] = []
  actionLearningTypes: ISelectOption[] = []
  actionOffices: ISelectOption[] = []
  actionAcademics: ISelectOption[] = []
  chapterMakeModal = false
  homeworkDbCourses: GetHomeworkDbCoursesRes | null = null
  chapters: CourseChapterRes | null = null
  chaptersLoading = false
  selectedChapter: CourseChapter | null = null
  chapterHomework: ChapterHomeworkRes | null = null
  chapterHomeworkLoading = false
  connectHomeworkModal = false
  homeworkDbCoursesLoading = false
  makeCourse = false
  teachersStatistics: GetTeachersStatisTicsRes | null = null
  selectedHomework: ChapterHomework | null = null
  selectedHomeworkDbCourse: HomeworkDbCourses | null = null
  selectedCurriculum: ICurriculum | null = null
  singleHomeworkCurriculum: SingleCurriculum | null = null
  chapterHomeworkFilter: GetChapterHomeworkReq = {
    page: 1,
    perPage: 10,
  }

  chaptersPagination: GetCourseChaptersReq = {
    page: 1,
    perPage: 10,
  }

  skillCLassesVideosFilter: GetSkillClassesVideosParams = {
    page: 1,
    perPage: 10,
  }
  uploadingPedagogicVideos: SKillClassesVideosItem[] = []
  uploadingTechnicVideos: SKillClassesVideosItem[] = []
  skillClassesVideosPedagogic: SKillClassesVideosRes | null = null
  skillClassesVideosTechnic: SKillClassesVideosRes | null = null
  skillClassesSelectedTab = SkillClassesTabsKeys.Pedagogic
  skillClassesVideoModal = false
  skillClassesVideosLoading = false
  skillClassesEditModal = false
  skillClassesEditData: SKillClassesVideosItem | null = null
  skillClassesVideoWatchModal = false
  skillClassesVideoHistoriesModal = false

  constructor() {
    makeAutoObservable(this)
  }

  setSkillClassesVideoHistoriesModal = (params: boolean) => {
    this.skillClassesVideoHistoriesModal = params
  }

  setSkillClassesVideoWatchModal = (params: boolean) => {
    this.skillClassesVideoWatchModal = params
  }

  setSkillClassesEditData = (params: SKillClassesVideosItem | null) => {
    this.skillClassesEditData = params
  }

  setSkillClassesEditModal = (params: boolean) => {
    this.skillClassesEditModal = params
  }

  setSkillClassesFilter = (params: GetSkillClassesVideosParams) => {
    this.skillCLassesVideosFilter = params
  }

  setUploadingPedagogicVideos = (params: SKillClassesVideosItem[]) => {
    this.uploadingPedagogicVideos = params
  }

  setUploadingTechnicVideos = (params: SKillClassesVideosItem[]) => {
    this.uploadingTechnicVideos = params
  }

  setSkillClassesVideosLoading = (params: boolean) => {
    this.skillClassesVideosLoading = params
  }

  setSkillClassesVideosPedagogic = (params: SKillClassesVideosRes) => {
    this.skillClassesVideosPedagogic = params
  }

  setSkillClassesVideosTechnic = (params: SKillClassesVideosRes) => {
    this.skillClassesVideosTechnic = params
  }

  setSkillClassesSelectedTab = (params: SkillClassesTabsKeys) => {
    this.skillClassesSelectedTab = params
  }

  setSkillClassesVideoModal = (params: boolean) => {
    this.skillClassesVideoModal = params
  }

  setTeacherStatisticsFilterLoading = (params: boolean) => {
    this.teacherStatisticsFilterLoading = params
  }

  setTeacherStatisticsFilter = (params: GetTeachersStatisTicsReq) => {
    this.teacherStatisticsFilter = params
  }

  setSelectedHomework = (params: ChapterHomework | null) => {
    this.selectedHomework = params
  }

  setSingleHomeworkCurriculum = (params: SingleCurriculum | null) => {
    this.singleHomeworkCurriculum = params
  }

  setSelectedHomeworkDbCourse = (params: HomeworkDbCourses | null) => {
    this.selectedHomeworkDbCourse = params
  }

  setMakeCourse = (params: boolean) => {
    this.makeCourse = params
  }

  setEditDraft = (params: boolean) => {
    this.editDraft = params
  }

  setHomeworkDbCoursesLoading = (params: boolean) => {
    this.homeworkDbCoursesLoading = params
  }

  setSelectedCurriculumId = (params: ICurriculum | null) => {
    this.selectedCurriculum = params
  }

  setHomeworkDbCourses = (params: GetHomeworkDbCoursesRes | null) => {
    this.homeworkDbCourses = params
  }

  setConnectHomeworkModal = (params: boolean) => {
    this.connectHomeworkModal = params
  }

  setChapterHomeworkFilter = (params: GetChapterHomeworkReq) => {
    this.chapterHomeworkFilter = params
  }

  setChapterHomework = (params: ChapterHomeworkRes | null) => {
    this.chapterHomework = params
  }

  setChapterHomeworkLoading = (params: boolean) => {
    this.chapterHomeworkLoading = params
  }

  setSelectedChapter = (params: CourseChapter | null) => {
    this.selectedChapter = params
  }

  setChapterPagination = (params: GetCourseChaptersReq) => {
    this.chaptersPagination = params
  }

  setChapterMakeModal = (params: boolean) => {
    this.chapterMakeModal = params
  }

  setTeacherStatistics = (params: GetTeachersStatisTicsRes | null) => {
    this.teachersStatistics = params
  }

  setHomeworkPerPage = (params: number) => {
    this.homeworkPerPage = params
  }

  resetStudentStatistics = () => {
    this.studentStatistics = null
  }

  setSingleHomeDefaultTabKey = (params: string) => {
    this.singleHomeDefaultTabKey = params
  }

  resetAllHomeworks = () => {
    this.allHomeworks = null
  }

  setTableLoading = (params: boolean) => {
    this.tableLoading = params
  }

  addHomeworkDbCourse = (params: AddCourseParams) => studyDepartmentApi.addHomeworkDbCourse(params)

  setCoursePerPage = (params: number) => {
    this.coursePerPage = params
  }

  setAllHomeworks = (params: IHomeworkResponse | null) => {
    this.allHomeworks = params
  }

  getHomeworkChats = (params: IGetHomeworks) =>
    groupsApi
      .getHomeworkForAdmin(params)
      .then((res) => {
        if (res.success) {
          res.data.homeworkChatsData.homeworkChats.reverse()

          this.setAllHomeworks(res.data)
        }
      })
      .catch(addCatchNotification)

  resetFilters = () => {
    this.homeworkGroupsFilter = null
  }

  setCoursesPagination = (params: number) => {
    this.coursesPagination = params
  }

  setHomeworkState = (params: ITextAreaState | null) => {
    this.homeworkTextAreaState = params
  }

  setCourseActiveTab = (params: CourseTabs | string) => {
    this.courseActiveTab = params
  }

  setCurriculumPagination = (params: IChangePagination) => {
    this.curriculumPagination = params
  }

  setChapters = (params: CourseChapterRes | null) => {
    this.chapters = params
  }

  setCourseLoading = (params: boolean) => {
    this.courseLoading = params
  }

  setSingleCurriculum = (params: ICurriculum | null) => {
    this.singleCurriculum = params
  }

  setCurriculumLoading = (params: boolean) => {
    this.curriculumLoading = params
  }

  setSingleCourse = (params: ICourse | null) => {
    this.singleCourse = params
  }

  setAddTopic = (params: boolean) => {
    this.addTopic = params
  }

  setStudyMonthEditModal = (params: boolean) => {
    this.studyMonthEditModal = params
  }

  setCourseMake = (params: boolean) => {
    this.courseMake = params
  }

  resetGroupTeachers = () => {
    this.groupTeachers = null
  }

  setTeacherModal = (params: boolean) => {
    this.teachersModal = params
  }

  setHomeworkStatisticsSort = (params: HomeworkStatistics | null) => {
    this.homeworkStatisticsSort = params
  }

  setHomeworksByDoneFilter = (params: IGetGroupStudentsRequest | null) => {
    this.homeworksByDoneFilter = params
  }

  setChaptersLoading = (params: boolean) => {
    this.chaptersLoading = params
  }

  setHomeworkGroupsFilter = (params: IGetGroupRequest | null) => {
    this.homeworkGroupsFilter = params
  }

  setHomeworkByChecking = (params: IGetGroupStudentsRequest | null) => {
    this.homeworkByCheckingFilter = params
  }

  setStudentFilter = (params: IGetGroupStudentsRequest | null) => {
    this.studentFilter = params
  }

  changeTopicPosition = (params: IChangeTopicPositionRequest) => studyDepartmentApi.changeTopicPosition(params)

  editChapter = (params: EditChapterReq) => studyDepartmentApi.editChapter(params)

  getSkillClassesVideos = (params: GetSkillClassesVideosParams) =>
    studyDepartmentApi
      .getSkillClassesVideos(params)
      .then((res) => {
        if (res.success) {
          const { videos: uploadingVideos, setVideos: setUploadingVideos } = this.getUploadingVideosAndSetter(
            this.skillClassesSelectedTab,
          )

          const setSkillClassesVideos =
            this.skillClassesSelectedTab === SkillClassesTabsKeys.Pedagogic
              ? this.setSkillClassesVideosPedagogic
              : this.setSkillClassesVideosTechnic

          const uploadingIds = uploadingVideos.map((item) => item.id)

          res.data?.videos?.forEach((item) => {
            if (uploadingIds.includes(item.id)) {
              const newUploadingVideos = uploadingVideos.filter((video) => video.id !== item.id)

              setUploadingVideos(newUploadingVideos)
            }
          })

          setSkillClassesVideos(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setSkillClassesVideosLoading(false))

  deleteSkillClassesVideos = (id: number) =>
    studyDepartmentApi
      .deleteSkillClassesVideos(id)
      .then((res) => {
        if (res.success) {
          this.getSkillClassesVideos({
            ...this.skillCLassesVideosFilter,
            videoType: this.skillClassesSelectedTab,
          })
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setSkillClassesVideosLoading(false))

  editSkillClassesVideos = (params: SkillClassesEditDataParam) => studyDepartmentApi.editSkillClassesVideos(params)

  getVideoUploadLink = (params: GetVideoUploadLink) => studyDepartmentApi.getVideoUploadLink(params)

  getChapterHomework = (params: GetChapterHomeworkReq) =>
    studyDepartmentApi
      .getChapterHomework(params)
      .then((res) => {
        if (res.success) {
          this.setChapterHomework(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setChapterHomeworkLoading(false))

  editHomeworkDdbCourse = (params: EditCourseParams) => studyDepartmentApi.editHomeworkDdbCourse(params)

  getHomework = (params: number) => studyDepartmentApi.getHomework(params)

  addHomeworkChapter = (params: AddChapterHomeworkReq) => studyDepartmentApi.addHomeworkChapter(params)

  editHomeworkChapter = (params: EditChapterHomeworkReq) => studyDepartmentApi.editHomeworkChapter(params)

  getCourseCurriculums = (params: IGetCourseCurriculums) =>
    studyDepartmentApi
      .getCourseCurriculums(params)
      .then((res) => {
        if (res.success) {
          this.courseCurriculum = res.data
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.curriculumLoading = false
      })

  getTeacherStatistics = (params: GetTeachersStatisTicsReq) =>
    studyDepartmentApi
      .getTeacherStatistics(params)
      .then((res) => {
        if (res.success) {
          this.setTeacherStatistics(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setTeacherStatisticsFilterLoading(false))

  getStudyRole = (params?: IGetRolesParams) =>
    studyDepartmentApi
      .getStudyRole(params)
      .then((res) => {
        if (res.success) {
          this.roles = res.data
        }
      })
      .catch(addCatchNotification)

  deleteCurriculum = (id: number) => studyDepartmentApi.deleteCurriculum(id)

  addCurriculum = (params: IAddCurriculum) => studyDepartmentApi.addCurriculum(params)

  editCurriculum = (params: IAddCurriculum) => studyDepartmentApi.editCurriculum(params)

  getDisciplines = () =>
    studyDepartmentApi
      .getDisciplines()
      .then((res) => {
        this.disciplines = res.data
      })
      .catch(addCatchNotification)

  addCourse = (params: ICourseFormValues) => studyDepartmentApi.addCourse(params)

  editCourse = (params: IEditCourse) => studyDepartmentApi.editCourse(params)

  getGroups = (params?: IGetGroupRequest) =>
    studyDepartmentApi
      .getHomeworkStatistics(params)
      .then((res) => {
        if (res.success) {
          this.homeworkStatisticGroups = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => (this.tableLoading = false))

  setActionCourses = (params: ISelectOption[]) => {
    this.actionCourses = params
  }

  deleteHomework = (params: number) => studyDepartmentApi.deleteHomework(params)

  setActionAcademics = (params: ISelectOption[]) => {
    this.actionAcademics = params
  }

  setActionLearningTypes = (params: ISelectOption[]) => {
    this.actionLearningTypes = params
  }

  setActionOffices = (params: ISelectOption[]) => {
    this.actionOffices = params
  }

  getGroupActionsFilterObjects = (params: TGetGroupActionsFilterObjects) =>
    studyDepartmentApi.getGroupActionsFilterObjects(params).then((res) => {
      if (res.success) {
        const courses = res.data?.courses?.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        const academics = res.data?.academics?.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        const offices = res.data?.offices?.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        const learningTypes = res.data?.learningTypes?.map((item) => ({
          value: item.id,
          label: item.name,
        }))

        this.setActionCourses(courses)
        this.setActionAcademics(academics)
        this.setActionOffices([allOption, ...offices])
        this.setActionLearningTypes([allOption, ...learningTypes])
      }
    })

  setGroupStudents = (params: GetHomeworkStatisticsRes | null) => {
    this.groupStudents = params
  }

  setLessonToCurriculum = (params: SetLessonReq) => studyDepartmentApi.setLessonToCurriculum(params)

  switchLessonToCurriculum = (params: SwitchHomeworkParams) => studyDepartmentApi.switchLessonToCurriculum(params)

  getCourseChapters = (params: GetCourseChaptersReq) =>
    studyDepartmentApi
      .getCourseChapters(params)
      .then((res) => {
        if (res.success) {
          this.setChapters(res.data)
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setChaptersLoading(false)
      })

  getSingeCurriculum = (id: number) =>
    studyDepartmentApi
      .getSingeCurriculum(id)
      .then((res) => {
        if (res.success) {
          this.setSingleHomeworkCurriculum(res.data)
        }
      })
      .catch(addCatchNotification)

  editDraftComplete = (params: EditDraftParams) => studyDepartmentApi.editDraft(params)

  getHomeworkDbCourses = (params: GetHomeworkDbCoursesReq) =>
    studyDepartmentApi
      .getHomeworkDbCourses(params)
      .then((res) => {
        if (res.success) {
          this.setHomeworkDbCourses(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setHomeworkDbCoursesLoading(false))

  getGroupStudents = (params: IGetGroupStudentsRequest) =>
    studyDepartmentApi
      .getGroupStudents(params)
      .then((res) => {
        if (res.success) {
          this.groupStudents = res.data
        }
      })
      .catch(addCatchNotification)

  getGroupHomeworkStudents = (params: IGetHomeworkStudents) =>
    studyDepartmentApi
      .getHomeworksByChecking(params)
      .then((res) => {
        if (res.success) {
          this.homeworksByChecking = res?.data
        }
      })
      .catch(addCatchNotification)

  getSingleStudentHomework = (params: IGetStudentHomeworkStatistics) =>
    groupsApi
      .getStudentGroupStatistics(params)
      .then((res) => {
        if (res.success) {
          this.studentStatistics = res?.data
        }
      })
      .catch(addCatchNotification)

  getCourse = (id: number) =>
    studyDepartmentApi
      .getCourse(id)
      .then((res) => {
        if (res.success) {
          this.course = res.data
        }

        return res
      })
      .catch(addCatchNotification)

  editCourseMyMonth = (params: IEditCourseByMonth) => studyDepartmentApi.editCourseByMonth(params)

  deleteCourse = (params: number) => studyDepartmentApi.deleteCourse(params)

  getCourses = (params?: IGetCourseRequest) =>
    studyDepartmentApi
      .getCourses(params)
      .then((res) => {
        if (res.success) {
          this.allCourses = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setCourseLoading(false)
      })

  resetCourses = () => {
    this.allCourses = null
  }

  optionFormatter = (options: IOption[], isSingle?: boolean) => {
    const formattedOptions = options.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    if (isSingle) {
      return formattedOptions
    }

    return [allOption, ...formattedOptions]
  }

  moveToCompletion = (params: MoveToCompletionParams) => studyDepartmentApi.moveToCompletion(params)

  getFilterObjects = (params?: IGetFilterObjects) =>
    studyDepartmentApi
      .getFilterObjects(params)
      .then((res) => {
        if (res.success) {
          this.filterObjects = res.data
          this.courses = this.optionFormatter(res.data?.courses)
          this.learningTypes = this.optionFormatter(res?.data?.learningTypes)
          this.teachers = this.optionFormatter(res?.data?.teachers, true)
          this.categories = this.optionFormatter(res?.data?.categories)
        }
      })
      .catch(addCatchNotification)

  getGroupTeachers = (params: number) =>
    studyDepartmentApi
      .getGroupTeachers(params)
      .then((res) => {
        if (res.success) {
          this.groupTeachers = res.data
          this.setTeacherModal(true)
        }
      })
      .catch(addCatchNotification)

  addChapter = (params: AddChapterReq) => studyDepartmentApi.addChapter(params)

  updateVideoStatus = (params: SkillClassesUploadVideoParams) => studyDepartmentApi.updateVideoStatus(params)

  getUploadingVideosAndSetter = (type: SkillClassesTabsKeys) => {
    return type === SkillClassesTabsKeys.Pedagogic
      ? { videos: this.uploadingPedagogicVideos, setVideos: this.setUploadingPedagogicVideos }
      : { videos: this.uploadingTechnicVideos, setVideos: this.setUploadingTechnicVideos }
  }

  private updateUploadingVideo = (
    id: number,
    type: SkillClassesTabsKeys,
    updater: (item: SKillClassesVideosItem) => SKillClassesVideosItem,
  ) => {
    const { videos, setVideos } = this.getUploadingVideosAndSetter(type)

    const updatedVideos = videos.map((item) => (item.id === id ? updater(item) : item))
    setVideos(updatedVideos)
  }

  private getHandleUploadProgress =
    (id: number, type: SkillClassesTabsKeys) => (bytesUploaded: number, bytesTotal: number, percentage: number) => {
      this.updateUploadingVideo(id, type, (item) => ({ ...item, percentage }))
    }

  private getHandleUploadError = (id: number, type: SkillClassesTabsKeys) => (error: DetailedError | Error) => {
    this.updateUploadingVideo(id, type, (item) => ({
      ...item,
      currentStatus: SkillClassesVideosStatus.Error,
    }))
  }

  private getHandleUploadSuccess = (id: number, type: SkillClassesTabsKeys, onSuccess?: () => void) => () => {
    onSuccess?.()

    this.updateUploadingVideo(id, type, (item) => ({
      ...item,
      currentStatus: SkillClassesVideosStatus.Waiting,
    }))

    this.updateVideoStatus({
      id,
      data: { message: mainDictionary.waitingMessage, status: SkillClassesVideosStatus.Waiting },
    })
  }

  runUploadFile = ({
    id,
    uploadFile,
    onSuccess,
    uploadUrl,
    type,
  }: {
    id: number
    uploadFile: any
    uploadUrl: string
    onSuccess?: () => void
    type: SkillClassesTabsKeys
  }) => {
    const callbacksObject = uploadToKinescopeFile({
      file: uploadFile as File,
      uploadUrl,
      onProgress: this.getHandleUploadProgress(id, type),
      onError: this.getHandleUploadError(id, type),
      onSuccess: this.getHandleUploadSuccess(id, type, onSuccess),
      origName: uploadFile.name,
      id,
      chapterId: 0,
    })

    this.updateUploadingVideo(id, type, (item) => ({
      ...item,
      abort: callbacksObject.abort,
      start: callbacksObject.start,
    }))
  }
}

export const studyDepartment = new StudyDepartment()
