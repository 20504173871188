import { PaginationProps } from "antd"

type Params = Omit<PaginationProps, "total" | "showSizeChanger"> & {
  total?: number
  notShowSizeChanger?: boolean
}

export const getPaginationParams = ({ total, notShowSizeChanger, ...rest }: Params) => ({
  ...rest,
  ...(total
    ? {
        total,
        showTotal: (total: number) => `Umumiy soni: ${total} ta`,
      }
    : {}),
  ...(!notShowSizeChanger ? { showSizeChanger: true } : {}),
})

export const roundOneDecimal = (rating: number) => Math.floor(rating * 10) / 10
