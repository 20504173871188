import { TPermission } from "@/api/controls"
import { StructuredPermissions } from "./types"

export const normalizedPermissions = (permissions: TPermission[]): any => {
  if (!permissions.length) return null

  return permissions.map((item) => {
    const { children, ...rest } = item

    return {
      ...rest,
      ...(children && children.length ? { children: normalizedPermissions(children) } : {}),
    }
  })
}

export const getStructuredPermissions = (permissions: TPermission[]): StructuredPermissions =>
  permissions.reduce(
    (previousValue, { label, key, children, ...rest }) => ({
      ...previousValue,
      [key]: rest,
      ...(children ? getStructuredPermissions(children) : {}),
    }),
    {},
  )
