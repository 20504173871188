import { useEffect } from "react"
import { observer } from "mobx-react"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Flex, Space, Typography } from "antd"
import { useBoolean } from "usehooks-ts"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from "../../../modules/permission"
import { BranchOfficeGrid } from "./components/BranchOfficeGrid"
import { BranchOfficeAddModal } from "./modals/BranchOfficeAddModal"
import { BranchOfficeDeleteModal } from "./modals/BranchOfficeDeleteModal"
import { branchOfficeStore } from "./shared/branch-office.store"

export const BranchOffices = observer(() => {
  const { breadcrumbStore } = useStores()
  const { setOpenAddModalStatus, isOpenAddModal } = branchOfficeStore
  const { value: isLoading, setTrue: setTrueLoading, setFalse: setFalseLoading } = useBoolean()
  const [isAvailableAdd] = useCheckPermission({
    module: permissionObjectTypes.staffControlBranchOffice,
    option: PermissionOptions.Create,
  })

  useEffect(() => {
    setTrueLoading()

    branchOfficeStore.getBranchOffices().finally(setFalseLoading)
    breadcrumbStore.addBreadcrumb({ label: mainDictionary.offices })

    return () => {
      breadcrumbStore.clearBreadcrumbs()
    }
  }, [])

  const handleAddButtonClick = () => {
    setOpenAddModalStatus(true)
  }

  return (
    <Flex vertical gap={20}>
      <Flex justify="space-between" align="center">
        <Typography.Title level={4}>{mainDictionary.branches}</Typography.Title>

        {isAvailableAdd && (
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddButtonClick}>
            {mainDictionary.addBranchOffice}
          </Button>
        )}
      </Flex>

      <BranchOfficeGrid isLoading={isLoading} />

      {isOpenAddModal && <BranchOfficeAddModal />}
      <BranchOfficeDeleteModal />
    </Flex>
  )
})
