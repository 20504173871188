import { makeAutoObservable } from "mobx"
import { CallHistoryRes, IGetReportResponse, reportsApi } from "@/api/reports"
import { addCatchNotification } from "@/modules/notifications"
import { GetCallHistory, IGetReportsTypes, UnprocessedTaskTypes } from "./types"

const defaultHistoryFilter = {
  page: 1,
  perPage: 10,
}

class ReportsStore {
  reports: IGetReportResponse | null = null
  loading = false
  reportFilters: IGetReportsTypes | null = null
  reportType: UnprocessedTaskTypes | string = UnprocessedTaskTypes.GroupAttendance
  reportsDefaultPage = 1
  callHistories: CallHistoryRes | null = null
  callHistoriesFilter: GetCallHistory = defaultHistoryFilter
  historyLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  setHistoryLoading = (params: boolean) => {
    this.historyLoading = params
  }

  setCallHistoriesFilter = (params: GetCallHistory) => {
    this.callHistoriesFilter = params
  }

  setCallHistories = (params: CallHistoryRes | null) => {
    this.callHistories = params
  }

  setReportsDefaultPage = (page: number) => {
    this.reportsDefaultPage = page
  }

  setReportFilters = (params: IGetReportsTypes | null) => {
    this.reportFilters = params
  }

  setReportType = (params: UnprocessedTaskTypes | string) => {
    this.reportType = params
  }

  getCallHistory = (params: GetCallHistory) =>
    reportsApi
      .getCallHistory(params)
      .then((res) => {
        if (res.success) {
          this.setCallHistories(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setHistoryLoading(false))

  resetReports = () => {
    this.reports = null
    this.reportFilters = {
      page: 1,
      perPage: 10,
    }
  }

  resetCallHistories = () => {
    this.callHistoriesFilter = defaultHistoryFilter
  }

  setLoading = (params: boolean) => {
    this.loading = params
  }

  getReports = (params: IGetReportsTypes) =>
    reportsApi
      .getReports(params)
      .then((res) => {
        if (res.success) {
          this.reports = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.loading = false
      })
}

export const reportsStore = new ReportsStore()
