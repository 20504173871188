import { CancelTokenSource } from "axios"
import { IResponse } from "@/api/types"
import { StaffData } from "@/pages/Users/Staff/types"
import { GetAcademicsParams } from "@/stores/notifications"
import { IChangeStudentStatusReq, IGetFaceIdLogsRequest } from "@/stores/staff"
import { dictionaryWithKeys } from "@/utils"
import { IAcademicResponse } from "../academic/types"
import { Endpoints } from "../endpoints"
import { IStaff } from "../gamification/types"
import { INetworkConfig, Instance } from "../instance"
import { StaffEndpoints } from "./endpoints"
import {
  IFaceIdLogsResponse,
  IStaffParams,
  IStaffRoles,
  IStaffRolesParams,
  ResetPassword,
  SingleStaffParams,
  StaffResponse,
  UpdateResponse,
} from "./types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class StaffApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getAcademics = (params: GetAcademicsParams): Promise<IResponse<IAcademicResponse>> =>
    this.get(StaffEndpoints.Academic, { params })

  getStaff = (params: IStaffParams): Promise<IResponse<StaffResponse>> =>
    this.get(dictionaryWithKeys(StaffEndpoints.UsersStaff), { params })

  resetPassword = (params: SingleStaffParams): Promise<IResponse<ResetPassword>> =>
    this.put(
      dictionaryWithKeys(StaffEndpoints.UsersStaffResetPassword, {
        id: params.id,
      }),
    )

  updateStaff = (params: StaffData): Promise<IResponse<UpdateResponse>> =>
    this.put(
      dictionaryWithKeys(StaffEndpoints.UsersStaffUpdate, {
        id: params.id,
      }),
      params,
    )

  getTeacherRoles = (params: IStaffRolesParams): Promise<IResponse<IStaffRoles>> =>
    this.get(dictionaryWithKeys(StaffEndpoints.TeacherRoles), { params })

  getSingleStaff = (params: number): Promise<IResponse<StaffData>> =>
    this.get(
      dictionaryWithKeys(StaffEndpoints.UsersStaffUpdate, {
        id: params,
      }),
    )

  getFaceIdLogs = (
    params: Partial<IGetFaceIdLogsRequest>,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<IResponse<IFaceIdLogsResponse>> =>
    this.get(`${StaffEndpoints.FaceControlActivityLogs}`, { params }, cancelTokenSource)

  changeStudentStatus = (params: IChangeStudentStatusReq): Promise<IResponse<IStaff>> =>
    this.put(`${StaffEndpoints.UsersStaff}/${params.id}${StaffEndpoints.UpdateStatus}`, params)
}

export const staffApi = new StaffApi(config)
