import { makeAutoObservable } from "mobx"

class SpecialStore {
  isOpenDrawer = false
  constructor() {
    makeAutoObservable(this)
  }

  setIsOpenDrawer = (value: boolean) => {
    this.isOpenDrawer = value
  }

  reset = () => {}
}

export const specialStore = new SpecialStore()
