import { ThemeConfig } from "antd/es/config-provider/context"

import colors from "../colors.module.scss"

export const components: ThemeConfig["components"] = {
  Typography: {
    colorSuccess: colors.colorPrimary,
  },
  Layout: {
    headerBg: colors.white,
    siderBg: colors.sideBg,
    triggerBg: colors.sideBg,
    bodyBg: colors.white,
  },
  Checkbox: {
    controlInteractiveSize: 22,
  },
  Menu: {
    itemBg: colors.sideBg,
    itemBorderRadius: 8,
    itemMarginInline: 12,
    itemPaddingInline: 10,
  },
  Button: {
    dangerColor: colors.red,
  },
  Table: {
    cellPaddingBlock: 12,
    headerBg: colors.white,
    cellPaddingInline: 12,
    rowHoverBg: colors.tableRowHoverColor,
    headerSplitColor: "transparent",
    borderColor: colors.tableBorderColor,
    headerColor: colors.headerColor,
    colorText: colors.black,
  },
  Pagination: {
    itemActiveBg: colors.colorPrimary,
    colorPrimary: colors.white,
  },
  Message: {
    contentBg: "red",
    colorText: "#333",
    fontSize: 16,
    borderRadius: 12,
  },
}
