export const constantsDictionary = {
  teachers: "O'qtuvchilar",
  categories: "Kategoriyalar",
  transactions: "Tranzaksiyalar",
  courses: "Kurslar",
  modules: "Modullar",
  lessonVideos: "Dars videolari",
  blogs: "Bloglar",
  synching: "Synxronlanyapti...",
  videoComment: "Video sharhlar",
  users: "Foydalanuvchilar",
  students: "O`quvchilar",
  profile: "Profil",
  authors: "Mualliflar",
  assistants: "Yordamchi ustozlar",
  promo: "Promo Kod",
  sale: "Chegirma",
  reports: "Monitoring",
  controls: "Xodimlar boshqaruvi",
  faceIdLogs: "Hikvision qaydlari",
  roles: "Rollar",
  groups: "Guruhlar",
  admin: "Admin",
  academics: "Akademiklar",
  actions: "Amallar",
  Gamification: "Gemifikatsiya",
  homeworks: "Uyga vazifalar",
  homework: "Uyga vazifa",
  didNotGiveHomework: "Uyga vazifa berilmaganlar",
  lastSynchTitle: "Oxirgi ma`lumotlar yangilangan vaqti: ",
  levels: "Bosqichlar",
  draft: "Qoralama",
  methods: "Metodlar",
  myGroups: "Guruhlarim",
  notfoundSubtitle: "Muammoni hal qilish uchun mas'ul xodimlarga murojaat qilishingiz mumkin.",
  notfoundTitle: "Sizda ushbu sahifa uchun huquq mavjud emas!",
  staff: "Xodim",
  statistics: "Statistika",
  confirm: "Siz ma’lumotlarini sinxronlamoqchimisiz?",
  synchSuccess: "Sinxronlash muvaffaqiyatli amalga oshirildi",
  studyDepartment: "O`quv bo`limi",
  connect: "Bog`lash",
  audit: "Audit",
  synch: "Sinxronlash",
  management: "Boshqaruv",
  offices: "Filiallar",
  login: "Kirish",
  doubleSynch: "Sinxronizatsiya qilinyapti bir oz kutib keyin qayta yangilang",
  faceIdDevices: "Hikvision qurilmalari",
  problemWithInternet: "Internet bilan biron muammo bor.",
  inQueue: "Navbatda",
  haveQueue: (text: number) => `Navbatda ${text} ta user bor`,
  exam: "Imtihon",
  market: "Do`kon",
  accessories: "Akksessuarlar",
  teachersKPI: `O'qituvchilar KPI`,
  teachersStatistics: "O'qituvchilar statistikasi",
  notifications: "Bildirishnomalar",
  sended: "Jo`natilganlar",
  templates: "Shablonlar",
  autoCall: "Avto qo`ng`iroqlar",
  events: "Tadbirlar",
  masterClasses: "Master klasslar",
  competitions: "Musobaqalar",
  homeworkBase: "Uyga vazifalar bazasi",
  theBestOnes: "Eng zo`rlari",
  addingToGroups: "Guruhlarga qo'shilish",
  studentDuplications: "O‘quvchi dublikatlari",
  skillClasses: "Mahorat darslari",
}
