import { observer } from "mobx-react"

export const Info = observer(() => {
  console.log("")

  return (
    <>
      <>Info</>
    </>
  )
})
