import { useEffect, useMemo } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import { onMessage } from "@firebase/messaging"
import { Button, Flex, Layout as AntdLayout } from "antd"
import { MenuProps } from "antd/es/menu/menu"
import classnamesBind from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import LogoNajotIcon from "@/assets/icons/logo_najot_talim.svg?react"
import MiniLogoNajotIcon from "@/assets/icons/mini-logo.svg?react"
import SidebarTriggerIcon from "@/assets/icons/sidebar_trigger.svg?react"
import SidebarTriggerOpenIcon from "@/assets/icons/sidebar_trigger_open.svg?react"
import { LoginModal, NoInternet } from "@/components"
import { LogOut } from "@/Layout/LayoutProtected/LogOut"
import { addNotification, NotificationMessageType } from "@/modules/notifications"
import {
  permissionObjectTypes,
  PermissionOptions,
  useCheckPermission,
  ValueOfPermissionObjectTypes,
} from "@/modules/permission"
import { messaging } from "@/shared/helpers"
import { useOnLine } from "@/shared/hooks"
import { useStores } from "@/stores"
import { useDeviceMediaQuery } from "@/utils"
import { Content } from "./Content"
import { Header } from "./Header"
import type { IMenuItems } from "./Menu"
import { getMenu, mainMenuLists, Menu, menuSkeletons } from "./Menu"

import styles from "./layout-protected.module.scss"

const cn = classnamesBind.bind(styles)

const getSelectedKeys = (pathname: string) => {
  const pathnameList = pathname.split("/")

  return [
    pathname,
    ...(pathnameList[1] ? [`/${pathnameList[1]}`] : []),
    ...(pathnameList[2] ? [`/${pathnameList[1]}/${pathnameList[2]}`] : []),
  ]
}

type MenuItem = Required<MenuProps>["items"][number]

export const LayoutProtected = observer(() => {
  const { value: isMiniSidebar, toggle: toggleMiniSidebarStatus } = useBoolean(false)
  const { isMobile } = useDeviceMediaQuery()
  const siderWidth = isMiniSidebar ? 80 : isMobile ? 0 : 250

  const [isAvailableToSee] = useCheckPermission({
    module: permissionObjectTypes.managementReports,
    option: PermissionOptions.Read,
  })
  const { permissionsStore, appStore } = useStores()
  const navigate = useNavigate()
  const mainMenuList = mainMenuLists(appStore.reportsCount || 0)
  const { pathname } = useLocation()
  const selectedKeys = getSelectedKeys(pathname)
  const { isOnline } = useOnLine()

  const menu = useMemo(() => {
    if (!permissionsStore.structuredPermissions) {
      return []
    }

    const menuItemsPermissionsKeys = Object.keys(
      permissionsStore.structuredPermissions,
    ) as ValueOfPermissionObjectTypes[]

    return getMenu(menuItemsPermissionsKeys, mainMenuList)
  }, [permissionsStore.menus, appStore.reportsCount]) as MenuItem[]

  // @ts-ignore
  const menuItems = permissionsStore.permissions === null ? menuSkeletons : menu

  useEffect(() => {
    if (pathname === "/" && permissionsStore.menus && menu && menu.length) {
      const { key, children } = menu[0] as IMenuItems
      const link = children && children[0] ? children[0].key : key

      navigate(link || "/")
    }
  }, [menu, permissionsStore.menus, pathname])

  useEffect(() => {
    if (permissionsStore.menus) {
      appStore.setMenuItems(menuItems)
      appStore.setSelectedKeys(selectedKeys)
    }
  }, [permissionsStore.menus, selectedKeys])

  useEffect(() => {
    if (isAvailableToSee) {
      appStore.getReportsCount()
    }
  }, [isAvailableToSee])

  onMessage(messaging, (payload) => {
    addNotification({
      message: payload?.notification?.title || "",
      messageType: NotificationMessageType.Info,
    })
  })

  return (
    <>
      {!isOnline && <NoInternet />}

      <AntdLayout className={cn("layout")} hasSider>
        <AntdLayout.Sider trigger={null} collapsible collapsed={isMiniSidebar} width={siderWidth}>
          <div className={cn("layout__sidebar", { "layout__sidebar--mini": isMiniSidebar })}>
            <div className={cn("layout__logo", { "layout__logo--mini": isMiniSidebar })}>
              {isMiniSidebar ? <MiniLogoNajotIcon width={32} /> : <LogoNajotIcon width={135} />}

              {!isMiniSidebar && (
                <Button type="text" onClick={toggleMiniSidebarStatus}>
                  <SidebarTriggerIcon />
                </Button>
              )}
            </div>

            {isMiniSidebar && (
              <Flex justify="center" style={{ marginTop: "10px" }}>
                <Button type="text" onClick={toggleMiniSidebarStatus}>
                  <SidebarTriggerOpenIcon />
                </Button>
              </Flex>
            )}

            <div className={cn("layout__menu")}>
              <Menu />
            </div>

            <LogOut isMini={isMiniSidebar} />
          </div>
        </AntdLayout.Sider>

        <AntdLayout className="site-layout">
          <Header />

          <Content>
            <Outlet />
          </Content>
        </AntdLayout>

        {appStore.loginModal?.isVisible && <LoginModal />}
      </AntdLayout>
    </>
  )
})
