import classNames from "classnames/bind"
import { motion } from "framer-motion"
import LogoNajotTalimIcon from "@/assets/icons/logo_najot_talim.svg?react"

import styles from "./logo.module.scss"

const cn = classNames.bind(styles)

export const Logo = () => (
  <div className={cn("logo")}>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "linear", duration: 1, x: { duration: 1 } }}
    >
      <LogoNajotTalimIcon />
    </motion.div>
  </div>
)
