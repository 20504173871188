import { useState } from "react"
import { MenuOutlined } from "@ant-design/icons"
import { Button, Drawer as MuiDrawer } from "antd"
import classnamesBind from "classnames/bind"
import { Icons } from "@/components"
import { useStores } from "@/stores"
import { Menu } from "../Menu"

import styles from "./drawer.module.scss"

const cn = classnamesBind.bind(styles)

export const Drawer = () => {
  const [isVisible, setVisible] = useState(false)
  const { appStore } = useStores()

  const handleDrawerClick = () => {
    setVisible(!isVisible)
  }

  return (
    <div className={cn("drawer")}>
      <Button className="menu" type="primary" icon={<MenuOutlined />} onClick={() => setVisible(true)} />
      <MuiDrawer
        title={appStore.adminRole}
        placement="left"
        onClose={handleDrawerClick}
        open={isVisible}
        className={cn("drawer__drawer")}
        extra={
          <div className={cn("drawer__icon")}>
            <Icons.Logo width={40} />
          </div>
        }
      >
        <Menu setVisibleDrawer={setVisible} />
      </MuiDrawer>
    </div>
  )
}
