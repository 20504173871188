import { makeAutoObservable } from "mobx"

class AnalyticsStore {
  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {}
}

export const analyticsStore = new AnalyticsStore()
