import { observer } from "mobx-react"
import { Flex } from "antd"
import classNames from "classnames/bind"
import { Parameters } from "./Parameters"
import { Schedule } from "./Schedule"
import { Statistics } from "./Statistics"
import { Teacher } from "./Teacher"
import { TeacherPayments } from "./TeacherPayments"

import styles from "./group-info.module.scss"
import { useStores } from "@/stores"
import { GroupMake } from "@/pages/Groups/GroupMake"

const cn = classNames.bind(styles)

export const GroupInfo = observer(() => {
  const { groupsStore } = useStores()

  return (
    <Flex className={cn("group-info")} vertical gap={32}>
      <Flex gap={24}>
        <Teacher
          fullName="Azizbek Tursunov"
          startedDate="30.05.2024"
          role="O'qituvchi"
          img="https://avatars.githubusercontent.com/u/100644251?v=4"
        />

        <Teacher
          fullName="Dilmurod Tursunov"
          startedDate="30.05.2024"
          role="Yordamchi ustoz"
          img="https://avatars.githubusercontent.com/u/12314121?v=4"
        />
      </Flex>

      <Flex align="flex-start" gap={20}>
        <Statistics />
        <Parameters />
      </Flex>

      <Schedule />

      <TeacherPayments />

      {groupsStore.groupMakeModal && <GroupMake />}
    </Flex>
  )
})
