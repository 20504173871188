import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const StudyDepartment = lazy(() =>
  import("./Homeworks/StudyDepartment")
    .then(({ StudyDepartment }) => ({ default: StudyDepartment }))
    .catch(handleCatchChunkError),
)

export const StudyDepartmentCourses = lazy(() =>
  import("./Courses").then(({ Courses }) => ({ default: Courses })).catch(handleCatchChunkError),
)

export const StudyDepartmentCourse = lazy(() =>
  import("./Courses/Course").then(({ Course }) => ({ default: Course })).catch(handleCatchChunkError),
)

export const StudyDepartmentCourseDragDrop = lazy(() =>
  import("./Courses/Course/StudyPlan/DragDrop")
    .then(({ DragDrop }) => ({ default: DragDrop }))
    .catch(handleCatchChunkError),
)

export const SingleHome = lazy(() =>
  import("./Homeworks/SingleHome/SingleHome")
    .then(({ SingleHome }) => ({ default: SingleHome }))
    .catch(handleCatchChunkError),
)

export const ByDoneHomeworkStudent = lazy(() =>
  import("./Homeworks/SingleHome/ByDone/StudentSingle")
    .then(({ StudentSingle }) => ({ default: StudentSingle }))
    .catch(handleCatchChunkError),
)

export const HomeworkDetail = lazy(() =>
  import("./Homeworks/HomeworkDetail")
    .then(({ HomeworkDetail }) => ({
      default: HomeworkDetail,
    }))
    .catch(handleCatchChunkError),
)

export const StudyDepartmentTeachersKPI = lazy(() =>
  import("./TeachersKPI").then(({ TeachersKPI }) => ({ default: TeachersKPI })).catch(handleCatchChunkError),
)

export const StudyDepartmentSource = lazy(() =>
  import("./HomeworkDatabase")
    .then(({ HomeworkDatabase }) => ({ default: HomeworkDatabase }))
    .catch(handleCatchChunkError),
)

export const StudyDepartmentHomeworkDatabaseChapters = lazy(() =>
  import("./HomeworkDatabase/Chapters").then(({ Chapters }) => ({ default: Chapters })).catch(handleCatchChunkError),
)

export const StudyDepartmentHomeworkDatabaseChaptersHomework = lazy(() =>
  import("@/pages/StudyDepartment/HomeworkDatabase/Chapters/Homework")
    .then(({ Homework }) => ({ default: Homework }))
    .catch(handleCatchChunkError),
)

export const StudyDepartmentHomeworkDatabaseChaptersHomeworkMake = lazy(() =>
  import("./HomeworkDatabase/Chapters/Homework/HomeworkMake").then(({ HomeworkMake }) => ({
    default: HomeworkMake,
  })),
)

export const Draft = lazy(() =>
  import("./Draft").then(({ Draft }) => ({ default: Draft })).catch(handleCatchChunkError),
)

export const Lessons = lazy(() =>
  import("./Draft/Lessons").then(({ Lessons }) => ({ default: Lessons })).catch(handleCatchChunkError),
)

export const LessonsHomework = lazy(() =>
  import("./Draft/Lessons/Homework")
    .then(({ DraftHomework }) => ({ default: DraftHomework }))
    .catch(handleCatchChunkError),
)

export const TeacherStatistics = lazy(() =>
  import("./TeachersStatistics")
    .then(({ TeacherStatistics }) => ({ default: TeacherStatistics }))
    .catch(handleCatchChunkError),
)

export const SwitchHomework = lazy(() =>
  import("./Courses/Course/StudyPlan/SwitchHomework").then(({ SwitchHomework }) => ({
    default: SwitchHomework,
  })),
)

export const SkillClasses = lazy(() =>
  import("./SkillClasses").then(({ SkillClasses }) => ({
    default: SkillClasses,
  })),
)
