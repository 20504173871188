export enum NotificationsEndpoints {
  GetAllForAdmin = "/get-all-for-admin",
  Notifications = "/notifications",
  UploadNotificationBanner = "/upload/notification-banners",
  Delete = "/delete",
  TemplatesGetAll = "/templates/get-all",
  TemplatesCreate = "/templates/create",
  Templates = "/templates",
  GetAll = "/get-all",
  Read = "/read",
  GetForAdmin = "/get-for-admin",
  Update = "update",
  Create = "/create",
  Settings = "/settings",
  All = "/all",
}
