import { observer } from "mobx-react"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Avatar, Button, Flex, Tag, Typography } from "antd"
import classNames from "classnames/bind"
import DefaultUserImg from "@/assets/img/img_avatar.png"
import { CalendarDay } from "@/components/CalendarDay"
import mainDictionary from "@/dictionary"
import { makeFileUrl } from "@/utils"
import MoreIcon from "@/assets/icons/more-icon.svg"

import styles from "./student.module.scss"

const cn = classNames.bind(styles)

type Props = {
  img?: string
}

export const Student = observer(({ img }: Props) => {
  console.log("")

  return (
    <Flex className={cn("student")} align="flex-start">
      <Flex className={cn("student__wrapper")} vertical gap={15}>
        <Flex justify="space-between" className={cn("student__left")} align="center">
          <Flex gap={12} align="center">
            <Avatar
              src={makeFileUrl(img || "")}
              size={60}
              icon={<img src={DefaultUserImg} alt="avatar" width={60} height={60} />}
            />

            <Flex vertical gap={4}>
              <Typography.Title className={cn("student__name")} level={5}>
                Azizbek Tursunov
              </Typography.Title>

              <Typography.Text type="danger">{mainDictionary.debt}: 20 soat</Typography.Text>
            </Flex>
          </Flex>

          <Flex gap={12}>
            <Typography.Text type="secondary">
              07.01.2024 {mainDictionary.from} <br /> 03.13.25 {mainDictionary.to}
            </Typography.Text>
            <Button icon={<img src={MoreIcon} />} type="text" shape="circle" />
          </Flex>
        </Flex>

        <Flex gap={12}>
          <Tag className={cn("student__tag")}>{mainDictionary.didNotSignContract}</Tag>
          <Tag className={cn("student__tag")}>{mainDictionary.documents}</Tag>
        </Flex>

        <Flex vertical gap={10}>
          <Typography.Text>{mainDictionary.payed} 1160 s/10 600 000 so‘m</Typography.Text>
          <Typography.Text>{mainDictionary.didNotPay} 1160 s/10 600 000 so‘m</Typography.Text>
        </Flex>
      </Flex>

      <Flex className={cn("student__right")} vertical gap={16}>
        <Flex align="center" gap={20}>
          <Button icon={<LeftOutlined />} shape="circle" />

          <Typography.Text>2-o‘quv oyi</Typography.Text>

          <Button icon={<RightOutlined />} shape="circle" />
        </Flex>

        <Flex wrap align="center" gap={10}>
          {Array(31)
            .fill("2")
            .map((item, index) => (
              <CalendarDay key={index} category="came" />
            ))}
        </Flex>
      </Flex>
    </Flex>
  )
})
