import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const MasterClass = lazy(() =>
  import("./MasterClass").then(({ MasterClass }) => ({ default: MasterClass })).catch(handleCatchChunkError),
)

export const Events = lazy(() =>
  import("./Events").then(({ Events }) => ({ default: Events })).catch(handleCatchChunkError),
)

export const SingleMasterClass = lazy(() =>
  import("./MasterClass/SingleMasterClass")
    .then(({ SingleMasterClass }) => ({ default: SingleMasterClass }))
    .catch(handleCatchChunkError),
)

export const Users = lazy(() =>
  import("./MasterClass/Users").then(({ Users }) => ({ default: Users })).catch(handleCatchChunkError),
)

export const MakeCompetition = lazy(() =>
  import("./Events/MakeCompetition")
    .then(({ MakeCompetition }) => ({ default: MakeCompetition }))
    .catch(handleCatchChunkError),
)

export const SingleCompetition = lazy(() =>
  import("./Events/SingleCompetition")
    .then(({ SingleCompetition }) => ({ default: SingleCompetition }))
    .catch(handleCatchChunkError),
)
