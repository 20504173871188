import { makeAutoObservable } from "mobx"
import { teachersApi } from "@/api"
import { addCatchNotification } from "@/modules/notifications"
import { TeachersKpiRequestParamsType, TeachersKpiResponseType } from "@/types"
import { GroupStatus } from "@/types/teachers-kpi"

class TeachersKpiStore {
  teachersKpi: TeachersKpiResponseType | null = null
  teachersKpiTotal = 0
  perPage = 10
  page = 1
  filterOptions: TeachersKpiRequestParamsType = {
    keys: ["hName"],
    status: GroupStatus.Active,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setFilterOption = (params: TeachersKpiRequestParamsType) => {
    this.filterOptions = params
  }

  setTeachersKpi = (kpiData: TeachersKpiResponseType) => {
    this.teachersKpi = kpiData
  }

  setTotalTeachersKpiTotal = (total: number) => {
    this.teachersKpiTotal = total
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setPage = (page: number) => {
    this.page = page
  }

  getTeachersKpi = (params: TeachersKpiRequestParamsType) =>
    teachersApi
      .getTeachersKpi(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setTeachersKpi(response.data)
          this.setTotalTeachersKpiTotal(response.data.total)
        }
      })
      .catch(addCatchNotification)

  reset = () => {
    this.teachersKpi = null
    this.teachersKpiTotal = 0
    this.page = 1
    this.perPage = 10
  }
}

export const teachersKpiStore = new TeachersKpiStore()
