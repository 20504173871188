export const marketDictionary = {
  add: "Qo`shish",
  hash: "#",
  name: "Nomi",
  productName: "Mahsulot nomi",
  actions: "Harakatlar",
  category: "Kategoriya",
  onSale: "Sotuvda",
  image: "Rasm",
  product: "Mahsulot",
  value: "Qiymati",
  sold: "Sotilgan",
  productsCount: "Maxsulotlar soni",
  count: "Soni",
  addCategory: "Kategoriya qo`shish",
  from: "dan",
  team: "Jamoa",
  onSaleCount: "Sotuvdagi soni",
  soldCount: "Sotilgan soni",
  to: "gacha",
  valueOfAccessories: "Akksesuar qiymati",
  nameOfAccessories: "Akksesuar nomi",
  addAccessory: "Aksessuar qo`shish",
  selectCategory: "Kategoryani tanlang",
  enterAccessoryName: "Aksessuar nomini kiriting",
  enterDescription: "Akksesuar tavsifini kiriting",
  enterAccessoryCount: "Akksesuar sonini kiriting",
  enterAccessoryPrice: "Aksessuar qiymati(narx)ini kiriting (Kumushda)",
  cancel: "Bekor qilish",
  sell: "Sotuvga qo`yish",
  givenCount: "Berilgan soni",
  student: "O'quvchi",
  hhId: "HH ID",
  photo: "Rasm",
  time: "Vaqti",
  returnedAccessory: "Aksessuarni qaytarish",
  reasonWhyReturnedThatProduct: "Aksessuar qaytarilayotgani sababini yozing",
  given: "Topshirilgan",
  stopped: "To`xtatilgan",
  stopping: "To`xtatish",
  returned: "Qaytarilgan",
  returnedProductTitle: "Sotuvi bekor qilinib qaytarilgan aksessuarlar",
  description: "Izoh",
  productDescription: "Mahsulot tavsifi",
  categoryWarningTitle: "Bu kategoriyaga maxsulot biriktirilganligi uchun, o'chirib bo'lmaydi",
  sureToArchiveProduct: "Ushbu aksessuar sotuvini to’xtatmoqchimisiz?",
  sureToRestoreProduct: "Ushbu aksessuar sotuvga qaytarmoqchimisiz?",
  productImage: "Maxsulot rasmi",
  addedSuccessfully: "Muvaffaqiyatli qo`shildi",
  editAccessory: "Aksessuar o`zgartirish",
  areYouSureToGive: "Ushbu aksessuarni sotib olgan studentga topshirmoqchimisiz?",
  categoriesPage: "Aksessuarlar kategoriyalari",
  categoryRequiredTitle: "Kategoriya uchun nom kiriting",
  accessoriesWhichStudentsBought: "Studentlar shaxsiy kabinetidan sotib olingan aksessuarlar",
  accessoriesWhichStudentsReceived: "Sotib olgan studentlarga topshirilgan aksessuarlar",
  accessoriesWhichOnSale: "Sotuvda bo`lgan aksessuarlar",
  accessoriesWhichIsStopped: "Sotuvdan to`xtatilgan aksessuarlar",
  backToPreviousVersion: "Ortga qaytarish",
  giveToStudent: "Topshirish",
}
