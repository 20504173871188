import { ChangeEvent, useEffect } from "react"
import { observer } from "mobx-react"
import { Button, Flex, Input, Typography } from "antd"
import classNames from "classnames/bind"
import { useDebounceValue } from "usehooks-ts"
import mainDictionary from "@/dictionary"
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from "@/modules/permission"
import { useStores } from "@/stores"
import { MakeContract } from "./MakeContract"
import { ContractTable } from "./Table"

import styles from "./contracts.module.scss"

const cx = classNames.bind(styles)

export const Contracts = observer(() => {
  const [debouncedValue, setValue] = useDebounceValue("", 500)
  const { settingsStore } = useStores()

  const [isAvailableAdd] = useCheckPermission({
    module: permissionObjectTypes.contracts,
    option: PermissionOptions.Read,
  })

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleMakeContract = () => {
    settingsStore.setMakeContractModal(true)
  }

  useEffect(() => {
    settingsStore.setContractsFilter({
      ...settingsStore.contractsFilter,
      page: 1,
      perPage: 10,
    })
  }, [])

  useEffect(() => {
    settingsStore.setContractsLoading(true)
    settingsStore.getContracts({ ...settingsStore.contractsFilter, search: debouncedValue })
  }, [debouncedValue, settingsStore.contractsFilter])

  return (
    <Flex className={cx("contracts")} vertical gap={20}>
      <Flex justify="space-between" align="center">
        <Typography.Title level={5}>{mainDictionary.contracts}</Typography.Title>

        {isAvailableAdd && (
          <Button onClick={handleMakeContract} type="primary">
            {mainDictionary.add}
          </Button>
        )}
      </Flex>

      <Flex className={cx("contracts__holder")} vertical>
        <Flex className={cx("contracts__top")} justify="space-between" align="center">
          <Flex vertical gap={5} className={cx("contracts__input")}>
            <Typography.Text>{mainDictionary.byName}</Typography.Text>
            <Input onChange={handleSearch} placeholder={mainDictionary.search} />
          </Flex>
        </Flex>
        <ContractTable />
      </Flex>

      {settingsStore.makeContractModal && <MakeContract />}
    </Flex>
  )
})
