import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { BalancesPeriod, OutstandingPaymants, PaidTime } from "./lazy"

export const specialRouter = [
  {
    path: ROUTES.special,
    children: [
      {
        path: ROUTES.specialOutstandingPaymants,
        index: true,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.profile}>
            <OutstandingPaymants />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.specialBalancesPeriod,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.profile}>
            <BalancesPeriod />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.specialPaidTime,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.profile}>
            <PaidTime />
          </AvailableComponent>
        ),
      },
    ],
  },
]
