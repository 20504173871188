export const studentDictionary = {
  allSilver: "Jami yig'ilgan Kumushlar:",
  allXp: "Jami yig'ilgan XP:",
  available: "Bor",
  birtDateInCorrectFormat: "Tug`ilgan sana formati noto`g`ri",
  birthCertificatesData: "Guvohnoma ma`lumotlari",
  birthDate: "Tug`ilgan sana:",
  birthDateRequired: "Tug`ilgan sana kiritish shart",
  blockedSuccessFully: "Student muvaffaqiyatli bloklandi",
  checking: "Tekshirilmoqda",
  closes: "Yaqinlari",
  confirmBlock: "Siz ushbu studentni bloklamoqchimisiz?",
  confirmChangePassword: "Siz ushbu studentning parolini o'zgartirmoqchimisiz?",
  confirmUnblock: "Siz ushbu studentni blokdan chiqarmoqchimisiz?",
  download: "Yuklash",
  print: "Chop etish",
  erpstatus: "ERP holati",
  fatherName: "Otasining ismi",
  group: "Guruh",
  certificates: "Sertifikatlar",
  readersCount: "O`qiganlar soni",
  groupSuccessfullyAddedToStudent: "Guruh muvaffaqiyatli qo`shildi",
  holyHopeStatus: "HH holati",
  id: "ID",
  imgDidNotUpload: "Yuklanmagan",
  level: "Bosqich",
  more: "Batafsil",
  name: "Ism",
  noData: "Ma`lumot mavjud emas",
  notActive: "Faol emas",
  notSuitable: "Talabga mos emas",
  number: "raqam",
  passport: "Passport/ID karta",
  passportNumberIsRequired: "Passport/ID karta raqamini kiritish shart",
  passportNumbersRequirement: "Passport/ID karta raqamlari 7 ta bo`lishi kerak",
  passportPattern: "Faqat 7 ta son kirita olasiz",
  passportsData: "Passport ma`lumotlari",
  passportSeriesIsRequired: "Passport/ID karta seriyasini kiritish shart",
  passportSeriesRequirement: "Passport/ID karta seriyasi 2 ta harf bo`lishi kerak",
  passwordGenerated: "Parol yasaldi",
  phoneNumber: "Telefon raqam",
  phoneNumberIncorrect: "Telefon raqam formati noto`g`ri",
  phoneNumberRequired: "Telefon raqam kiritish kerak",
  phonePattern: "To`g`ri formatdagi telefon raqam kiriting",
  pinfl: "JSHSHIR:",
  pinflPattern: "Faqat 14 ta son kirita olasiz",
  pinflsData: "JSHSHIR ma`lumotlari",
  pnflRequirement: "JSHSHIR 14 ta raqam bo`lishi kerak",
  privateInfo: "Shaxsiy ma`lumot",
  privateInfos: "Shaxsiy ma`lumotlar",
  requiredPnfl: "JSHSHIR kiritish shart",
  searchInputLabel: "Ism yoki HHID",
  searchInputPlaceholder: "Qidiruv",
  searchSelectDefaultValue: "Barchasi",
  tableId: "HHID",
  tableFullname: "Ism-familiya",
  searchSelectLabel: "Yo'nalish",
  seeResult: "Natijalarni ko`rish",
  sentAuthor: "Jo'natuvchi",
  sentNumber: "Jo'natilgan raqami",
  sentSms: "Jo'natilgan smslar",
  series: "seriya",
  seriesPattern: "Faqat 2 ta harf kirita olasiz",
  sistem: "Tizim",
  sms: "Sms",
  smsHistory: "Sms tarixi",
  smsNot: "Bu studentning smslari yo'q",
  smsSend: "Sms jo'natish",
  smsSended: "Sms jo'natildi",
  studentPhoneNumber: "Talabaning telefon raqami:",
  successChangeLogin: "Siz ushbu o'quvchini loginini o'zgartirdingiz!",
  suitable: "Talabga mos",
  surname: "Familiya",
  testimony: "Guvohnama",
  testNumberIsRequired: "Guvohnama raqamini shart",
  testSeriesIsRequired: "Guvohnama seriyani kiritish shart",
  toWhom: "Kimga",
  unavailable: "Yo'q",
  unblockSuccessFully: "Student muvaffaqiyatli blokndan chiqarildi",
  downloadCertificate: "Yuklab olish",
  moreResults: "Batafsil natijalar",
  share: "Ulashish",
  startDate: "Boshlash vaqti",
  groupEndDate: "Tugash vaqti",
  mainHomeworkResults: "Asosiy vazifa natijalari",
  maxLengthOfHomeworkDescription: "Matn uzunligi belgilangan maksimal belgilar sonidan oshib ketdi.",
  shouldEnterDescription: "Izoh yozish shart",
  shouldFill: "To`ldirish shart",
  age: "Yoshi",
  email: "Email",
  mobileNumber: "Mobil tel raqam",
  mobile: "mobile",
  seeResults: "Natijani ko‘rish",
  totalInfo: "Umumiy",
  infoAboutCourse: "Kursi haqida ma’lumot",
  privateAndContactInfo: "Shaxsiy va kontakt ma’lumotlar",
  totalXp: "Umumiy Xp",
  loginText: "Login",
  enteringDate: "Kirish sanasi",
  lessonPayments: "Dars to'lovlari:",
  blackList: "Qora ro‘yxat:",
  schedulePeriod: "Jadval davri",
  personalAccount: "Shaxsiy hisob",
  income: "Kirim",
  expanse: "Chiqim",
  returnMoney: "Qaytarish",
  paymentMethod: "Tolov usuli",
  whoAdd: "Kimga qo‘shdi",
  account: "Hisob",
  sendMessageToStudentAboutPayment: "To‘lov haqida o‘quvchiga xabar yuborish",
  paymentForStudy: "O‘quv uchun to‘lov",
  homeworkDesignation: "Vazifa belgilash",
  addHistory: "Tarix qo‘shish",
  passportLabel: "Pasport(AD1234567) yoki tug'ilganlik",
  futureFieldsPlatform: "Kelajak kasblari | Platformasi",
  contractNumber: "Shartnoma raqami",
  eContract: "E-Shartnoma",
  debtorGrants: "Qarzdor grantlar",
  agencyForYouthAffairs: "Yoshlar ishlari agentligi",
  payBackApproximatelyDate: "To'lov qaytarish taxminiy sanasi",
  engishForIt: "English for IT",
  typesOfMoneyInWallet: "Koshelekdagi pullar tarkibi:",
  discountType: "Chegirma turi (mavjud bo'lsa)",
  paymentAmount: "To'lov summasi",
  priceAndDiscounts: "Narxlar, chegirmalar, qo'shimcha to'lovlar",
}
