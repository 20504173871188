import { UniqueIdentifier } from "@dnd-kit/core"
import { SkillClassesVideosStatus } from "./constants"

export interface ICourseFormValues {
  disciplineId: number
  name: string
  photo?: string
}

export interface ICourseMonthFormValues {
  lessonsPerMonth: number
  roleIds: number[]
}

export interface IAddTopicForm {
  name: string
}

export interface IDraggedItem {
  to: number
  id: number | UniqueIdentifier
}

export enum CourseTabs {
  LessonMonth = "lesson-month",
  LessonPlan = "lesson-plan",
}

export type SkillClassesFormValues = Record<
  string,
  {
    courseType: string
    name: string
  }
>

export interface SkillClassesEditData {
  courseType: string
  name: string
}

export interface SkillClassesVideoStatusHistory {
  id: number
  message: string
  status: SkillClassesVideosStatus
  createdAt: string
}
