import { Dispatch, SetStateAction } from "react"
import { AxiosRequestConfig } from "axios"
import { StaffGroupsDetail } from "@/api/groups"
import { IResponse } from "@/api/types"
import mainDictionary from "@/dictionary"
import { addCatchNotification } from "@/modules/notifications"
import { ICourseFormValues } from "@/pages/StudyDepartment/types"
import { IGetRolesParams } from "@/stores/controls/type"
import { GroupDownloadOptionType, OnDownloadProgressType } from "@/stores/groups"
import {
  AddChapterHomeworkReq,
  AddChapterReq,
  AddCourseParams,
  EditChapterHomeworkReq,
  EditChapterReq,
  EditCourseParams,
  EditDraftParams,
  GetChapterHomeworkReq,
  GetCourseChaptersReq,
  GetHomeworkDbCoursesReq,
  GetTeachersStatisTicsReq,
  IAddCurriculum,
  IChangeTopicPositionRequest,
  IEditCourse,
  IEditCourseByMonth,
  IGetCourseCurriculums,
  IGetCourseRequest,
  IGetFilterObjects,
  IGetGroupRequest,
  IGetGroupStudentsRequest,
  IGetHomeworkStudents,
  MoveToCompletionParams,
  SetLessonReq,
  SwitchHomeworkParams,
  TGetGroupActionsFilterObjects,
} from "@/stores/study-department/types"
import { IRoles } from "../controls/types"
import { Endpoints } from "../endpoints"
import { INetworkConfig, Instance } from "../instance"
import { StudyDepartmentEndpoints } from "./endpoints"
import {
  ChapterHomework,
  ChapterHomeworkRes,
  CourseChapterRes,
  GetHomeworkDbCoursesRes,
  GetHomeworkStatisticByCheckingRes,
  GetHomeworkStatisticsRes,
  GetSkillClassesVideosParams,
  GetTeachersStatisTicsRes,
  GetVideoUploadLink,
  GetVideoUploadLinkRes,
  ICourse,
  ICourseResponse,
  ICurriculum,
  IDiscipline,
  IFilterObjectsResponse,
  IGetCourseCurriculumsRes,
  IGetCourseResponse,
  IHomeworkStatisticGroupsRes,
  SingleCurriculum,
  SKillClassesVideosItem,
  SKillClassesVideosRes,
  StudentsBufferRes,
  TFilterObjectResponse,
  SkillClassesUploadVideoParams,
  SkillClassesEditDataParam,
} from "./types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class StudyDepartmentApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  updateVideoStatus = (params: SkillClassesUploadVideoParams): Promise<IResponse<null>> =>
    this.put(
      `${StudyDepartmentEndpoints.PublicVideos}${StudyDepartmentEndpoints.UpdateStatus}/${params.id}`,
      params.data,
    )

  editSkillClassesVideos = (params: SkillClassesEditDataParam): Promise<IResponse<null>> =>
    this.put(`${StudyDepartmentEndpoints.PublicVideos}/${params.id}`, params.data)

  getVideoUploadLink = (params: GetVideoUploadLink): Promise<IResponse<GetVideoUploadLinkRes>> =>
    this.post(`${StudyDepartmentEndpoints.PublicVideos}`, params)

  getSkillClassesVideos = (params?: GetSkillClassesVideosParams): Promise<IResponse<SKillClassesVideosRes>> =>
    this.get(StudyDepartmentEndpoints.PublicVideos, { params })

  deleteSkillClassesVideos = (id: number): Promise<IResponse<SKillClassesVideosItem>> =>
    this.delete(`${StudyDepartmentEndpoints.PublicVideos}/${id}`)

  getHomeworkStatistics = (params?: IGetGroupRequest): Promise<IResponse<IHomeworkStatisticGroupsRes>> =>
    this.get(`${StudyDepartmentEndpoints.HomeworkStatistics}${StudyDepartmentEndpoints.Groups}`, { params })

  getGroupStudents = (params: IGetGroupStudentsRequest): Promise<IResponse<GetHomeworkStatisticsRes>> =>
    this.get(`${StudyDepartmentEndpoints.HomeworkStatistics}${StudyDepartmentEndpoints.Student}`, { params })

  getGroupActionsFilterObjects = (params: TGetGroupActionsFilterObjects): Promise<IResponse<TFilterObjectResponse>> =>
    this.get(`${StudyDepartmentEndpoints.GroupActions}${StudyDepartmentEndpoints.FilterObjects}`, { params })

  getHomeworksByChecking = (params: IGetHomeworkStudents): Promise<IResponse<GetHomeworkStatisticByCheckingRes>> =>
    this.get(`${StudyDepartmentEndpoints.HomeworkStatistics}${StudyDepartmentEndpoints.ByChecking}`, { params })

  getFilterObjects = (params?: IGetFilterObjects): Promise<IResponse<IFilterObjectsResponse>> =>
    this.get(
      `${StudyDepartmentEndpoints.HomeworkStatistics}${StudyDepartmentEndpoints.Groups}${StudyDepartmentEndpoints.FilterObjects}`,
      { params },
    )

  getGroupTeachers = (params: number): Promise<IResponse<StaffGroupsDetail>> =>
    this.get(
      `${StudyDepartmentEndpoints.HomeworkStatistics}${StudyDepartmentEndpoints.Groups}/${params}${StudyDepartmentEndpoints.Detail}`,
    )

  getCourses = (params?: IGetCourseRequest): Promise<IResponse<IGetCourseResponse>> =>
    this.get(`${StudyDepartmentEndpoints.Courses}`, { params })

  getDisciplines = (): Promise<IResponse<IDiscipline[]>> =>
    this.get(`${StudyDepartmentEndpoints.Courses}${StudyDepartmentEndpoints.ActiveDisciplines}`)

  addCourse = (info: ICourseFormValues): Promise<IResponse<ICourse>> =>
    this.post(StudyDepartmentEndpoints.Courses, info)

  deleteCourse = (id: number): Promise<IResponse<ICourse>> => this.delete(`${StudyDepartmentEndpoints.Courses}/${id}`)

  editCourse = (params: IEditCourse): Promise<IResponse<ICourse>> =>
    this.put(`${StudyDepartmentEndpoints.Courses}/${params.id}`, params.data)

  getCourse = (id: number): Promise<IResponse<ICourseResponse>> =>
    this.get(`${StudyDepartmentEndpoints.Courses}/${id}${StudyDepartmentEndpoints.StudyMonth}`)

  editCourseByMonth = (params: IEditCourseByMonth): Promise<IResponse<ICourseResponse>> =>
    this.put(`${StudyDepartmentEndpoints.Courses}/${params.id}${StudyDepartmentEndpoints.StudyMonth}`, params.data)

  getCourseCurriculums = (params: IGetCourseCurriculums): Promise<IResponse<IGetCourseCurriculumsRes>> =>
    this.get(`${StudyDepartmentEndpoints.Curriculum}${StudyDepartmentEndpoints.List}`, { params })

  getStudyRole = (params?: IGetRolesParams): Promise<IResponse<IRoles[]>> =>
    this.get(
      `${StudyDepartmentEndpoints.Courses}${StudyDepartmentEndpoints.StudyMonth}${StudyDepartmentEndpoints.Roles}`,
      { params },
    )

  addCurriculum = (params: IAddCurriculum): Promise<IResponse<ICurriculum>> =>
    this.post(StudyDepartmentEndpoints.Curriculum, params)

  deleteCurriculum = (id: number): Promise<IResponse<ICurriculum>> =>
    this.delete(`${StudyDepartmentEndpoints.Curriculum}/${id}`)

  editCurriculum = (params: IAddCurriculum): Promise<IResponse<ICurriculum>> => {
    const { courseId, name } = params

    return this.put(`${StudyDepartmentEndpoints.Curriculum}/${courseId}`, { name })
  }

  changeTopicPosition = (params: IChangeTopicPositionRequest): Promise<IResponse<ICurriculum>> =>
    this.patch(`${StudyDepartmentEndpoints.Curriculum}${StudyDepartmentEndpoints.Positions}`, params)

  getCourseChapters = (params: GetCourseChaptersReq): Promise<IResponse<CourseChapterRes>> =>
    this.get(StudyDepartmentEndpoints.HomeworkDbLessons, { params })

  addChapter = (params: AddChapterReq): Promise<IResponse<CourseChapterRes>> =>
    this.post(StudyDepartmentEndpoints.HomeworkDbLessons, params)

  editChapter = (params: EditChapterReq): Promise<IResponse<CourseChapterRes>> =>
    this.put(`${StudyDepartmentEndpoints.HomeworkDbLessons}/${params.id} `, params.data)

  getChapterHomework = (params: GetChapterHomeworkReq): Promise<IResponse<ChapterHomeworkRes>> =>
    this.get(StudyDepartmentEndpoints.HomeworkDb, { params })

  deleteHomework = (params: number): Promise<IResponse<ChapterHomeworkRes>> =>
    this.delete(`${StudyDepartmentEndpoints.HomeworkDb}/${params}`)

  addHomeworkChapter = (params: AddChapterHomeworkReq): Promise<IResponse<ChapterHomeworkRes>> =>
    this.post(StudyDepartmentEndpoints.HomeworkDb, params)

  getHomework = (params: number): Promise<IResponse<ChapterHomework>> =>
    this.get(`${StudyDepartmentEndpoints.HomeworkDb}/${params}`)

  editHomeworkChapter = (params: EditChapterHomeworkReq): Promise<IResponse<ChapterHomeworkRes>> =>
    this.put(`${StudyDepartmentEndpoints.HomeworkDb}/${params.id}`, params.data)

  setLessonToCurriculum = (params: SetLessonReq): Promise<IResponse<ICourse>> =>
    this.put(`${StudyDepartmentEndpoints.HomeworkDb}${StudyDepartmentEndpoints.SetLessons}`, params)

  switchLessonToCurriculum = (params: SwitchHomeworkParams): Promise<IResponse<ICourse>> =>
    this.put(`${Endpoints.HomeworkDb}${Endpoints.SetLessons}`, params)

  getHomeworkDbCourses = (params: GetHomeworkDbCoursesReq): Promise<IResponse<GetHomeworkDbCoursesRes>> =>
    this.get(`${StudyDepartmentEndpoints.HomeworkDb}${StudyDepartmentEndpoints.Courses}`, { params })

  addHomeworkDbCourse = (params: AddCourseParams): Promise<IResponse<GetHomeworkDbCoursesRes>> =>
    this.post(`${StudyDepartmentEndpoints.HomeworkDb}${StudyDepartmentEndpoints.Courses}`, params)

  getTeacherStatistics = (params: GetTeachersStatisTicsReq): Promise<IResponse<GetTeachersStatisTicsRes>> =>
    this.get(`${StudyDepartmentEndpoints.Academic}${StudyDepartmentEndpoints.Statistics}`, { params })

  editHomeworkDdbCourse = (params: EditCourseParams): Promise<IResponse<GetHomeworkDbCoursesRes>> =>
    this.put(`${StudyDepartmentEndpoints.HomeworkDb}${StudyDepartmentEndpoints.Courses}/${params.id}`, params.data)

  editDraft = (params: EditDraftParams): Promise<IResponse<GetHomeworkDbCoursesRes>> =>
    this.put(`${StudyDepartmentEndpoints.HomeworkDb}${StudyDepartmentEndpoints.Lessons}/${params.id}`, params.data)

  moveToCompletion = (params: MoveToCompletionParams): Promise<IResponse<GetHomeworkDbCoursesRes>> =>
    this.put(`${StudyDepartmentEndpoints.HomeworkDb}/${params.id}${StudyDepartmentEndpoints.Move}`, params.data)

  getSingeCurriculum = (id: number): Promise<IResponse<SingleCurriculum>> =>
    this.get(`${Endpoints.HomeworkDb}/${id}${Endpoints.ByCurriculumId}`)

  getStudentHomeworkDownloadFile = (options: AxiosRequestConfig): Promise<IResponse<StudentsBufferRes>> =>
    this.get(StudyDepartmentEndpoints.StudentsNotDoingHomework, options)

  getStudentsStatisticsFile: any = async (setPercent: Dispatch<SetStateAction<number>>) => {
    const onDownloadProgress: OnDownloadProgressType = (e) => {
      const percent = Math.floor((e.loaded / (e.total || e?.loaded)) * 100)

      setPercent(percent)
    }

    const options: GroupDownloadOptionType = { responseType: "blob", onDownloadProgress }

    try {
      const data = await this.getStudentHomeworkDownloadFile(options)

      return data
    } catch (error: any) {
      addCatchNotification(mainDictionary.errorDownloadingFile)
      throw error
    }
  }
}

export const studyDepartmentApi = new StudyDepartmentApi(config)
