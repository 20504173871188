import { observer } from "mobx-react"
import { EditOutlined } from "@ant-design/icons"
import { Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"

import styles from "./parameters.module.scss"
import { useStores } from "@/stores"

const cn = classNames.bind(styles)

export const Parameters = observer(() => {
  const { groupsStore } = useStores()

  const handleEdit = () => {
    groupsStore.setGroupMakeModal(true)
  }

  return (
    <div className={cn("parameters")}>
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center">
          <Typography.Title level={5}>{mainDictionary.parameters}</Typography.Title>

          <Button onClick={handleEdit} className={cn("parameters__button")} type="text" icon={<EditOutlined />}>
            {mainDictionary.edit}
          </Button>
        </Flex>
        <Flex vertical gap={20}>
          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.branch}:</Typography.Text>
            <Typography.Text className={cn("parameters__price")}>Chilonzor</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.course}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>Grafik dizayn</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.type}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>Barchasi</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.category}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>Dizayn</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.paymentType}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>Ishtirok uchun</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.productType}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>Asosiy</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.averageAge}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>18</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.capacityOfStudents}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>21</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.existantStudent}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>21</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.contracts}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>21</Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.paidStudents}</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>21</Typography.Text>
          </div>
        </Flex>
      </Flex>
    </div>
  )
})
