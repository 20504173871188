import { StaffAccountStatus, StaffWorkStatus } from "@/api/users"
import { GroupStatus } from "../teachers/types"

export enum StaffType {
  NEW = 1,
  ACTIVE = 2,
  BLOCKED = 3,
}

export enum KeyType {
  FirstName = "firstName",
  LastName = "lastName",
}

export enum AssistantsStatus {
  Active = "Aktiv",
  DisActive = "Aktiv emas",
}

export interface IAssistant {
  id: number
  photo: string
  firstName: string
  lastName: string
  workStatus: StaffWorkStatus
  isActive: boolean
  accountStatus: StaffAccountStatus
  staffType: number
  login: string
  rating: number
  createdAt: string
}

export interface IAssistantGroup {
  id: number
  name: string
  beginTime: string
  endTime: string
  studentsCount: number
  status: GroupStatus
  staff: IStaff
}

export interface IStaff {
  assistants: IAssistant[]
  teacher: ITeacher
}

export interface ITeacher {
  id: number
  photo: string
  firstName: string
  lastName: string
  workStatus: number
  isActive: boolean
  accountStatus: number
  staffType: number
  login: string
  rating: number
  createdAt: string
  genderType: number
  phone: string
}

export interface IAssistantAttendance {
  attendances: IAttendance[]
  group: IGroup
  rating: number
}

export interface IAttendance {
  id: number
  lesson: ILesson
  rating: number
  photo: string
  photoTime: string
  createdAt: string
}

export interface ILesson {
  id: number
  name: string
  createdAt: string
}

export interface IGroup {
  id: number
  name: string
  beginTime: string
  endTime: string
  studentsCount: number
  status: number
  staff: any
}
