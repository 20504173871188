import mainDictionary from "@/dictionary"
import { NotificationFilterTypes } from "@/types"

export const notificationFilterOptions = [
  { label: mainDictionary.allOfThem, value: null },
  { label: mainDictionary.attendance, value: NotificationFilterTypes.AcademicAttendance },
  { label: mainDictionary.academicRemindAssignHomework, value: NotificationFilterTypes.AcademicRemindAssignHomework },
  {
    label: mainDictionary.academicHomeworkCompletionAlert,
    value: NotificationFilterTypes.AcademicHomeworkCompletionAlert,
  },
  { label: mainDictionary.academicExam, value: NotificationFilterTypes.AcademicExam },
  { label: mainDictionary.academicExamCompletionAlert, value: NotificationFilterTypes.AcademicExamCompletionAlert },
  { label: mainDictionary.academicGroup, value: NotificationFilterTypes.AcademicGroup },
]
