import { observer } from "mobx-react"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import { CalendarDay } from "@/components/CalendarDay"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { MakeSchedule } from "./MakeSchedule"
import { RowItem } from "./RowItem"

import styles from "./schedule.module.scss"

const cn = classNames.bind(styles)

export const Schedule = observer(() => {
  const { groupsStore } = useStores()

  const handleAddSchedule = () => {
    groupsStore.setScheduleModal(true)
  }

  return (
    <Flex gap={40} vertical className={cn("schedule")}>
      <Flex justify="space-between">
        <Typography.Title level={5}>{mainDictionary.schedule}</Typography.Title>

        <Button onClick={handleAddSchedule} type="primary">
          {mainDictionary.add}
        </Button>
      </Flex>

      <Flex vertical gap={35}>
        <RowItem />
        <RowItem />
        <RowItem />
      </Flex>

      <Flex vertical gap={12}>
        <Flex gap={20} align="center">
          <Typography.Title level={5}>Avgust 2024</Typography.Title>

          <Flex gap={8}>
            <Button icon={<LeftOutlined />} type="text" size="small" />
            <Button icon={<RightOutlined />} type="text" size="small" />
          </Flex>
        </Flex>

        <Flex gap={10}>
          {Array(14)
            .fill("2")
            .map((item, index) => (
              <CalendarDay key={index} category="schedule" />
            ))}
        </Flex>
      </Flex>

      {groupsStore.scheduleModal && <MakeSchedule />}
    </Flex>
  )
})
