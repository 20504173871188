import "./contract.css"

import { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { PaperClipOutlined } from "@ant-design/icons"
import { Button, Form, Input, Modal, Select, Upload, UploadProps } from "antd"
import { RcFile } from "antd/es/upload"
import { UploadFile } from "antd/lib"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { uploadContract } from "@/router/upload"
import { useStores } from "@/stores"
import { ContractFormValues } from "@/stores/settings"
import { extractContractPathname, makeFileUrl } from "@/utils"
import { OtherOption, validateFile } from "../constants"

import styles from "./make-contract.module.scss"

const cn = classNames.bind(styles)

export const MakeContract = observer(() => {
  const { settingsStore, studyDepartment } = useStores()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState<UploadFile[]>([])
  const [percent, setPercent] = useState(0)

  const handleClose = () => {
    settingsStore.setMakeContractModal(false)
    settingsStore.setSelectedContract(null)
  }

  const handleContractUpload: UploadProps["onChange"] = async ({ fileList: newFileList, file }) => {
    if (!newFileList.length) {
      return
    }

    setLoading(true)
    newFileList[0].status = "uploading"
    setFiles(newFileList)

    try {
      const response = await uploadContract(newFileList[0].originFileObj! as RcFile, (percent: number) => {
        file.percent = percent
        setFiles([file])
        setPercent(percent)
      })

      file.url = makeFileUrl(response[0].path)
      file.status = "done"
      setFiles([file])
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    } finally {
      setLoading(false)
    }
  }

  const courseOptions = useMemo(() => {
    const groups = studyDepartment.allCourses?.courses?.map((item) => ({
      value: item.id,
      label: item.name,
    }))

    return [OtherOption, ...(groups || [])]
  }, [studyDepartment.allCourses])

  const handleFileChange = () => false

  useEffect(() => {
    studyDepartment.getCourses({
      page: 1,
      perPage: 150,
    })
  }, [])

  const handleRemove = () => {
    setFiles([])
  }

  const handleChangeCourse = (value: number) => {
    if (value) {
      const course = courseOptions?.find((item) => item.value === value)

      form.setFieldValue("title", course?.label || "")

      return
    }

    form.setFieldValue("title", "")
  }

  const handleSuccess = async () => {
    handleClose()

    settingsStore.setContractsLoading(true)

    addSuccessNotification(mainDictionary.successEdit)

    await settingsStore.getContracts(settingsStore.contractsFilter)
  }

  const handleSubmit = async (value: ContractFormValues) => {
    const { attachment, ...values } = value

    const info = {
      ...values,
      attachment: {
        size: files?.[0]?.size || 0,
        url: extractContractPathname(files?.[0]?.url) || "",
        origName: files?.[0]?.name || "",
      },
    }

    setLoading(true)

    try {
      if (settingsStore.selectedContract?.id) {
        const data = {
          id: settingsStore.selectedContract?.id,
          data: info,
        }

        const res = await settingsStore.editContract(data)

        if (res.success) {
          handleSuccess()
        }

        return
      }

      const res = await settingsStore.addContract(info)

      if (res.success) {
        handleSuccess()
      }
    } catch (err: Error | unknown) {
      addCatchNotification(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (percent === 100) {
      setTimeout(() => {
        setPercent(0)
      }, 100)
    }
  }, [percent])

  useEffect(() => {
    if (settingsStore.selectedContract) {
      form.setFieldsValue({
        courseId: settingsStore.selectedContract?.course?.id,
        title: settingsStore.selectedContract?.title,
        attachment: settingsStore.selectedContract?.attachment,
      })

      setFiles([
        {
          status: "done",
          url: makeFileUrl(settingsStore.selectedContract?.attachment?.url),
          name: settingsStore.selectedContract?.attachment?.origName,
          uid: settingsStore.selectedContract?.attachment?.url,
          size: settingsStore.selectedContract?.attachment?.size,
        },
      ])
    }
  }, [settingsStore.selectedContract])

  const title = settingsStore?.selectedContract?.id ? mainDictionary?.editingContract : mainDictionary.addingContract

  return (
    <Modal
      okButtonProps={{ loading }}
      onOk={form.submit}
      title={title}
      onCancel={handleClose}
      className={cn("make-contract")}
      open={settingsStore.makeContractModal}
      okText={settingsStore?.selectedContract?.id ? mainDictionary.edit : mainDictionary.add}
      cancelText={mainDictionary.cancel}
    >
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <Form.Item
          name="courseId"
          rules={[{ required: true, message: mainDictionary.requiredField }]}
          label={mainDictionary.course}
        >
          <Select
            showSearch
            onChange={handleChangeCourse}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={courseOptions}
          />
        </Form.Item>

        <Form.Item
          label={mainDictionary.name}
          name="title"
          rules={[{ required: true, message: mainDictionary.requiredField }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="attachment" rules={[{ required: true, message: mainDictionary.requiredFileUpload }]}>
          <Upload
            accept=".docx"
            fileList={files}
            beforeUpload={validateFile}
            onChange={handleContractUpload}
            maxCount={1}
            className={cn("make-contract__item")}
            customRequest={handleFileChange}
            onRemove={handleRemove}
          >
            <Button
              className={cn("make-contract__item")}
              style={{ width: "100%" }}
              disabled={files.length > 0}
              icon={<PaperClipOutlined />}
            >
              {mainDictionary.upload}
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
})
