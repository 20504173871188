import { makeAutoObservable, runInAction } from "mobx"
import { ActionTypes, auditsApi, IAuditData, IAuditParams } from "@/api/audit"
import { controlsApi, IRoles } from "@/api/controls"
import { addCatchNotification } from "@/modules/notifications"

class AuditStore {
  isOpenStaffRolesModal = false
  isOpenGroupAttendanceModal = false
  isOpenExamModal = false
  audits: IAuditData[] | [] = []
  page = 1
  perPage = 10
  key = ""
  roles: IRoles[] | [] = []
  permissionName: string | undefined = undefined
  roleId: number | undefined = undefined
  startDate: string | undefined = undefined
  endDate: string | undefined = undefined
  total = 0
  singleAuditId: number | null = null
  actionType: ActionTypes | null = null
  isVisibleGamificationModal = false
  isEditGamification = false
  isOpenStudyDepartmentModal = false
  definitionModal = false
  comment: string | null = null
  isGamificationOfferModal = false
  additionalDesc: string | null = null
  permissionNames: string[] | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setPermissionNames = (params: string[] | null) => {
    this.permissionNames = params
  }

  setAdditionalDesc = (params: string | null) => {
    this.additionalDesc = params
  }

  setDefinitionModal = (params: boolean) => {
    this.definitionModal = params
  }

  setComment = (params: string | null) => {
    this.comment = params
  }

  setIsOpenStudyDepartment = (params: boolean) => {
    this.isOpenStudyDepartmentModal = params
  }

  resetAuditFilter = () => {
    this.page = 1
    this.startDate = undefined
    this.endDate = undefined
    this.roleId = undefined
    this.key = ""
    this.permissionName = undefined
  }

  setEditedGamification = (isEdit: boolean) => {
    this.isEditGamification = isEdit
  }

  setGamificationModal = (isVisible: boolean) => {
    this.isVisibleGamificationModal = isVisible
  }

  getSingleAudit = (id: number) =>
    auditsApi
      .getSingleAudits(id)
      .then((res) => {
        if (res.success) {
          try {
            const comment = JSON.parse(res.data.detail)

            if (res.data?.desc && !comment?.comment) {
              this.setComment(res?.data?.desc)

              return
            }

            this.setComment(comment?.comment)
          } catch (error: Error | unknown) {
            addCatchNotification(error)
          }
        }
      })
      .catch(addCatchNotification)

  getAudits = (params: IAuditParams) =>
    auditsApi
      .getAudits(params)
      .then((res) => {
        if (res.success) {
          this.setAudits(res.data.audits)
          this.setTotal(res.data.total)
        }
      })
      .catch(addCatchNotification)

  getAuditPermissions = () =>
    controlsApi
      .getAuditPermissions()
      .then((res) => {
        if (res.success) {
          this.setPermissionNames(res.data.names)
        }
      })
      .catch(addCatchNotification)

  getRoles = () =>
    controlsApi
      .getRoles()
      .then((res) => {
        if (res.success) {
          runInAction(() => {
            this.roles = res.data.roles
          })
        }
      })
      .catch((err) => {
        addCatchNotification(err.response?.data?.error?.errMsg)
      })

  setAudits = (audits: IAuditData[]) => {
    this.audits = audits
  }

  setTotal = (total: number) => {
    this.total = total
  }

  setPermissionName = (name: string) => {
    this.permissionName = name
  }

  setKey = (key: string) => {
    this.key = key
  }

  setStartDate = (time: string | undefined) => {
    this.startDate = time
  }

  setEndDate = (time: string | undefined) => {
    this.endDate = time
  }

  setRoleId = (roleId: number) => {
    this.roleId = roleId
  }

  setIsOpenStaffRolesModal = (isOpen: boolean) => {
    this.isOpenStaffRolesModal = isOpen
  }

  setIsOpenGroupAttendanceModal = (isOpen: boolean) => {
    this.isOpenGroupAttendanceModal = isOpen
  }

  setIsOpenExamModal = (isOpen: boolean) => {
    this.isOpenExamModal = isOpen
  }

  setSingleAuditId = (id: number | null) => {
    this.singleAuditId = id
  }

  setActionTypes = (actionType: ActionTypes | null) => {
    this.actionType = actionType
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setGamificationOfferModal = (value: boolean) => {
    this.isGamificationOfferModal = value
  }

  reset = () => {
    this.isOpenStaffRolesModal = false
    this.isOpenGroupAttendanceModal = false
    this.isOpenExamModal = false
    this.page = 1
    this.perPage = 10
    this.total = 0
    this.actionType = null
    this.singleAuditId = null
    this.roleId = undefined
    this.endDate = undefined
    this.startDate = undefined
    this.permissionName = undefined
    this.isEditGamification = false
    this.isVisibleGamificationModal = false
    this.roles = []
    this.audits = []
    this.isGamificationOfferModal = false
  }
}

export const auditStore = new AuditStore()
