import { observer } from "mobx-react"
import { Button, Flex, Modal, Space, Table } from "antd"
import { useBoolean } from "usehooks-ts"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addNotification, NotificationMessageType } from "@/modules/notifications"
import { branchOfficeApi } from "@/pages/Controls/BranchOffice/shared/branch-office.api"
import { branchOfficeStore } from "../shared/branch-office.store"
import { BranchOffice } from "../types"

export const BranchOfficeDeleteModal = observer(() => {
  const {
    inActionBranchOffice,
    setOpenDeleteModalStatus,
    isOpenDeleteModal,
    getBranchOffices,
    setInActionBranchOffice,
  } = branchOfficeStore
  const { value: isLoading, setTrue: setTrueLoading, setFalse: setFalseLoading } = useBoolean()

  const handleModalClose = () => {
    if (isLoading) {
      return
    }

    setOpenDeleteModalStatus(false)
  }

  if (!inActionBranchOffice) {
    if (isOpenDeleteModal) {
      addNotification({ message: mainDictionary.openDeleteModalMessage, messageType: NotificationMessageType.Info })
    }

    return null
  }

  const columns = [
    {
      title: mainDictionary.id,
      render: (item: BranchOffice) => <span>{item.id}</span>,
    },
    {
      title: mainDictionary.branchOfficeName,
      render: (item: BranchOffice) => <span>{item.name}</span>,
    },
  ]

  const handleOkButtonClick = async () => {
    setTrueLoading()

    try {
      await branchOfficeApi.deleteOffices(inActionBranchOffice.id)
      await getBranchOffices()

      setInActionBranchOffice(null)
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    } finally {
      setFalseLoading()
      setOpenDeleteModalStatus(false)
    }
  }

  return (
    <Modal
      open={isOpenDeleteModal}
      title={mainDictionary.deleteBranchOffice(inActionBranchOffice.name)}
      footer={null}
      onCancel={handleModalClose}
    >
      <Flex vertical gap={10}>
        {inActionBranchOffice && <Table columns={columns} dataSource={[inActionBranchOffice]} pagination={false} />}

        <Space style={{ width: "100%", justifyContent: "end" }}>
          <Button onClick={handleModalClose} disabled={isLoading}>
            {mainDictionary.nope}
          </Button>

          <Button type="primary" onClick={handleOkButtonClick} disabled={isLoading} loading={isLoading}>
            {mainDictionary.yes}
          </Button>
        </Space>
      </Flex>
    </Modal>
  )
})
