/* eslint-disable max-len */
export const ROUTES = {
  home: "/",
  signIn: "/signin",
  groups: "/groups",
  edit: "/edit",
  singleGroup: "/groups/:id",

  audits: "/audits",

  management: "/management",
  managementAutoCall: "/auto-call",
  managementAutoCallHistory: "/auto-call/history/:id",

  controls: "/control",
  controlRoles: "/control",
  singleRole: "/control/roles/:id",
  controlRolesAdd: "/control/roles/add",
  controlUsers: "/control/:userId/users",
  controlUsersAdd: "/control/users/add",
  controlUserEdit: "/control/:id/users/:userId/edit",
  editRole: "/control/roles/edit/:id",
  controlBranchOffice: "/branch-office",
  teachers: "/teachers",
  teacherSingle: "/teachers/:id",
  students: "/students",
  singleStudent: "/students/:studentId",
  studentPaymentHistory: "/student/:id/payment-history",
  singleStudentResult: "/students/:id/result",
  studentAdd: "/students/add",
  contracts: "/contracts",
  contractsHistory: "/contracts/history/:id",

  gamification: "/gamification",
  gamificationStatistics: "/gamification/statistics",
  gamificationLevels: "/gamification/levels",
  gamificationLevelsSingle: "/gamification/levels/:id",
  gamificationActions: "/gamification/actions",
  gamificationActionSingle: "/gamification/actions/:id",
  gamificationHomeworkExamSingle: "/gamification/actions/homework-exam/:id",
  gamificationHomeworkHomeworkSingleAdd: "/gamification/actions/homework-exam/:id/homework-add",
  gamificationHomeworkHomeworkSingle: "/gamification/actions/homework-exam/:id/homework/:levelId/edit",
  gamificationCameSingle: "/gamification/actions/came/:id",
  gamificationActionByPassExamSingle: "/gamification/actions/:id/passExam",
  gamificationConnect: "/gamification/connect",
  gamificationConnectGroupSingle: "/gamification/connect/:id",
  gamificationConnectActionSingle: "/gamification/connect/action/:id",
  gamificationStudent: "/gamification/:categoryId/student/:id",
  gamificationStudentStatistics: "/gamification/statistics/:id",
  gamificationStudentStatisticsMore: "/gamification/statistics/:id/:methodId",
  gamificationTheBestOnes: "/gamification/the-best-ones",
  gamificationTheBestOnesAdd: "/gamification/the-best-ones/add",

  usersAcademic: "/users/academic",
  usersAcademicSingle: "/users/academic/:id",
  usersAcademicGroups: "/users/academic/:id/group",
  teacherAttendance: "/users/academic/:id/:groupId/attendances",
  studentDuplications: "/student-duplications",

  assistants: "/assistants",
  assistantSingle: "/assistants/:id",
  assistantAttendance: "/assistants/:id/:groupId/attendances",

  courses: "courses",
  coursesCategories: "/courses/categories",
  coursesSubCategories: "/courses/sub-categories",
  coursesSubCategoriesView: "/courses/sub-category/:id/list",
  archivedCoursesSubCategoriesView: "/courses/sub-category/archived/:id/list",
  coursesList: "/courses/list",
  coursesListAdd: "/courses/list/add",
  coursesListEdit: "/courses/list/:id/edit",
  coursesVideos: "/courses/videos",
  coursesCategorySubCategories: "/courses/category/:id/sub-categories",
  coursesSubCategoriesCreate: "/courses/sub-categories/create",
  coursesSubCategoriesEdit: "/courses/sub-category/:id/edit",
  faceId: "/faceId",
  faceIdScreens: "/face-id/screens",

  faceIdDevices: "/face-id/devices",

  market: "/market",
  marketTransactions: "/market/transactions",
  marketCategories: "/market/categories",
  marketAccessories: "/market/accessories",
  marketAccessoriesAdd: "/market/accessories/add",
  marketAccessoriesEdit: "/market/accessories/:id/edit",
  marketAccessoriesRestore: "/market/accessories/:id/restore",
  marketAccessory: "/market/accessories/:id",

  users: "users",
  usersStaff: "/users/staff",
  usersSingleStaff: "/users/staff/:id",
  studentsAssistants: "/users/students/assistants",
  studentsTeachers: "/users/students/staffs",
  studentsAuthors: "/users/students/authors",
  authors: "/users/authors",
  authorAdd: "/users/authors/add",
  authorEdit: "/users/authors/:authorId/edit",

  studyDepartment: "/study-department",
  studyDepartmentHomeworks: "/study-department/homeworks",
  studyDepartmentTeacherStatistics: "/study-department/teacher-statistics",
  studyDepartmentHome: "/study-department/homeworks/:id",
  studyDepartmentSingleStudent: "/study-department/homeworks/:id/student/:studentId/start/:startDateId/end/:endDate",
  studyDepartmentHomePage: "/study-department/homeworks/:id/lesson/:lessonId/theme/:homeworkId",
  studyDepartmentHomePageHomeworkSingle:
    "/study-department/homeworks/:id/lesson/:lessonId/theme/:homeworkId/chat/:chatId/home/:senderId",
  studyDepartmentCourses: "/study-department/courses",
  studyDepartmentCoursesSingle: "/study-department/courses/:id",
  studyDepartmentCoursesSingleDragDrop: "/study-department/courses/:id/drag-drop",
  studyDepartmentCoursesSingleSwitchHomework: "/study-department/courses/:id/switch/:homeworkId",
  studyDepartmentHomeworkChat:
    "/study-department/group/:groupId/user/:userId/homeworkChat/:homeworkId/lesson/:lessonId/student/:studentId",
  studyDepartmentTeachersKPI: "/study-department/teachers-kpi",
  studyDepartmentSource: "/study-department/source",
  studyDepartmentHomeworkDataBase: "/study-department/source/homework",
  studyDepartmentHomeworkDataBaseDraft: "/study-department/source/draft",
  studyDepartmentHomeworkDataBaseDraftLessons: "/study-department/source/draft/:id/lessons",
  studyDepartmentHomeworkDataBaseDraftLessonsHomework: "/study-department/source/draft/:id/lessons/:lessonId/homework",
  studyDepartmentHomeworkDataBaseChapters: "/study-department/source/homework/:courseId/chapters",
  studyDepartmentHomeworkDataBaseChaptersHomework:
    "/study-department/source/homework/:courseId/chapters/:chapterId/homework",
  studyDepartmentHomeworkDataBaseChaptersHomeworkAdd:
    "/study-department/source/homework/:courseId/chapters/:chapterId/homework/add",
  studyDepartmentHomeworkDataBaseChaptersHomeworkEdit:
    "/study-department/source/homework/:courseId/chapters/:chapterId/homework/:homeworkId/edit",
  studyDepartmentSkillClasses: "/study-department/skill-classes",

  teacherEdit: "/users/teacher/:id/edit",
  teacherAdd: "/users/teacher/add",

  reports: "/reports",
  reportSingle: "/reports/:id",

  publicResult: "/exam-result/:examId",

  finance: "/finance",
  revenueAndPayments: "/finance/revenue-and-payments",

  settings: "/settings",
  settingsPublicOffering: "/settings/public-offering",
  settingsPublicOfferingUpdate: "/settings/public-offering/update",
  settingsStudentStatuses: "/settings/students",
  settingsStudentStatusesEdit: "/settings/students/:id/edit",
  settingsMobileApp: "/settings/mobile-app",
  settingsFinance: "/settings/finance",

  notifications: "/notifications",
  notificationsSended: "/notifications/sended",
  notificationsSingle: "/notifications/more/:id",
  notificationsPublicStaffAndStudent: "/notifications/public-staff-and-student/create",
  templates: "/notifications/templates",
  templateEdit: "/notifications/templates/:id/edit",
  templateCreate: "/notifications/templates/create",
  notificationPublicStudentsCreate: "/notifications/public-students/create",
  notificationStudentPrivate: "/notifications/student-private/create",

  myNotification: "/my-notification",
  myNotificationSingle: "/my-notification/:id",

  competitions: "/events",
  addCompetitions: "/events/add",
  editCompetitions: "/events/:id/edit",
  singleCompetition: "/events/:id",
  masterClass: "/master-class",
  singleMasterClass: "/master-class/:id",
  masterClassParticipants: "/master-class/:id/participants",

  special: "/special",
  specialOutstandingPaymants: "/special/outstanding-paymants",
  specialBalancesPeriod: "/special/balances-period",
  specialPaidTime: "/special/paid-time",

  analytics: "/analytics",
  analyticsTeachersAvailability: "/analytics/teachers-availability",
  analyticsAudienceAvailability: "/analytics/audience-availability",

  profile: "/profile",
} as const

/*
 * T extend {name, age}
 * {name, age, asd, asdasd,,}
 * */
