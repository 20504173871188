import { NotificationArgsProps } from "antd"
import { MessageArgsProps } from "antd/lib"
import { AxiosError } from "axios"

export enum NotificationMessageType {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
}

export type NotificationParams = Omit<NotificationArgsProps, "message"> & {
  message: unknown | Error | AxiosError | MessageArgsProps["content"]
  messageType?: NotificationMessageType
}
