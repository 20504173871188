import { useMemo } from "react"
import { observer } from "mobx-react"
import { Flex, Tabs } from "antd"
import mainDictionary from "@/dictionary"
import { GroupPageState } from "../types"
import { GroupTop } from "./GroupTop"
import {
  ActionsHistory,
  Attendance,
  Exam,
  GroupInfo,
  Homework,
  Info,
  Journal,
  Lessons,
  Students,
  TeacherAttendance,
  Videos,
} from "./Tabs"

export const GroupSingle = observer(() => {
  const groupTabs = useMemo(
    () => [
      {
        label: mainDictionary.infos,
        key: GroupPageState.GroupInfo,
        children: <GroupInfo />,
      },
      {
        label: mainDictionary.students,
        key: GroupPageState.Students,
        children: <Students />,
      },
      {
        label: mainDictionary.lessons,
        key: GroupPageState.GroupLessons,
        children: <Lessons />,
      },
      {
        label: mainDictionary.actionsArchive,
        key: GroupPageState.ActionsArchive,
        children: <ActionsHistory />,
      },
      {
        label: mainDictionary.attendance,
        key: GroupPageState.Attendance,
        children: <Attendance />,
      },
      {
        label: mainDictionary.videos,
        key: GroupPageState.Videos,
        children: <Videos />,
      },
      {
        label: mainDictionary.iHomework,
        key: GroupPageState.Homework,
        children: <Homework />,
      },
      {
        label: mainDictionary.exams,
        key: GroupPageState.Exam,
        children: <Exam />,
      },
      {
        label: mainDictionary.groupInfo,
        key: GroupPageState.Info,
        children: <Info />,
      },
      {
        label: mainDictionary.journal,
        key: GroupPageState.Journal,
        children: <Journal />,
      },
      {
        label: mainDictionary.teacherAttendance,
        key: GroupPageState.TeacherAttendance,
        children: <TeacherAttendance />,
      },
    ],
    [],
  )

  return (
    <Flex vertical gap={36}>
      <GroupTop />

      <Tabs items={groupTabs} defaultActiveKey={GroupPageState.GroupInfo} />
    </Flex>
  )
})
