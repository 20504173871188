import { observer } from "mobx-react"

export const Homework = observer(() => {
  console.log("")

  return (
    <>
      <>Homework</>
    </>
  )
})
