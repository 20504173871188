import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const BalancesPeriod = lazy(() =>
  import("./BalancesPeriod").then(({ BalancesPeriod }) => ({ default: BalancesPeriod })).catch(handleCatchChunkError),
)

export const OutstandingPaymants = lazy(() =>
  import("./OutstandingPayments")
    .then(({ OutstandingPaymants }) => ({ default: OutstandingPaymants }))
    .catch(handleCatchChunkError),
)

export const PaidTime = lazy(() =>
  import("./PaidTime").then(({ PaidTime }) => ({ default: PaidTime })).catch(handleCatchChunkError),
)
