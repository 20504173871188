import { ValueOf } from "@/types/types"
import { permissionObjectTypes } from "./constants"

export enum PermissionOptions {
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
}

export type ValueOfPermissionObjectTypes = ValueOf<typeof permissionObjectTypes>

export type Permission = {
  children?: Permission[]
  displayName: string
  name: ValueOfPermissionObjectTypes
  permissions: PermissionOptions[]
}

export type StructuredPermissions = Partial<Record<ValueOfPermissionObjectTypes, Omit<Permission, "name" | "children">>>
