export const downloader = (data: Uint8Array, name?: string) => {
  const blob = new Blob([data])
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")

  link.href = url
  link.download = `${name}.xlsx`

  link.click()

  URL.revokeObjectURL(url)
}

export function downloadCSV(bufferData: ArrayBuffer) {
  const uint8Array = new Uint8Array(bufferData)

  const blob = new Blob([uint8Array], { type: "text/csv" })

  const url = URL.createObjectURL(blob)

  const link = document.createElement("a")

  link.href = url
  link.download = "studentsData.csv"
  document.body.appendChild(link)
  link.click()

  URL.revokeObjectURL(url)
  document.body.removeChild(link)
}
