import { PaginationProps, Table as AntTable, TableProps } from "antd"
import { ColumnsType } from "antd/lib/table"
import { PER_PAGES } from "@/constants/utils"
import { getPerPages } from "@/shared"
import { storage } from "@/utils/storage"
import { StoreTitle } from "./types"

type Props<T> = TableProps<T> & {
  storeTitle: StoreTitle
  onStoreChange: (page: number, perPage: number) => void
  columns?: ColumnsType<T>
}

export const Table = <T extends Record<string, unknown>>(props: Props<T>) => {
  const { pagination, onStoreChange, columns, storeTitle, ...restProps } = props
  const perPageFromLocalStorage = getPerPages()

  const handleShowSizeChange = (currentPage: number, size: number) => {
    onStoreChange?.(currentPage, size)
    storage.setObject(PER_PAGES, { ...perPageFromLocalStorage, [storeTitle]: size })
  }

  return (
    <AntTable<T>
      {...restProps}
      columns={columns}
      pagination={
        pagination && {
          ...pagination,

          onShowSizeChange: handleShowSizeChange,
        }
      }
    />
  )
}
