import { Endpoints } from "@/api/endpoints"
import { INetworkConfig, Instance } from "@/api/instance"
import { dictionaryWithKeys } from "@/utils"
import { IGetAttendanceRequest } from "../teachers/types"
import { IResponse, ISuccessResponse } from "../types"
import { AssistantsEndpoint } from "./endpoints"
import {
  IAssistantProfileRequest,
  IAssistantRequest,
  IGetAssistantAttendanceResponse,
  IGetAssistantGroupResponse,
  IGetAssistantRequest,
  IGetAssistantResponse,
  IResetPasswordResponse,
} from "./types"

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}${AssistantsEndpoint.Assistant}`,
}

class AssistantsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getAssistants = (params: IGetAssistantRequest): Promise<IResponse<IGetAssistantResponse>> =>
    this.get(AssistantsEndpoint.All, { params })

  getAssistantGroups = (params: number): Promise<IResponse<IGetAssistantGroupResponse>> =>
    this.get(
      dictionaryWithKeys(AssistantsEndpoint.AssistantGroups, {
        id: params,
      }),
    )

  getAssistantAttendances = (params: IGetAttendanceRequest): Promise<IResponse<IGetAssistantAttendanceResponse>> =>
    this.get(
      dictionaryWithKeys(AssistantsEndpoint.AssistantAttendances, {
        id: params.id,
        groupId: params.groupId,
      }),
      { params: { page: params.page, perPage: params.perPage } },
    )

  resetPassword = (params: IAssistantRequest): Promise<IResponse<IResetPasswordResponse>> =>
    this.put(
      dictionaryWithKeys(AssistantsEndpoint.ResetPassword, {
        id: params.id,
      }),
    )

  updateAssistant = (params: IAssistantRequest): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${params.id}`, params)

  generateAssistantProfile = (params: IAssistantProfileRequest): Promise<IResponse<IResetPasswordResponse>> =>
    this.post(`${AssistantsEndpoint.SignUp}/${params.id}`, params)
}

export const assistantsApi = new AssistantsApi(config)
