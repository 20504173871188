import { VideoStatus } from "@/modules/kinescope/constants"

export const kinescopeDictionary = {
  invalidVideoNotificationMessage: "Videofayl formati to'g'ri kelmadi",
  getNotUploadUrlFromKinescope: (filename: string) => `Kineskopdan javob yo'q. Fayl: "${filename}"`,
  uploadText: "Videofaylni yuklash uchun ushbu hudud ustiga bosing yoki faylni shu yerga olib keling",
  uploadHint: "Videofayl .mp4, .webm, .mpeg, .avi, .mkv, .m4v, .ogm, .mov, . mpg formatlaridan birida bo'lishi kerak",
  requiredLesson: "Darsni tanlashingiz shart!",
  selectLesson: "Darsni tanlang",
  requiredLessonName: "Video nomini tanlash shart!",
  maxLessonNameCharacter: "Videofayil nomi 500 belgidan kam bo'lishi kerak.",
  videoName: "Video nomi",
  lesson: "Dars",
  addFiles: "Fayllarni yuklash",
  notClose: "Fayl yuklanish jarayonida. Operatsiyani bajarishga aminmisiz?",
  waitingMessage: "Konvertatsiya qilinishni kutyapti",
}

export const statusHistoryMessage: Record<VideoStatus, string> = {
  [VideoStatus.Done]: "Fayl yuklandi",
  [VideoStatus.Error]: "Fayln to’liq yuklanmadi",
  [VideoStatus.Pause]: "Fayl kutyapti",
  [VideoStatus.Processing]: "Kinescopeda konvertatsiya qilinmoqda",
  [VideoStatus.Uploading]: "Fayl yuklanish jarayonida",
  [VideoStatus.Waiting]: "Konvertatsiya qilinishni kutyapti",
}
