import { ReactNode } from "react"
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, LinkOutlined } from "@ant-design/icons"
import { Button, Flex, Tooltip } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"

import styles from "./table-actions.module.scss"

const cn = classNames.bind(styles)

type CustomAction = {
  tooltipString: string
  icon: ReactNode
  handleClick: VoidFunction
}

type Props = {
  hasCustomAction?: boolean
  actions?: CustomAction[]
  handleClick?: VoidFunction
  isVertical?: boolean
  isActionColumn?: boolean
}

export const TableActions = ({
  hasCustomAction = false,
  actions,
  handleClick,
  isVertical = false,
  isActionColumn = false,
}: Props) => {
  const defaultActions = [
    {
      tooltipString: mainDictionary.more,
      icon: <InfoCircleOutlined />,
    },
    {
      tooltipString: mainDictionary.more,
      icon: <LinkOutlined />,
    },
    {
      tooltipString: mainDictionary.more,
      icon: <EditOutlined />,
    },
    {
      tooltipString: mainDictionary.delete,
      icon: <DeleteOutlined />,
    },
  ]

  const actionList = hasCustomAction ? actions : defaultActions
  const content = (
    <Flex vertical={isVertical} gap={12}>
      {actionList?.map((action, idx) => (
        <Tooltip key={idx} title={action.tooltipString}>
          <Button
            size="small"
            onClick={handleClick}
            danger={action.tooltipString === mainDictionary.delete}
            icon={action.icon}
          >
            {isVertical ? action.tooltipString : null}
          </Button>
        </Tooltip>
      ))}
    </Flex>
  )

  return <div className={cn(!isActionColumn && "table-actions")}>{content}</div>
}
