import {
  AddMasterClassReq,
  ChangeStatus,
  EditMasterClassReq,
  GetMasterClassParams,
  GetMasterClassParticipantsReq,
  RewardStudents,
} from "@/stores/events"
import { Endpoints } from "../endpoints"
import { INetworkConfig, Instance } from "../instance"
import { IResponse } from "../types"
import { EventsEndpoints } from "./endpoints"
import {
  GetCompetitionRes,
  GetEventTeamsRes,
  GetMasterClassesRes,
  GetMasterClassParticipantsRes,
  ModifiedMasterClass,
} from "./types"

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}${EventsEndpoints.Events}`,
}

class EventsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getMasterClasses = (params: GetMasterClassParams): Promise<IResponse<GetMasterClassesRes>> =>
    this.get(EventsEndpoints.MasterClasses, { params })

  getCompetitions = (params: GetMasterClassParams): Promise<IResponse<GetCompetitionRes>> =>
    this.get(EventsEndpoints.Competitions, { params })

  getMasterClass = (params: number): Promise<IResponse<ModifiedMasterClass>> =>
    this.get(`${EventsEndpoints.MasterClass}/${params}`)

  getCompetition = (params: number): Promise<IResponse<ModifiedMasterClass>> =>
    this.get(`${EventsEndpoints.Competition}/${params}`)

  addMasterClass = (params: AddMasterClassReq): Promise<IResponse<GetMasterClassesRes>> =>
    this.post(EventsEndpoints.MasterClasses, params)

  addCompetition = (params: AddMasterClassReq): Promise<IResponse<GetMasterClassesRes>> =>
    this.post(EventsEndpoints.Competition, params)

  editMasterClass = (params: EditMasterClassReq): Promise<IResponse<GetMasterClassesRes>> =>
    this.put(`${EventsEndpoints.MasterClasses}/${params.id}`, params.data)

  editCompetition = (params: EditMasterClassReq): Promise<IResponse<GetMasterClassesRes>> =>
    this.put(`${EventsEndpoints.MasterClasses}/${params.id}`, params.data)

  finishMasterClass = (id: number): Promise<IResponse<GetMasterClassesRes>> =>
    this.put(`${EventsEndpoints.MasterClasses}/${id}${EventsEndpoints.Finish}`)

  finishCompetition = (id: number): Promise<IResponse<GetMasterClassesRes>> =>
    this.put(`${EventsEndpoints.Competitions}/${id}${EventsEndpoints.Finish}`)

  getMasterClassParticipants = (
    params: GetMasterClassParticipantsReq,
  ): Promise<IResponse<GetMasterClassParticipantsRes>> => this.get(EventsEndpoints.MasterClassParticipants, { params })

  changeParticipantStatus = (params: ChangeStatus): Promise<IResponse<GetMasterClassesRes>> =>
    this.put(`${params.id}${EventsEndpoints.ParticipantStatus}`, params.data)

  deleteCompetition = (id: number): Promise<IResponse<GetMasterClassesRes>> =>
    this.delete(`${EventsEndpoints.Competitions}/${id}`)

  deleteMasterclass = (id: number): Promise<IResponse<GetMasterClassesRes>> =>
    this.delete(`${EventsEndpoints.MasterClasses}/${id}`)

  getTeams = (id: number, awarded?: boolean): Promise<IResponse<GetEventTeamsRes>> =>
    this.get(`/${id}${EventsEndpoints.Teams}`, { params: { awarded } })

  rewardStudents = (params: RewardStudents): Promise<IResponse<AddMasterClassReq>> =>
    this.put(`/${params.id}${EventsEndpoints.RewardStudents}`, { participantsData: params.data })
}

export const eventsApi = new EventsApi(config)
