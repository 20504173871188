import { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { DatePicker, Flex, Form, Modal, Select, Typography } from "antd"
import classNames from "classnames/bind"
import dayjs from "dayjs"
import { useBoolean } from "usehooks-ts"
import { IStudents } from "@/api/students"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { ControlActionTypes } from "@/pages/Users/Students/types"
import { useStores } from "@/stores"
import { formatDate, getFullName, useCheckMyGroup } from "@/utils"

import styles from "./change-status.module.scss"

const cx = classNames.bind(styles)

type FormValues = {
  status: number
  startDate?: string
}

type StatusOption = {
  label: string
  value: number
  name: string
}

export const ChangeStatus = observer(() => {
  const { groupsStore, settingsStore, studentStore } = useStores()
  const [form] = Form.useForm()
  const isMyGroup = useCheckMyGroup()
  const { studentId, id: groupId } = useParams()
  const { value: isLoading, setFalse: setFalseLoading, setTrue: setTrueIsLoading } = useBoolean(false)
  const { value: isDisable, setFalse: setIsDisableFalse, setTrue: setIsDisableTrue } = useBoolean(false)
  const { value: showDatePicker, setFalse: setHideDatePicker, setTrue: setShowDatePicker } = useBoolean(false)
  const fullName = groupsStore.changeStudentSelect ? getFullName<IStudents>(groupsStore.changeStudentSelect) : ""

  const handleClose = () => {
    groupsStore.setChangeStatusModal(false)
    groupsStore.setChangeStudentSelect(null)
    groupsStore.setSelectedGroup(null)
  }

  const id = studentId ? studentId : groupId

  const disabledDate = (current: dayjs.Dayjs) => {
    if (studentStore?.singleStudent?.id) {
      const groupEndDate = groupsStore.selectedGroup?.endedDate
      const groupStartDate = groupsStore.selectedGroup?.startedDate

      return current.isAfter(groupEndDate) || current.isBefore(groupStartDate)
    }

    const groupEndDate = (isMyGroup ? groupsStore?.groupSingle : groupsStore?.singleGroup)?.endedDate
    const groupStartDate = (isMyGroup ? groupsStore?.groupSingle : groupsStore?.singleGroup)?.startedDate

    return current.isAfter(groupEndDate) || current.isBefore(groupStartDate)
  }

  const handleChange = (value: number, option: any) => {
    if (option.name === ControlActionTypes.NOT_CALCULATE_STATISTIC && value !== groupsStore.selectedGroup?.actionId) {
      setShowDatePicker()
      setIsDisableFalse()

      return
    }

    setHideDatePicker()

    if (value === groupsStore.selectedGroup?.actionId) {
      setIsDisableTrue()

      return
    }

    setIsDisableFalse()
  }

  const options: StatusOption[] | undefined = useMemo(
    () =>
      settingsStore?.studentStatuses?.controlActions?.map((item) => ({
        label: item.displayName,
        value: item.id,
        name: item.name,
      })),
    [settingsStore.studentStatuses],
  )

  const handleFinish = async (values: FormValues) => {
    if (!groupsStore.selectedGroup?.studentGroupId || !id) return

    const info = {
      studentGroupId: groupsStore.selectedGroup?.studentGroupId,
      controlActionId: values.status,
      startDate: values?.startDate ? formatDate(values?.startDate, true) : undefined,
    }

    setTrueIsLoading()

    try {
      await settingsStore.changeStudentStatus(info)
      handleClose()
      addSuccessNotification(mainDictionary.successEdit)

      if (studentId) {
        studentStore.getSingleStudent({ id: parseInt(id, 10) })

        return
      }

      if (!groupId) return

      groupsStore.getGroupDetailForAdmin(parseInt(groupId, 10))
    } catch (err: Error | unknown) {
      addCatchNotification(err)
    } finally {
      setFalseLoading()
    }
  }

  useEffect(() => {
    settingsStore.getStudentStatuses()
    form.setFieldValue("status", groupsStore.selectedGroup?.actionId)
    form.setFieldValue("startDate", dayjs())

    if (groupsStore.selectedGroup?.actionId) {
      setIsDisableTrue()
    }
  }, [])

  return (
    <Modal
      okText={mainDictionary.save}
      cancelText={mainDictionary.cancel}
      onCancel={handleClose}
      open={groupsStore.changeStatusModal}
      title={fullName}
      onOk={form.submit}
      okButtonProps={{
        loading: isLoading,
        disabled: isDisable,
      }}
    >
      <Flex vertical gap={15}>
        <Flex gap={5} align="flex-start">
          <Typography.Text>{mainDictionary?.group}:</Typography.Text>
          <Typography.Text>{groupsStore?.selectedGroup?.groupName}</Typography.Text>
        </Flex>

        <Form onFinish={handleFinish} form={form} layout="vertical">
          <Form.Item rules={[{ required: true }]} name="status" label={mainDictionary?.studentStatus}>
            <Select onChange={handleChange} options={options} />
          </Form.Item>

          {showDatePicker && (
            <Form.Item label={mainDictionary.willStartDate} name="startDate" rules={[{ required: true }]}>
              <DatePicker disabledDate={disabledDate} className={cx("date-picker")} />
            </Form.Item>
          )}
        </Form>
      </Flex>
    </Modal>
  )
})
