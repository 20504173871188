import { makeAutoObservable } from "mobx"
import { teachersApi } from "@/api"
import { IGetAttendanceRequest } from "@/api/teachers"
import { addCatchNotification } from "@/modules/notifications"
import { IAttendance, ITeacherAssistant, ITeacherGroup } from "./types"

class TeachersStore {
  teacherGroups: ITeacherGroup[] = []
  attendances: IAttendance[] = []
  assistants: ITeacherAssistant[] = []
  isModulesModalOpen = false
  isStaffsModalOpen = false
  searchValue = ""
  groupName = ""
  searchInputValue = ""
  selectedStaffId = 0
  total = 0
  rating = 0
  perPage = 10
  page = 1

  constructor() {
    makeAutoObservable(this)
  }

  getTeacherGroups = (params: number) => {
    teachersApi.getTeacherGroups(params).then((res) => {
      this.setTeacherGroups(res.data.staffGroups)

      return res
    })
  }

  getTeacherAssistants = (id: number) => {
    const teacher = this.teacherGroups.find((teacher) => teacher.id === id)

    if (teacher) {
      this.setAssistants(teacher.staff.assistants)
    }
  }

  getTeacherAttendances = (params: IGetAttendanceRequest) =>
    teachersApi
      .getTeacherAttendances(params)
      .then((res) => {
        this.setTotal(res.data.total)
        this.setGroupName(res.data?.teacherAttendancesData?.group?.name)
        this.setRating(res.data?.teacherAttendancesData?.rating)
        this.setStaffAttendances(res.data?.teacherAttendancesData?.attendances)

        return res
      })
      .catch(addCatchNotification)

  setIsModulesModalOpen = (isModalOpen: boolean) => {
    this.isModulesModalOpen = isModalOpen
  }

  setIsStaffsModalOpen = (isModalOpen: boolean) => {
    this.isStaffsModalOpen = isModalOpen
  }

  setSelectedStaffId = (id: number) => {
    this.selectedStaffId = id
  }

  setSearchValue = (value: string) => {
    this.searchValue = value
  }

  setSearchInputValue = (value: string) => {
    this.searchInputValue = value
  }

  setTotal = (total: number) => {
    this.total = total
  }

  setRating = (rating: number) => {
    this.rating = rating
  }

  setGroupName = (name: string) => {
    this.groupName = name
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setTeacherGroups = (teacherGroups: ITeacherGroup[]) => {
    this.teacherGroups = teacherGroups
  }

  setStaffAttendances = (attendances: IAttendance[]) => {
    this.attendances = attendances
  }

  setAssistants = (assistants: ITeacherAssistant[]) => {
    this.assistants = assistants
  }

  reset = () => {
    this.teacherGroups = []
    this.isModulesModalOpen = false
    this.isStaffsModalOpen = false
    this.searchValue = ""
    this.searchInputValue = ""
    this.perPage = 10
    this.page = 1
    this.rating = 0
    this.total = 0
    this.groupName = ""
    this.attendances = []
    this.assistants = []
    this.selectedStaffId = 0
  }
}

export const teachersStore = new TeachersStore()
