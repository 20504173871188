import { handleCatchChunkError } from "@/utils"
import { lazy } from "react"

export const AudienceAvailability = lazy(() =>
  import("./AudienceAvailability")
    .then(({ AudienceAvailability }) => ({ default: AudienceAvailability }))
    .catch(handleCatchChunkError),
)

export const TeacherAvailability = lazy(() =>
  import("./TeacherAvailability")
    .then(({ TeacherAvailability }) => ({ default: TeacherAvailability }))
    .catch(handleCatchChunkError),
)
