import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { Button, Flex, Tooltip, Typography } from "antd"
import classNames from "classnames/bind"
import { GroupStatus, IGroup } from "@/api/gamification"
import { StoreTitle, Table as AntTable } from "@/components/Table"
import { groupStatus, ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from "@/modules/permission"
import { groupStatusColor } from "@/pages/Gamification/constants"
import { getPaginationParams } from "@/pages/utils"
import { getPerPages } from "@/shared"
import { useStores } from "@/stores"
import { getRowIndex } from "@/utils"
import { formatDate } from "@/utils/date"

import styles from "./table.module.scss"

const cn = classNames.bind(styles)

export const ByGroupTable = observer(() => {
  const { gamificationStore } = useStores()
  const navigate = useNavigate()
  const perPageFromLocalStorage = getPerPages()
  const [isAvailableEdit] = useCheckPermission({
    module: permissionObjectTypes.gamificationGroups,
    option: PermissionOptions.Update,
  })

  const handleOpenModal = (id: number) => {
    gamificationStore.getGroupActionDetail(id)
    gamificationStore.setByGroupInfoModal(true)
  }

  const handleClickEdit = (id: number) => {
    const route = ROUTES.gamificationConnectGroupSingle.replace(":id", String(id))

    navigate(route)
  }

  const columns = useMemo(
    () => [
      {
        title: mainDictionary.order,
        render: (item: IGroup, _: any, index: number) => (
          <span>
            {getRowIndex(
              gamificationStore.groupActionFilter?.page! || 1,
              gamificationStore.groupActionFilter?.perPage! || 10,
              index,
            )}
          </span>
        ),
      },
      {
        title: mainDictionary.groupName,
        render: (item: IGroup) => <span>{item.name}</span>,
      },
      {
        title: mainDictionary.status,
        render: (item: IGroup) => (
          // @ts-ignore
          <Typography.Text type={groupStatusColor[item.status] || "secondary"}>
            {groupStatus[item.status]}
          </Typography.Text>
        ),
      },
      {
        title: mainDictionary.category,
        render: (item: IGroup) => <span>{item.category?.displayName}</span>,
      },
      {
        title: mainDictionary.startDate,
        render: (item: IGroup) => <span>{formatDate(item.startedDate) || "-"}</span>,
      },
      {
        title: mainDictionary.willEndDate,
        render: (item: IGroup) => <span>{formatDate(item.endedDate) || "-"}</span>,
      },
      {
        title: mainDictionary.actionsCount,
        align: "center",
        render: (item: IGroup) => <span>{item.actionsCount}</span>,
      },
      ...(isAvailableEdit
        ? [
            {
              render: (item: IGroup) => (
                <Flex gap={10}>
                  <Button
                    type="text"
                    icon={<InfoCircleOutlined className={cn("table__icon")} />}
                    onClick={handleOpenModal.bind(null, item.id)}
                  />
                  <Tooltip title={mainDictionary.edit}>
                    <Button icon={<EditOutlined />} onClick={handleClickEdit.bind(null, item.id)} />
                  </Tooltip>
                </Flex>
              ),
            },
          ]
        : []),
    ],
    [gamificationStore.groupActionsByGroups?.groups],
  )

  const handlePageChange = (page: number, perPage: number) => {
    const info = {
      status: GroupStatus.ACTIVE,
      ...gamificationStore.groupActionFilter,
      page,
      perPage,
    }

    gamificationStore.setGroupActionFilter(info)

    gamificationStore.getGroupActions(info)
  }

  const handleSizeChange = (page: number, perPage: number) => {
    const info = {
      ...gamificationStore.groupActionFilter,
      page,
      perPage,
    }

    gamificationStore.setGroupActionFilter(info)
  }

  useEffect(() => {
    gamificationStore.setGroupActionByGroupTableLoading(true)
    gamificationStore.setSelectedActionIds([])
    gamificationStore.setGroupActionFilter({
      ...gamificationStore.groupActionFilter,
      perPage: perPageFromLocalStorage?.groupActionPage,
    })
  }, [])

  return (
    <AntTable
      // @ts-ignore
      columns={columns}
      dataSource={gamificationStore.groupActionsByGroups?.groups || []}
      rowKey="id"
      className={cn("table")}
      storeTitle={StoreTitle.GroupActionPage}
      onStoreChange={handleSizeChange}
      loading={gamificationStore.groupActionByGroupTableLoading}
      pagination={{
        ...getPaginationParams({
          total: gamificationStore.groupActionsByGroups?.total,
          notShowSizeChanger: false,
          onChange: handlePageChange,
          current: gamificationStore?.groupActionFilter?.page,
          pageSize: gamificationStore.groupActionFilter?.perPage || 10,
        }),
      }}
    />
  )
})
