import { GetCallHistory, IGetReportsTypes } from "@/stores/reports"
import { Endpoints } from "../endpoints"
import { INetworkConfig, Instance } from "../instance"
import { IResponse } from "../types"
import { ReportsEndpoints } from "./endpoints"
import { CallHistoryRes, IGetReportResponse } from "./types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class ReportsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getReports = (params: IGetReportsTypes): Promise<IResponse<IGetReportResponse>> =>
    this.get(`${ReportsEndpoints.Reports}`, { params })

  getCallHistory = (params: GetCallHistory): Promise<IResponse<CallHistoryRes>> =>
    this.get(`${ReportsEndpoints.callHistoriesAll}`, { params })
}

export const reportsApi = new ReportsApi(config)
