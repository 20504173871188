import { NotificationTypes } from "@/stores/notifications"

export enum RoleTypes {
  STAFF = 1,
  ACADEMIC = 2,
}

export const enum NotificationBodyTypes {
  JSON = 1,
  HTML,
}

export enum NotificationActionTypes {
  SENT_BY_ADMIN = 1,
  EXAM_STUDENT,
  ANNOUNCE_EXAM_STUDENT,
  DEADLINE_EXAM_STUDENT,
  HOMEWORK_STUDENT,
  CHECK_HOMEWORK_STUDENT,
  DEADLINE_HOMEWORK_STUDENT,
  ADD_GROUP_STUDENT,
  STOP_GROUP_STUDENT,
  AWARD_STUDENT,
  ATTENDANCE_STAFF,
  HOMEWORK_STAFF,
  CHECK_HOMEWORK_STAFF,
  EXAM_STAFF,
  CREATE_EXAM_STAFF,
  EXAM_HOMEWORK_STAFF,
  CHECK_EXAM_STAFF,
  ADD_GROUP_STAFF,
}

export type Notification = {
  id: number
  title: string
  firstName: string
  lastName: string
  roleType: RoleTypes
  isRead: boolean
  createdAt: string
  offices?: string[]
  courses?: string[]
  categories?: string[]
  groups?: string[]
  roles?: string[]
  viewCount: number
  receiversCount: number
  notificationType: NotificationTypes
  body?: string
  bodyType: NotificationBodyTypes
  banner?: string
  notificationActionType: NotificationActionTypes
}

export type NotificationRes = {
  total: number
  notifications: Notification[]
}

export type Template = {
  id: number
  body: string
  title: string
  banner: string
  name: string
  createdAt: string
}

export type TemplateRes = {
  total: number
  templates: Template[]
}

export type GetAllMyNotificationRes = {
  notifications: Notification[]
  total: number
}
