import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import { EditOutlined } from "@ant-design/icons"
import { Button, Tooltip } from "antd"
import classNames from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import { IAction } from "@/api/gamification"
import { StoreTitle, Table as AntTable } from "@/components/Table"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from "@/modules/permission"
import { getPaginationParams } from "@/pages/utils"
import { getPerPages } from "@/shared"
import { useStores } from "@/stores"

import styles from "./by-action.module.scss"

const cn = classNames.bind(styles)

export const ByAction = observer(() => {
  const navigate = useNavigate()
  const { gamificationStore } = useStores()
  const perPageFromLocalStorage = getPerPages()
  const [isAvailableEdit] = useCheckPermission({
    module: permissionObjectTypes.gamificationGroups,
    option: PermissionOptions.Update,
  })
  const [page, setPage] = useState<number>()
  const [perPage, setPerPage] = useState<number>()
  const { value: loading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean(true)

  const handleClick = (id: number) => {
    navigate(ROUTES.gamificationConnectActionSingle.replace(":id", String(id)))
  }

  const columns = useMemo(
    () => [
      {
        title: mainDictionary.actionName,
        render: (item: IAction) => <span>{item.name}</span>,
      },
      {
        title: mainDictionary.groupsCount,
        align: "center",
        render: (item: IAction) => <span>{item.groupsCount}</span>,
      },
      ...(isAvailableEdit
        ? [
            {
              align: "right",
              title: mainDictionary.settings,
              render: (item: IAction) => (
                <Tooltip title={mainDictionary.edit}>
                  <Button icon={<EditOutlined />} onClick={handleClick.bind(null, item.id)} />
                </Tooltip>
              ),
            },
          ]
        : []),
    ],
    [],
  )

  useEffect(() => {
    gamificationStore.setSelectedActionIds([])
    gamificationStore.getActions({ page: page!, perPage: perPage! }).then(setLoadingTrue).finally(setLoadingFalse)
  }, [page, perPage])

  const handleChange = (page: number, pageSize: number) => {
    setPage(page)
    setPerPage(pageSize)
  }

  useEffect(() => {
    setPerPage(perPageFromLocalStorage?.byActionPage)
  }, [])

  return (
    <AntTable
      // @ts-ignore
      columns={columns}
      dataSource={gamificationStore.deeds?.actions}
      rowKey="id"
      className={cn("by-action")}
      storeTitle={StoreTitle.ByActionPage}
      onStoreChange={handleChange}
      loading={loading}
      pagination={{
        ...getPaginationParams({
          total: gamificationStore.deeds?.total,
          notShowSizeChanger: false,
          onChange: handleChange,
          pageSize: perPage,
        }),
      }}
    />
  )
})
