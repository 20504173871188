import { makeAutoObservable } from "mobx"
import {
  GetCategoriesResponse,
  GetProductsRes,
  GetTransactionsRes,
  marketApi,
  MarketCategory,
  Product,
} from "@/api/market"
import { addCatchNotification } from "@/modules/notifications"
import {
  CategoryAddParams,
  CategoryEditParams,
  ChangeStatusParams,
  ChangeTransactionStatusParams,
  EditProductParams,
  GetCategoriesParams,
  GetProductsParams,
  GetTransactionsParams,
  MakeProductParams,
  ProductStatuses,
  TransactionStates,
} from "./types"

const defaultParams = {
  page: 1,
  perPage: 10,
}

class MarketStore {
  isOpenCategoryMakeModal = false
  isOpenReasonModal = false
  categories: GetCategoriesResponse | null = null
  categoriesLoader = true
  selectedCategory: MarketCategory | null = null
  categoryFilter: GetCategoriesParams = defaultParams

  products: GetProductsRes | null = null
  productsLoader = false
  productFilter: GetProductsParams | null = null
  imagePreviewModal = false
  imagePreviewUrl: string | null = null
  productsTab: ProductStatuses | string = ProductStatuses.OnSale
  transactions: GetTransactionsRes | null = null
  transactionsLoader = false
  transactionTab: TransactionStates | string = TransactionStates.Pending
  selectedTransactionId: number | null = null
  accessoryModal = false
  selectedProduct: Product | null = null
  transactionsFilter: GetTransactionsParams = {
    ...defaultParams,
    state: TransactionStates.Pending,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedProduct = (params: Product | null) => {
    this.selectedProduct = params
  }

  setAccessoryModal = (params: boolean) => {
    this.accessoryModal = params
  }

  setTransactionFilter = (params: GetTransactionsParams) => {
    this.transactionsFilter = params
  }

  setSelectedTransactionId = (params: number | null) => {
    this.selectedTransactionId = params
  }

  setTransactionTab = (params: TransactionStates | string) => {
    this.transactionTab = params
  }

  setTransactionsLoader = (params: boolean) => {
    this.transactionsLoader = params
  }

  setTransactions = (params: GetTransactionsRes | null) => {
    this.transactions = params
  }

  setProductTab = (params: string) => {
    this.productsTab = params
  }

  setImagePreviewUrl = (params: string | null) => {
    this.imagePreviewUrl = params
  }

  setImagePreviewModal = (params: boolean) => {
    this.imagePreviewModal = params
  }

  setProductFilter = (params: GetProductsParams | null) => {
    this.productFilter = params
  }

  setProductsLoader = (params: boolean) => {
    this.productsLoader = params
  }

  setProducts = (params: GetProductsRes | null) => {
    this.products = params
  }

  setSelectedCategory = (params: MarketCategory | null) => {
    this.selectedCategory = params
  }

  setCategoryFilter = (params: GetCategoriesParams) => {
    this.categoryFilter = params
  }

  setCategoriesLoader = (params: boolean) => {
    this.categoriesLoader = params
  }

  setCategories = (params: GetCategoriesResponse | null) => {
    this.categories = params
  }

  deleteCategory = (id: number) => marketApi.deleteCategory(id)

  editCategory = (params: CategoryEditParams) => marketApi.editCategory(params)

  addCategory = (params: CategoryAddParams) => marketApi.addCategory(params)

  editStatusOfTransaction = (params: ChangeTransactionStatusParams) => marketApi.editTransactionStatus(params)

  getProducts = (params: GetProductsParams) =>
    marketApi
      .getProducts(params)
      .then((res) => {
        if (res.success) {
          this.setProducts(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setProductsLoader(false))

  getTransactions = (params: GetTransactionsParams) =>
    marketApi
      .getTransactions(params)
      .then((res) => {
        if (res.success) {
          this.setTransactions(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setTransactionsLoader(false)
      })

  editProduct = (params: EditProductParams) => marketApi.editProduct(params)

  addProduct = (params: MakeProductParams) => marketApi.addProduct(params)

  changeStatus = (params: ChangeStatusParams) => marketApi.changeStatus(params)

  getCategories = (params: GetCategoriesParams) =>
    marketApi
      .getCategories(params)
      .then((res) => {
        if (res.success) {
          this.setCategories(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setCategoriesLoader(false)
      })

  setIsOpenCategoryMakeModal = (params: boolean) => {
    this.isOpenCategoryMakeModal = params
  }

  setIsReasonModal = (params: boolean) => {
    this.isOpenReasonModal = params
  }

  resetFilter = () => {
    this.categoryFilter = {
      page: 1,
      perPage: 10,
    }
  }
}

export const marketStore = new MarketStore()
