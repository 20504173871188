import { Attachment } from "@/modules/HomeworkChat/types"
import { HomeworkDbDifficultyLevels } from "@/stores/study-department/types"
import { HomeworkAssignmentTypes, Role } from "../groups/types"
import { SkillClassesTabsKeys, SkillClassesVideosStatus } from "@/pages/StudyDepartment/constants"
import { SkillClassesEditData } from "@/pages/StudyDepartment/types"

export enum GroupStatus {
  NotStarted = 1,
  ACTIVE = 2,
  ENDED = 3,
}

export interface IHomeworkStatisticGroups {
  [key: string]: any
  id: number
  name: string
  status: GroupStatus
  category: string
  course: string
  learningType: string
  startedDate: string
  endedDate: string
  createdAt: string
  office?: string
}

export interface IHomeworkStatisticGroupsRes {
  groups: IHomeworkStatisticGroups[]
  total: number
}

export interface GetHomeworkStatisticsRes {
  group: Group
  students: StudentHomeworkStatistic[]
  studyMonths: GroupStudyMonth[]
  total: number
}
export interface Group {
  id: number
  hGroupId: number
  hName: string
  hBranchId: number
  startedDate: Date
  status: GroupStatus
  endedDate: Date
  createdAt: Date
}
export interface StudentHomeworkStatistic {
  id: number
  firstName: string
  lastName: string
  averageScore: number
  acceptedHomeworkCount: number
  rejectedHomeworkCount: number
  uncompletedHomeworkCount: number
  totalHomeworkCount: number
  waitingHomeworkCount?: number
}

export enum HomeworkCheckingStatus {
  Checked = 1,
  Unchecked,
  NotGiven,
}
export interface GroupStudyMonth {
  month: number
  startDate: string
  endDate: string
}

export interface LessonCheckingStatistic {
  id: number
  name: string
  createdAt: Date
  status: HomeworkCheckingStatus
  homeworkId: number
  homeworkChatCreatedAt: string
}

export interface GetHomeworkStatisticByCheckingRes {
  group: Group
  lessons: LessonCheckingStatistic[]
  total: number
  uncheckedCount: number
  notGivenCount: number
  checkedCount: number
  studyMonths: GroupStudyMonth[]
}

export interface IOption {
  id: number
  name: string
}

export interface IFilterObjectsResponse {
  categories: IOption[]
  courses: IOption[]
  teachers: IOption[]
  learningTypes: IOption[]
}

export interface StaffGroupDetail {
  title: string
  staff: StaffOfGroup[]
}

export interface StaffOfGroup {
  id: number
  firstName: string
  lastName: string
  role: Role
  hasPermission?: boolean
}

export interface ICourse {
  id: number
  name: string
  disciplineName: string
  hasStudyMonths: boolean
  hasCurriculum: boolean
  createdAt: string
  curriculumCompleted?: boolean
  disciplineId: number
  imageIllustration?: string
  homeworkCommentRequired?: boolean
}

export interface IGetCourseResponse {
  total: number
  courses: ICourse[]
}

export interface IDiscipline {
  name: string
  id: number
  isUsed?: boolean
}

export interface ICourseResponse {
  id: number
  name: string
  lessonsPerMonth: number
  lessonsCount: number
  roleNames: string[]
  journalRoleNames: string[]
  curriculumCompleted: boolean
  homeworkCommentRequired: boolean
}

export type HomeworkDbData = {
  courseIds: number[]
  deadline: number
  highLevelCount: number
  homeworkDbLessonIds: number[]
  lowLevelCount: number
  mediumLevelCount: number
  assignmentType: HomeworkAssignmentTypes
}

export interface ICurriculum {
  id: number
  name: string
  position: number
  createdAt: string
  homeworkDbData?: HomeworkDbData
  homeworkDbLessons: string[]
}

export interface IGetCourseCurriculumsRes {
  curriculum: ICurriculum[]
  course: ICourse
  total: number
}

export type TFilterObject = {
  id: number
  name: string
}

export type TFilterObjectResponse = {
  courses: TFilterObject[]
  academics: TFilterObject[]
  learningTypes: TFilterObject[]
  offices: TFilterObject[]
}

export type CourseChapter = {
  id: number
  title: string
  createdAt: string
}

export type CourseChapterRes = {
  homeworkDbLessons: CourseChapter[]
  total: number
  course: {
    name: string
    id: number
  }
}

export type ChapterHomework = {
  id: number
  desc: string
  difficultlyLevel: HomeworkDbDifficultyLevels
  homeworkDbLessonId: number
  attachments: Attachment[]
  createdAt: string
}

export type ChapterHomeworkRes = {
  homeworkDb: ChapterHomework[]
  total: number
  lesson: {
    id: number
    name: string
  }
}

export type HomeworkDbCourses = {
  id: number
  name: string
}

export type GetHomeworkDbCoursesRes = {
  homeworkDbCourses: HomeworkDbCourses[]
  total: number
}

export type CurriculumHomeworkDbData = {
  lowLevelCount: number
  highLevelCount: number
  mediumLevelCount: number
  homeworkDbLessonId: number
  homeworkDbCourseId: number
}

export type SingleCurriculum = {
  deadline: number
  courseIds: number
  assignmentType: HomeworkAssignmentTypes
  curriculumHomeworkData: CurriculumHomeworkDbData[]
}

export type TeachersStatisTic = {
  academic: {
    id: number
    firstName: string
    lastName: string
    role: string
  }
  homework: number
  exam: number
  lateness: number
  lessonRate: number
  xp: number
}

export type GetTeachersStatisTicsRes = {
  academics: TeachersStatisTic[]
  total: number
}

export type StudentsBuffer = {
  type: string
  data: number[]
}

export type StudentsBufferRes = {
  studentsData: StudentsBuffer
}

export type GetSkillClassesVideosParams = {
  page: number
  perPage: number
  videoType?: SkillClassesTabsKeys
  key?: string
}

export type VideoStatusHistory = {
  id: number
  message: string
  status: number
  createdAt: string
}

export type SKillClassesVideosItem = {
  id: number
  name: string
  url: string
  videoType: string
  size: number
  status: SkillClassesVideosStatus
  file?: File
  currentStatus?: number
  uploadUrl?: string
  createdAt: string
  courseType: string
  percentage?: number
  videoStatusHistories: VideoStatusHistory[]
}

export type SKillClassesVideosRes = {
  total: number
  videos: SKillClassesVideosItem[]
}

export type GetVideoUploadLink = {
  filename: string
  name: string
  videoType: SkillClassesTabsKeys
  size: number
  courseType: string
}

export type GetVideoUploadLinkRes = {
  id: number
  videoId: string
  uploadUrl: string
}

export type SkillClassesUploadVideoParams = {
  id: number
  data: {
    message: string
    status: SkillClassesVideosStatus
  }
}

export type SkillClassesEditDataParam = {
  id: number
  data: SkillClassesEditData
}
