export enum UploadEndpoints {
  Upload = "/upload",
  StaffAttendances = "/staff-attendances",
  StaffProfile = "/staff-profile",
  Store = "/store",
  Items = "/items",
  CourseImageIllustration = "/course-image-illustration",
  HomeWorkUpload = "/upload/homework-attachments",
  UploadNotificationBanner = "/upload/notification-banners",
  UploadStudentPhoto = "/upload/student-profile",
  TusFiles = "/tus/files",
}
