export const groupDictionary = {
  assistant: "Yordamchi ustoz",
  groupName: "Guruh nomi",
  directionName: "Yo'nalish nomi",
  assistants: "Yordamchi ustozlar",
  info: "Ma`lumot",
  lessonStartDate: "Dars boshlanish sanasi",
  lessonEndDate: "Dars tugash sanasi",
  teacherName: "O'qituvchi nomi",
  teacher: "O`qituvchi",
  attendance: "Davomat",
  attending: "Yo`qlama qilish",
  iHomework: "Uy vazifalar",
  exams: "Imtihonlar",
  homeworkColumn: "Uy vazifa",
  videos: "Videolar",
  studentName: "O`quvchi ismi",
  changeStatus: "Statusni o’zgartirish",
  changePeriod: "Muddatni o`zgartirish",
  studentStatusInGroup: "Guruhdagi status",
  isCome: "Keldi",
  groupInfo: "Guruh ma`lumoti",
  takePicture: "Rasmga oling",
  startLesson: "Darsni boshlash",
  later: "Keyinroq",
  confirmModalText: "Agar darsni hozir boshlasangiz, bu dars uchun boshqa yo`qlama qila olmaysiz",
  warning: "Eslatma",
  noPermission: "Browser`da Kamera uchun ruxsat yo`q! Yoqgandan keyin kira olasiz)))",
  close: "Berkitish va bloklash",
  searchPlaceholder: "Video qidirish",
  emptyDataText: "",
  size: "Video hajmi",
  addedTime: "Qo`shilgan sanasi",
  lessonDate: "Dars sanasi",
  selectLesson: "Darsni tanlang",
  selectVideo: "Video",
  participants: "Ishtirokchilar",
  results: "Natijalar",
  retry: "Qayta topshirish",
  newExam: "Yangi imtihon",
  ball: "Ball",
  champions: "O`tganlar",
  failures: "Yiqilganlar",
  notEnter: "Topshirmaganlar",
  fail: "Yiqilgan",
  allParticipatesCount: "nafar",
  passed: "O`tgan",
  addHomework: "Uyga vazifa qo`shish",
  sendersCount: "Kutayotganlar",
  checksCount: "Qabul qilinganlar",
  givenTime: "Berilgan vaqt",
  deadline: "Tugash vaqti",
  inHour: "soatda",
  deadlineDate: "Tugash sanasi",
  homeworkDeadlines: "Tugash vaqti",
  wait: "Kutayabti",
  byWaiting: "Kutayotganlar bo`yicha",
  inComplete: "Bajarmaganlar",
  homeworkSendedTime: "Uyga vazifa jo`natilgan vaqt",
  examSendedTime: "Imtihon jo`natilgan vaqt",
  lastTime: "Oxirgi vaqti",
  deadlineTitle: "Muhlat",
  eventTime: "Xodisa vaqti",
  moduleName: "Module nomi",
  desc: "Tavsifi",
  homeworkDesc: "Uyga vazifa izohi",
  examDesc: "Imtihon izohi",
  filesCount: "Fayllar soni",
  files: "Fayllar",
  reject: "Qaytarish",
  accept: "Qabul qilish",
  send: "Jo`natish",
  notStarted: "Boshlanmagan",
  clear: "Tozalash",
  clearAll: "Barchasini tozalash",
  confirmTemplateTitle: "Ushbu shablonnni qaysi turdagi bildirishnomada ishlatmoqchisiz?",
  notStatus: "Status yo`q",
  uploadFinishNotificationMessage: "Video muvaffaqiyatli yuklandi",
  uploadPause: "Faylni yuklash to’xtatildi",
  uploadPlay: "Fayl serverga yuklanishga tayyorlanyapti",
  unloadConfirm: "Chiqishga ishonchingiz komilmi?",
  sureToDeleteVideo: "O`chirishga aminmisiz?",
  successDelete: "Video muvaffaqiyatli o`chirildi",
  convertedFileUpload: "Tayyor",
  convertedFileInKineskope: "Konvertatsiyada",
  error: "Xatolik!",
  deleteNotificationMessage: "Video o'chirildi",
  uploadingToServer: "Navbat kutyapti",
  converting: "Konvertatsiyada",
  uploading: "Yuklanyapti",
  pause: "Pauza",
  journal: "Jurnal",
  uploadWaiting: "Kutyapti",
  lessonName: "Dars nomi",
  addedDate: "Qo`shilgan sana",
  videoHistory: "Video tarixi",
  state: "Holati",
  message: "Xabari",
  read: "O`qilgan",
  didNotRead: "O`qilmagan",
  lesson: "Dars",
  updateNotificationMessage: "Video muvaffaqiyatli yuklandi!",
  upload: "Yuklash",
  homeworkFiles: "Uyga vazifa fayllari",
  examFiles: "Imtihon fayllari",
  publish: "E`lon qilish",
  time: "Vaqt",
  homeworkAddedSuccessfully: "Uyga vazifa muvaffaqiyatli qo`shildi",
  approved: "Qabul qilindi",
  author: "Muallif",
  academicUsers: "Akademik foydalanuvchilar:",
  successAttendance: "Davomat muvaffaqiyatli saqlandi!",
  successTakePhoto: "Rasmingiz muvaffaqiyatli saqlandi",
  photoError: "Xatolik yuz berdi",
  onlyNumber: "Faqat son va 0 - 100 oralig`ida son kirita olasiz!",
  examResult: "Imtihon javobi!",
  notParticipate: "Ishtirok etmadi",
  failureReason: "Yiqilish sababi",
  notParticipants: "Ishtirok etmaganlar",
  examDate: "Imtihon vaqti",
  announcedDate: "E`lon qilingan vaqti",
  makeSureExamDate: "Imtihon sanasi to'g'ri belgilanganiga ishonch hosil qiling!",
  shouldFillAllField: "Barcha studentlarning ma`lumotlarini to`ldirish shart!",
  successfullyAddedExam: "Imtihon muvaffaqiyatli qo`shildi!",
  successfullyEditedExam: "Imtihon muvaffaqiyatli o`zgartirildi!",
  sureDeleteHomework: "Uyga vazifani o`chirib yubormoqchimisiz?",
  successfullyDeleteHomework: "Uyga vazifa muvaffaqiyatli o`chirildi",
  successfullyEditedHomework: "Uyga vazifa muvaffaqiyatli o`zgartirildi",
  clickedDisabledDate: "Bu kunda dars mavjud emas!",
  dLessonTime: "Dars vaqti: ",
  dLessonDays: "Dars kunlari: ",
  come: "Kelgan",
  xp: "XP",
  xpPerUser: "XP(har bir a`zoga)",
  silver: "Kumush",
  lessonNotStartedYet: "Dars boshlanishiga hali bor",
  notHaveLessonThisDay: "Bu sanada dars mavjud emas yoki Davomat qilinmagan",
  isNotStudyingHere: "Bu o`quvchi bu yerda o`qimaydi",
  finishedClass: "Tugatgan",
  groupTeachers: "Guruh ustozlari",
  teacherPermission: "Bu o’qituvchi darsda tizim bilan ishlamaydi",
  warningMessage: "Biror yangi o`quvchi ro`yxatda ko`rinmasa, sahifani yangilang!",
  requiredTheme: "Mavzuni kiritish shart",
  emptyStringNotAllowed: "Mavzu nomi bo`sh bo`lishi mumkin emas!!!",
  homeworkStatistics: "Uyga vazifa statistikasi",
  another: "Boshqa",
  cannotAttend: "Davomat qilib bo`lmaydi",
  daily: "24 soat",
  moreOneDay: "36 soat",
  allOfThem: "Barchasi",
  waiters: "Kutayotganlar",
  acceptedHomework: "Qabul qilingan",
  rejectedHomework: "Qaytarilganlar",
  notDone: "Bajarilmagan",
  month: "oy",
  acceptedThing: "- Qabul qilinganlar",
  rejectedThing: "- Qaytarilganlar",
  pendingThing: "- Tekshirishni kutayotganlar",
  notDoneThing: "- Bajarmaganlar",
  notGiven: "- Berilmaganlar",
  waitingThing: "- Kutayotganlar bo’yicha",
  sort: "Saralash",
  sortByStudyPeriod: "O`quv oyi bo`yicha",
  byDate: "Vaqt oralig`i bo`yicha",
  averageBall: "O`rtacha bahosi",
  fullName: "Ism familiya",
  byScore: "Baho bo’yicha",
  byAccepted: "Qabul qilinganlar bo’yicha",
  byRejected: "Qaytarilganlar bo’yicha",
  byNotDone: "Bajarmaganlar bo’yicha",
  noData: "Ma`lumot mavjud emas",
  didNotStudy: "O’quvchi bu muddatda guruhda o’qimagan",
  finishedGroup: "Tugatgan",
  startedDate: "Boshlangan vaqti",
  endedDate: "Tugatgan vaqti",
  activeStudents: "Faol o`quvchilar",
  finishedStudents: "Tugatgan o`quvchilar",
  notStartedYet: "O’qishni boshlamagan",
  takeExam: "Imtihon olish",
  homeworkAdd: "Uyga vazifa qo`shish",
  homeworkEdit: "Uyga vazifani tahrirlash",
  checkingHomework: "Uyga vazifa tekshirish",
  studyPlan: "O`quv reja bo`yicha",
  selectTopic: "Mavzuni tanlang",
  byNotGiven: "Berilmaganlar",
  byNotGivenHw: "Berilmaganlar bo`yicha",
  course: "Kurs",
  category: "Yo`nalish",
  branch: "Fillial",
  waitingForChecking: "Tekshirilishini kutayotganlar",
  required_text:
    "xabar 1000 belgidan qisqa yoki unga teng bo'lishi kerak, xabar satr bo'lishi kerak, xabar bo'sh bo'lmasligi kerak",
  addStudentToGroup: "Guruhga student qo`shish",
  continue: "O'qishni davom ettirish",
  addStudentGroup: "guruhiga student qo`shish",
  studentFullNameId: "Student ism-familiyasi yoki ID",
  hhId: "HH ID",
  hhStatus: "ERP`dagi statusi",
  studyPeriod: "O`qish muddati",
  from: "dan",
  to: "gacha",
  withoutLesson: "Darssiz",
  price: "Narx",
  selectPrice: "Narxni tanlang",
  comment: "Izoh",
  writeComment: "Izoh yozing",
  addText: "Qo`shish",
  didntStudied: "Bir kun ham o`qimagan o`quvchilar",
  finished: "Tugatgan",
  notStudied: "O`qimagan",
  studyType: "O`quv turi",
  signAndColors: "Belgi va ranglar",
  filters: "Filterlar",
  homeworkConditions: "Uyga vazifa holatlari",
  attendanceConditions: "Davomat holatlari",
  notDoneHomework: "Bajarmagan",
  notGivenHomework: "Berilmagan",
  inLesson: "Darsda bor bo`lgan",
  cameLate: "Kechikkan",
  wasNotInLesson: "Darsda yo`q bo`lgan",
  finishGroup: "O`qishni boshlamagan yoki tugatgan",
  byCurriculum: "O`quv oyi bo`yicha",
  finish: "O'qishni to'xtatish",
  finishedDate: "O'qishni to'xtatish sanasi",
  exportXls: "Export XLSX file",
  attendanceTitle: "Davomat",
  didHaveLesson: "Dars bo`lmagan",
  participateLessonCount: "Darsda bo`lganlar soni",
  doneHomeworkCount: "Vazifasi qabul qilinganlar soni",
  attendanceSavedSuccessfully: "Yo’qlama muvaffaqiyatli saqlandi",
  successWithError:
    "Yo’qlama muvaffaqiyatli saqlandi. Ammo quyidagi studentlar   yo’qlamasi HHda saqlanishida xatolik yuz berdi:",
  student: "O`quvchi",
  haveLesson: "Darsda",
  countOfStudentWhoHasInLesson: "bo`lganlar soni",
  acceptHomework: "Vazifasi qabul",
  countOfStudentWhoWasAcceptedTheirHomework: "qilinganlar soni",
  inActive: "Nofaol",
  reason: "Sabab",
  negativeFactor: "Salbiy omil",
  studentAddedToGroup: "O`quvchi guruhga muvaffaqiyatli qo`shildi",
  finishGroupMessage: "O`quvchi guruhdagi o`qishini tugatdi",
  continueGroupMessage: "O`quvchi Guruhda o’qishni davom ettirmoqda",
  for: "uchun",
  curriculum: "O`quv oyi",
  teacherNotSelected: "O’qituvchilar tanlanmagan",
  minute: "daqiqa",
  lateTime: "Kech qolgan vaqti",
  addStudentToGroups: "guruhiga student qo`shish",
  finishStudy: "guruhida student o`qishini tugatish",
  continueStudy: "guruhida student o`qishini davom ettirish",
  addExam: "Imtihon qo`shish",
  editedStudentDeadlineMessage: "O`quvchi uchun uyga vazifa muddati o`zgartirildi",
  editedStudentExamDeadlineMessage: "O`quvchi uchun imtihon muddati o`zgartirildi",
  requiredHomeworkScore: "To`ldirish shart!",
  systemType: "Xatolik yuz bergan platforma.",
  clickUp: "Clickup",
  holyHope: "Holyhope",
  errorStudents: "Xatolik yuz bergan o`quvchilar ",
  announce: "E`lon qilish",
  lateTimeError: "Dars vaqtiga to`g`ri kelmaydi",
  teacherIsNotSelected: "O`qituvchi tanlanmagan",
  room: "Xona",
  homeWorkStatusIcon: "Uyga vazifa haqida ma`lumot",
  task: "Vazifa",
  inProgress: "Jarayonda",
  completed: "Tugagan",
  successfullyChecked: "Tekshirildi",
  reasonable: "Sababli",
  withoutReason: "Sababsiz",
  onRoad: "Yo`lda",
  reminderForExam: "Oxirgi 7 kundagi uyga vazifa berilmagan mavzularni tanlay olasiz!",
  reasonableComment: "Betoblik, sovuq maraka yoki boshqa joydagi imtihonlari sababli darsga kela olmaydi",
  withoutReasonComment: "Boshqa sababga ko'ra darsga kela olmaydi",
  onRoadComment: "Darsga kechikib kelyabti",
  kpiCalculatedDate: "KPI hisoblangan sana",
  maxLimitForDescription: "Izoh 1000 belgidan qisqa bo'lishi kerak",
  requiredDescription: "Izoh yozish shart!",
  notDoingHomework: "Vazifa bajarmayapti",
  hasEdited: "Tahrirlangan",
  bySystem: "Sistema tomonidan",
  byTeacher: "O`qituvchi tomonidan",
  givingHomeworkType: "Uyga vazifa turi",
  different: "Har xil",
  same: "Bir xil",
  homeworkDbLesson: "Uyga vazifa mavzusi",
  afterDeadline: "Deadline dan kegin",
  emptyFileName: "Audio fayl nomi mavjud emas",
  voiceRecordedSuccessFully: "Ovozli habar muvaffaqiyatli yozib olindi",
  ratingCalculateTime: `Reyting hisoblanish muddati`,
  columnSettings: "Ustunlarni sozlash",
  debtorAndPayment: "Qarz, To`lov",
  columnSettingModalTitle: "Ustun sozlamasi",
  resetColumns: "Asl holiga qaytatish",
  additionalFilter: "Qo‘sh. filtr",
  responsiblePerson: "Mas`ul shaxslar",
  additionalSpace: "Qo`shimcha maydonlar",
  groupCapacity: "Guruh sig`imi",
  permission: "Ruxsat",
  infos: "Ma`lumotlar",
  lessons: "Guruh darsliklari",
  actionsArchive: "Amallar tarixi",
  mainText: "Asosiy yozuv",
  additionalText: "Qo'shma yozuv",
  matches: "Mosliklar",
  dontCopy: "Nusxalanmasin",
  changePlacesOfMainAndAdditionalItems: "Asosiy va qo'shimcha o'rinlarni almashtiring",
  fullGroup: "To`lgan",
  downloadXlsx: "XLSX yuklash",
  dateFrom: "dan beri",
  fromPayers: "To'lovchilardan olingan:",
  lastPrice: "Qolgan:",
  allOfthem: "Jami:",
  visits: "Tashriflar",
  payed: "To'langan:",
  scoreForGroup: "Ushbu guruh uchun baho:",
  includesSalary: "Ish haqiga kiritilgan",
  allHours: "Umumiy soatlar:",
  joiningGroups: "Guruhga qo'shilish",
  salaryForAllGroups: "Barcha sinflar uchun o'qituvchiga to'lash:",
  parameters: "Parametrlar",
  paymentType: "To‘lov turi",
  productType: "Mahsulot toifasi:",
  averageAge: "O'rta yosh:",
  capacityOfStudents: "O‘quvchilar sig‘imi",
  existantStudent: "Mavjud o‘quvchilar",
  paidStudents: "To'laganlar:",
  addScheduleToGroup: "Guruh uchun jadval qo'shish",
  weekdays: "Hafta kunlari",
  color: "Rang",
  teacherPayments: "O‘qituvchi uchun to‘lovlar",
  didNotPay: "To'lanmagan",
  addGroup: "Guruh qo`shish",
  groupStatus: "Statusi",
  primaryPrice: "Birlamchi narx:",
  countOfSeats: "O‘rinlar soni",
  addStudent: "O‘quvchi qo‘shish",
  didNotSignContract: "Shartnoma tuzilmagan",
  documents: "Hujjatlar",
  debt: "Qarz: ",
  didNotAttendance: "Yo`qlama qilinmagan",
  studentAdd: "O‘quvchi qo‘shish",
}
