import { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { Button, Flex, Pagination, Spin, Tabs } from "antd"
import classNames from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import CheckedIcon from "@/assets/icons/checkedIcon.svg?react"
import mainDictionary from "@/dictionary"
import { addCatchNotification } from "@/modules/notifications"
import { useStores } from "@/stores"
import { notificationFilterOptions } from "./constants"
import { Notification } from "./Notification"

import styles from "./my-notification.module.scss"

const cx = classNames.bind(styles)

export const MyNotification = observer(() => {
  const { notificationStore, breadcrumbStore } = useStores()
  const [perPage, setPerPage] = useState(10)

  const { value: isLoading, setTrue: setTrueLoading, setFalse: setFalseLoading } = useBoolean(false)

  const handleChange = (page: number, perPage: number) => {
    setPerPage(perPage)
    notificationStore.setMyNotificationsPage(page)
  }

  const handleTabChange = async (key: string) => {
    notificationStore.setMyNotificationLoader(true)

    const notificationType = key === "all" ? null : Number(key)

    try {
      await notificationStore.getAllNotifications({
        ...(notificationType !== null && { notificationFilterType: notificationType }),
        page: notificationStore?.myNotificationsPage,
        perPage,
      })
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    } finally {
      notificationStore.setMyNotificationLoader(false)
    }
  }

  const handleReadAll = async () => {
    setTrueLoading()

    try {
      await notificationStore.readNotification(1517, {
        readAll: true,
      })

      await notificationStore.getAllNotifications({
        page: notificationStore?.myNotificationsPage,
        perPage,
      })
    } catch (error: Error | unknown) {
      addCatchNotification(error)
    } finally {
      setFalseLoading()
    }
  }

  const tabs = useMemo(
    () =>
      notificationFilterOptions.map((option) => ({
        key: option.value?.toString() || "all",
        label: option.label,
        children: "",
      })),
    [notificationStore.myNotifications?.notifications],
  )

  useEffect(() => {
    notificationStore.setMyNotificationLoader(true)

    notificationStore.getAllNotifications({
      page: notificationStore?.myNotificationsPage,
      perPage,
    })
  }, [notificationStore.myNotificationsPage, perPage])

  useEffect(() => {
    const details = [
      {
        label: mainDictionary.notifications,
      },
    ]

    breadcrumbStore.addBreadcrumbs(details)

    return () => {
      breadcrumbStore.clearBreadcrumbs()
    }
  }, [])

  return (
    <Flex className={cx("my-notification")} vertical gap={15}>
      <>
        <Flex justify="flex-end" align="center" gap={15}>
          <div className={cx("my-notification__tabs")}>
            <Tabs onChange={handleTabChange} items={tabs} />
          </div>
          <Button
            onClick={handleReadAll}
            loading={isLoading}
            size="middle"
            type="text"
            className={cx("my-notification__button")}
            icon={<CheckedIcon />}
          >
            {mainDictionary.markAsRead}
          </Button>
        </Flex>
        {notificationStore?.myNotificationLoader && (
          <Flex className={cx("my-notification__loader")} vertical justify="center" align="center">
            <Spin />
          </Flex>
        )}

        {notificationStore?.myNotifications?.notifications?.map((item) => <Notification key={item.id} {...item} />)}

        {(notificationStore?.myNotifications?.total || 0) > 10 && (
          <Flex justify="flex-end">
            <Pagination
              onChange={handleChange}
              current={notificationStore?.myNotificationsPage}
              total={Math.ceil(notificationStore?.myNotifications?.total || 0 / 10)}
            />
          </Flex>
        )}
      </>
    </Flex>
  )
})
