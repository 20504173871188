export enum EventsEndpoints {
  Events = "/events",
  MasterClasses = "/master-classes",
  MasterClass = "/master-class",
  Finish = "/finish",
  MasterClassParticipants = "/participants",
  ParticipantStatus = "/participant-status",
  Competitions = "/competitions",
  Competition = "/competition",
  Teams = "/teams",
  RewardStudents = "/reward-students",
}
