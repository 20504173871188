import mainDictionary from "@/dictionary"
import { addCatchNotification } from "@/modules/notifications"

export const OtherOption = {
  label: mainDictionary.other,
  value: 0,
}

import { Upload } from "antd"
import { RcFile } from "antd/es/upload"

export const validateFile = (file: RcFile) => {
  const isDocx = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

  if (!isDocx) {
    addCatchNotification(mainDictionary.contractDocxRequirement)
  }

  return isDocx || Upload.LIST_IGNORE
}
