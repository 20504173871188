import { useEffect } from "react"
import { observer } from "mobx-react"
import { BellOutlined, EditOutlined, KeyOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import EditLoginIcon from "@/assets/icons/edit-profile.svg"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { makeFileUrl, phoneFormatter } from "@/utils"
import { useBreadcrumbs } from "@/utils/hooks/useBreadcrumbs"
import { ActionCard } from "./ActionCard/ActionCard"
import { ChangeLogin } from "./ChangeLogin"
import { ChangeNotifications } from "./ChangeNotifications"
import { ChangePassword } from "./ChangePassword"
import { profileBreadcrumb } from "./constants"

import styles from "./profile.module.scss"

const cn = classNames.bind(styles)

export const Profile = observer(() => {
  const { profileStore, appStore, notificationStore } = useStores()

  const profile = appStore.profile

  useBreadcrumbs(profileBreadcrumb)

  const handleChangeLogin = () => {
    profileStore.setChangeLogin(true)
  }

  const handleChangePassword = () => {
    profileStore.setChangePassword(true)
  }

  const handleConfigureNotifications = () => {
    notificationStore.setConfigureNotifications(true)
  }

  useEffect(() => {
    notificationStore.getNotificationSettings()
  }, [])

  return (
    <div className={cn("profile")}>
      <Flex align="flex-start" gap={235}>
        <Typography.Title level={5}>{mainDictionary.profile}</Typography.Title>

        <Flex flex={1} vertical gap={24}>
          <Flex vertical gap={24}>
            <Avatar src={makeFileUrl(profile?.photo as string)} size={84} icon={<UserOutlined />} />

            <Flex vertical gap={8}>
              <Typography.Title level={4}>
                {profile?.firstName || "-"} {profile?.lastName || "-"}
              </Typography.Title>

              <Typography.Title level={5}>{phoneFormatter(profile?.phone || "-") || "-"}</Typography.Title>
            </Flex>
          </Flex>

          <div className={cn("profile__holder")}>
            <Flex className={cn("profile__wrapper")} vertical gap={8}>
              <Typography.Text type="secondary">{mainDictionary.login}</Typography.Text>

              <Flex className={cn("profile__card")} align="center" justify="space-between">
                <Flex align="center" gap={12}>
                  <img src={EditLoginIcon} width={18} height={18} alt="edit-icon" />

                  <Typography.Text>{profile?.login || "-"}</Typography.Text>
                </Flex>

                <Button type="text" size="small" icon={<EditOutlined />} onClick={handleChangeLogin} />
              </Flex>
            </Flex>

            <Flex className={cn("profile__wrapper")} vertical gap={8}>
              <Typography.Text type="secondary">{mainDictionary.password}</Typography.Text>

              <Flex className={cn("profile__card")} align="center" justify="space-between">
                <Flex align="center" gap={12}>
                  <KeyOutlined />

                  <Typography.Text>********</Typography.Text>
                </Flex>

                <Button type="text" size="small" icon={<EditOutlined />} onClick={handleChangePassword} />
              </Flex>
            </Flex>

            <Flex className={cn("profile__wrapper")} vertical>
              <Flex className={cn("profile__card")} align="center" justify="space-between">
                <Flex align="center" gap={12}>
                  <BellOutlined />

                  <Typography.Text>{mainDictionary.notificationSettings}</Typography.Text>
                </Flex>

                <Button type="text" size="small" icon={<EditOutlined />} onClick={handleConfigureNotifications} />
              </Flex>
            </Flex>
          </div>
        </Flex>
      </Flex>

      {profileStore.changeLogin && <ChangeLogin />}
      {profileStore.changePassword && <ChangePassword />}
      {notificationStore.isNotificationSettings && <ChangeNotifications />}
    </div>
  )
})
