import { observer } from "mobx-react"
import { Modal, Typography } from "antd"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"

export const LoginModal = observer(() => {
  const { appStore } = useStores()

  const handleCancel = () => {
    appStore.setLoginModal(null)
  }

  return (
    <Modal
      open={appStore.loginModal?.isVisible}
      title={mainDictionary.login}
      onCancel={handleCancel}
      onOk={handleCancel}
    >
      <Typography.Text copyable>{appStore.loginModal?.login}</Typography.Text>
    </Modal>
  )
})
