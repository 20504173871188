import { CalendarOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Flex, Tag, Tooltip, Typography } from "antd"
import classNames from "classnames/bind"
import { IGroup } from "@/api/groups"
import mainDictionary from "@/dictionary"
import { ColumnSettings } from "@/stores/groups"
import { GroupColumns } from ".."

import styles from "./table.module.scss"

const cn = classNames.bind(styles)

type ColumnsTypes = {
  handleGroupNameClick: (item: IGroup) => void
  selectedKey: number | null
  handleOpenModal: (item: IGroup) => void
  availableColumns: ColumnSettings[]
}

export const formattedColumns = ({ handleGroupNameClick, selectedKey, availableColumns }: ColumnsTypes): any => {
  const columnsValues = availableColumns.filter((item) => item.isChecked)?.map((item) => item.value)

  const columns = [
    {
      title: mainDictionary.groups,
      value: GroupColumns.Groups,
      render: (item: IGroup) => (
        <Flex gap={20}>
          <h4 className={cn("group-table__name")} onClick={handleGroupNameClick.bind(null, item)}>
            {item?.name}
          </h4>

          {item.id === selectedKey && (
            <Flex gap={8}>
              <Tooltip title={mainDictionary.schedule}>
                <Button size="small" icon={<CalendarOutlined />} />
              </Tooltip>

              <Tooltip title={mainDictionary.edit}>
                <Button size="small" icon={<EditOutlined />} />
              </Tooltip>

              <Tooltip title={mainDictionary.delete}>
                <Button danger size="small" icon={<DeleteOutlined />} />
              </Tooltip>
            </Flex>
          )}
        </Flex>
      ),
      fixed: "left",
      width: 300,
    },
    {
      title: mainDictionary.branch,
      value: GroupColumns.Branch,
      render: (item: IGroup) => <Typography.Text>{item?.office?.name || "-"}</Typography.Text>,
    },
    {
      title: mainDictionary.course,
      value: GroupColumns.Course,
      render: (item: IGroup) => <Typography.Text>{item?.course?.name || "-"}</Typography.Text>,
    },
    {
      title: mainDictionary.category,
      value: GroupColumns.Category,
      render: (item: IGroup) => <Typography.Text>{item?.category?.displayName || "-"}</Typography.Text>,
    },
    {
      title: mainDictionary.type,
      value: GroupColumns.Type,
      render: (item: IGroup) => <Typography.Text>{item.learningType}</Typography.Text>,
    },
    {
      title: mainDictionary.groupSchedule,
      value: GroupColumns.Schedule,
      render: (item: IGroup) => <Typography.Text>{item?.activeScheduleItems?.[0]?.beginTime || "-"}</Typography.Text>,
    },
    {
      title: mainDictionary.teachers,
      value: GroupColumns.Teachers,
      render: () => <span>Kimdir</span>,
    },
    {
      title: mainDictionary.room,
      value: GroupColumns.Room,
      render: () => <span>Osmondagi bolalar</span>,
    },
    {
      title: mainDictionary.debtorAndPayment,
      value: GroupColumns.Debtor,
      render: () => (
        <Flex>
          <Tag color="red">4</Tag>
          <Tag color="green">16</Tag>
        </Flex>
      ),
    },
  ]

  return columns.filter((item) => columnsValues.includes(item.value)) || []
}
