export const gamificationOfferDictionary = {
  publicOfferingNotAdded: `Oferta qo'shilmagan`,
  lastUpdatedTime: (time: string) => `Oxirgi o'zgartirilgan vaqti: ${time}`,
  update: `O'zgartirish`,
  save: "Saqlash",
  gamificationOffer: "Gamification oferta",
  gamificationOfferTitle: "Oferta nomi",
  gamificationAddedOfferDate: "Qoshilgan sanasi",
  copyToClipboard: "Nusxa olish",
  copiedToClipboard: "Vaqtinchalik xotiraga nusxalandi",
  studentStatuses: "O'quvchi statuslari",
  failedToClipboard: "Vaqtinchalik xotiraga nusxalashda xatolik",
  gamificationTab1: "Joriy oferta",
  gamificationTab2: "Tarix",
}
