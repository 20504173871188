export enum UserStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  NEW = "NEW",
}

export const userStatusList = [UserStatus.NEW, UserStatus.ACTIVE, UserStatus.BLOCKED]

export enum WorkStatus {
  WORKING = "ishlayabdi",
}

export const IIsActive = {
  ACTIVE: true,
  DISACTIVE: false,
}

export enum OrderBy {
  ASC = "ASC",
  DESC = "DESC",
}

export enum UserTypes {
  Admin = 1,
  Teacher = 2,
  Assistant = 3,
  Student = 4,
}
