export const managementDictionary = {
  notAttendedGroups: "Yo`qlama qilinmagan guruhlar",
  notGivenHomeworkGroup: "Uyga vazifa berilmagan guruhlar",
  warningForUpdate: "Oxirgi ma'lumotlarni ko'rish uchun sahifani yangilang",
  allCount: "Umumiy soni",
  descriptionRequired: "Tavsif yozish shart",
  warningForHomework:
    // eslint-disable-next-line max-len
    "60 - 100 oralig'ida ball qo'yilgan vazifa 'Qabul qilingan', 0 - 59 oralig'ida ball qo'yilgan vazifa 'Qaytarilgan' hisoblanadi.",
  autoCallTemplates: "Avtoqo`ng`iroq shablonlari",
  didNotComeToLesson: "Darsga kelish eslatmasi",
  failedInExam: "Imtixondan yiqilish eslatmasi",
  didNotSubmitHomeworkReminder: "Uyga vazifa topshirmaganlik eslatmasi",
  passExamReminder: "Imtixondan o`tganlik eslatmasi",
  passExam: "Imtixondan o`tdi",
  allCalls: "Umumiy qo'ng'iroqlar",
  byFullName: "Ism-familiya bo`yicha",
  byContact: "Aloqa bo`yicha",
  byAnswerStatus: "Javob statusi bo`yicha",
  byPeriod: "Muddat bo`yicha",
  isConnected: "Aloqa o`rnatildi",
  answer: "Javob",
  notAnswered: "Javob berilmadi",
  notConnectedCall: "Aloqa o`rnatilmadi",
  connected: "O`rnatildi",
  notConnected: "O`rnatilmadi",
}
