import { observer } from "mobx-react"

export const Exam = observer(() => {
  console.log("")

  return (
    <>
      <>Exam</>
    </>
  )
})
