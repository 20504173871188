import { makeAutoObservable } from "mobx"
import {
  eventsApi,
  EventStatuses,
  EventTeams,
  GetCompetitionRes,
  GetMasterClassesRes,
  GetMasterClassParticipantsRes,
  ModifiedMasterClass,
} from "@/api/events"
import { addCatchNotification } from "@/modules/notifications"
import {
  AddMasterClassReq,
  ChangeParticipateStatus,
  ChangeStatus,
  EditMasterClassReq,
  GetMasterClassParams,
  GetMasterClassParticipantsReq,
  RewardStudents,
} from "./types"

class EventsStore {
  activeMasterClass: EventStatuses | string = EventStatuses.Scheduled
  activeEvents: EventStatuses | string = EventStatuses.Scheduled
  masterClasses: GetMasterClassesRes | null = null
  competitions: GetCompetitionRes | null = null
  masterClassLoading = false
  competitionLoading = false
  selectedMasterClass: ModifiedMasterClass | null = null
  selectedMasterClassLoading = false
  masterClassParticipants: GetMasterClassParticipantsRes | null = null
  participantsLoading = false
  participateChangeStatus: ChangeParticipateStatus | null = null
  eventTeams: EventTeams[] | null = null
  awardedTeams: EventTeams[] | null = null
  isAvailableEditAwardedParticipants = false
  isAvailableEditParticipants = false
  coursesModal = false
  selectedCourses: string[] = []
  awardedParticipants: GetMasterClassParticipantsRes | null = null
  makeModal = false
  makeCompetitionModal = false

  participantsPagination: GetMasterClassParticipantsReq = {
    page: 1,
    perPage: 10,
  }

  masterClassFilter: GetMasterClassParams = {
    page: 1,
    perPage: 10,
    eventStatus: EventStatuses.Scheduled,
  }

  competitionFilter: GetMasterClassParams = {
    page: 1,
    perPage: 10,
    eventStatus: EventStatuses.Scheduled,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setMakeCompetitionModal = (params: boolean) => {
    this.makeCompetitionModal = params
  }

  setMakeModal = (params: boolean) => {
    this.makeModal = params
  }

  setSelectedCourses = (params: string[]) => {
    this.selectedCourses = params
  }

  setCoursesModal = (params: boolean) => {
    this.coursesModal = params
  }

  setAwardedTeams = (params: EventTeams[] | null) => {
    this.awardedTeams = params
  }

  setIsAvailableEditAwardedParticipants = (params: boolean) => {
    this.isAvailableEditAwardedParticipants = params
  }

  setIsAvailableEditParticipants = (params: boolean) => {
    this.isAvailableEditParticipants = params
  }

  setEventTeams = (params: EventTeams[] | null) => {
    this.eventTeams = params
  }

  setCompetitionFilter = (params: GetMasterClassParams) => {
    this.competitionFilter = params
  }

  setParticipateChangeStatus = (params: ChangeParticipateStatus | null) => {
    this.participateChangeStatus = params
  }

  setActiveEvents = (value: EventStatuses | string) => {
    this.activeEvents = value
  }

  setParticipantsPagination = (params: GetMasterClassParticipantsReq) => {
    this.participantsPagination = params
  }

  setParticipantsLoading = (params: boolean) => {
    this.participantsLoading = params
  }

  setMasterClassParticipants = (params: GetMasterClassParticipantsRes | null) => {
    this.masterClassParticipants = params
  }

  setMasterClassAwardedParticipants = (params: GetMasterClassParticipantsRes | null) => {
    this.awardedParticipants = params
  }

  setSelectedMasterClassLoading = (value: boolean) => {
    this.selectedMasterClassLoading = value
  }

  setMasterClassFilter = (params: GetMasterClassParams) => {
    this.masterClassFilter = params
  }

  setSelectedMasterClass = (params: ModifiedMasterClass | null) => {
    this.selectedMasterClass = params
  }

  setMasterClassLoading = (value: boolean) => {
    this.masterClassLoading = value
  }

  setCompetitionLoading = (value: boolean) => {
    this.competitionLoading = value
  }

  setMasterClasses = (params: GetMasterClassesRes | null) => {
    this.masterClasses = params
  }

  setCompetitions = (params: GetCompetitionRes | null) => {
    this.competitions = params
  }

  getSingleMasterClasses = (id: number) =>
    eventsApi
      .getMasterClass(id)
      .then((res) => {
        if (res.success) {
          this.setSelectedMasterClass(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setSelectedMasterClassLoading(false))

  deleteCompetition = (id: number) => eventsApi.deleteCompetition(id)

  deleteMasterclass = (id: number) => eventsApi.deleteMasterclass(id)

  getEventTeams = (id: number) =>
    eventsApi
      .getTeams(id)
      .then((res) => {
        if (res.success) {
          this.setEventTeams(res.data?.eventTeamData || [])
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setParticipantsLoading(false))

  getAwardedTeams = (id: number, awarded: boolean) =>
    eventsApi
      .getTeams(id, awarded)
      .then((res) => {
        if (res.success) {
          this.setAwardedTeams(res.data?.eventTeamData || [])
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setParticipantsLoading(false))

  getSingleCompetition = (id: number) =>
    eventsApi
      .getCompetition(id)
      .then((res) => {
        if (res.success) {
          this.setSelectedMasterClass(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setSelectedMasterClassLoading(false))

  getMasterClasses = (params: GetMasterClassParams) =>
    eventsApi
      .getMasterClasses(params)
      .then((res) => {
        if (res.success) {
          this.setMasterClasses(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setMasterClassLoading(false))

  getCompetitions = (params: GetMasterClassParams) =>
    eventsApi
      .getCompetitions(params)
      .then((res) => {
        if (res.success) {
          this.setCompetitions(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setCompetitionLoading(false))

  getMasterClassParticipants = (params: GetMasterClassParticipantsReq) =>
    eventsApi
      .getMasterClassParticipants(params)
      .then((res) => {
        if (res.success) {
          this.setMasterClassParticipants(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setParticipantsLoading(false))

  getMasterClassAwardedParticipants = (params: GetMasterClassParticipantsReq) =>
    eventsApi
      .getMasterClassParticipants(params)
      .then((res) => {
        if (res.success) {
          this.setMasterClassAwardedParticipants(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setParticipantsLoading(false))

  finishMasterClass = (id: number) => eventsApi.finishMasterClass(id)

  finishCompetition = (id: number) => eventsApi.finishCompetition(id)

  setActiveEventsStore = (value: EventStatuses | string) => {
    this.activeMasterClass = value
  }

  addMasterClass = (params: AddMasterClassReq) => eventsApi.addMasterClass(params)

  addCompetition = (params: AddMasterClassReq) => eventsApi.addCompetition(params)

  editMasterClass = (params: EditMasterClassReq) => eventsApi.editMasterClass(params)

  editCompetition = (params: EditMasterClassReq) => eventsApi.editCompetition(params)

  changeParticipantStatus = (params: ChangeStatus) => eventsApi.changeParticipantStatus(params)

  rewardStudents = (params: RewardStudents) => eventsApi.rewardStudents(params)
}

export const eventsStore = new EventsStore()
