import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const Contracts = lazy(() =>
  import("./Contracts").then(({ Contracts }) => ({ default: Contracts })).catch(handleCatchChunkError),
)

export const ContractsHistory = lazy(() =>
  import("./History").then(({ ContractHistory }) => ({ default: ContractHistory })).catch(handleCatchChunkError),
)
