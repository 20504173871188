import { Instance, IResponse } from "@/api"
import { VideoCreateRequestParams, VideoCreateResponse, VideoUpdateRequestParams } from "./types"

const endpoints = {
  videoUpdateStatusAcademic: (id: string | number) => `/video/update-status-for-academic/${id}`,
  videoUpdateStatusAdmin: (id: string | number) => `/video/update-status-for-admin/${id}`,
  videoDeleteForAcademic: (id: string | number) => `/video/delete-for-academic/${id}`,
  videoDeleteForAdmin: (id: string | number) => `/video/delete-for-admin/${id}`,
} as const

class Api extends Instance {
  createUploadVideo = (endpoint: string, params: VideoCreateRequestParams): Promise<IResponse<VideoCreateResponse>> =>
    this.post(endpoint, params)

  updateVideoStatus = (
    { id, ...restParams }: VideoUpdateRequestParams,
    isAcademic?: boolean,
  ): Promise<IResponse<NonNullable<unknown>>> =>
    this.put(isAcademic ? endpoints.videoUpdateStatusAcademic(id) : endpoints.videoUpdateStatusAdmin(id), restParams)

  deleteUploadVideo = (id: number, isAcademic?: boolean): Promise<IResponse<NonNullable<unknown>>> =>
    this.delete(isAcademic ? endpoints.videoDeleteForAcademic(id) : endpoints.videoDeleteForAdmin(id))
}

export const api = new Api()
