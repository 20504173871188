import { observer } from "mobx-react"

export const ActionsHistory = observer(() => {
  console.log("")

  return (
    <>
      <>Actions archive</>
    </>
  )
})
