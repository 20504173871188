import { Endpoints } from "@/api/endpoints"
import { INetworkConfig, Instance } from "@/api/instance"
import { IResponse } from "@/api/types"
import { IGetSynchParams, ILogSendRequestParams, SynchronizeAvailablePages } from "@/stores"
import { TPermission } from "../controls/types"
import { IStaff } from "../groups/types"
import { AppEndpoints } from "./endpoints"
import { IGetReportsCount, IGetSynchResponse, TInitial } from "./types"

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}`,
}

class AppApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getInitial = (): Promise<IResponse<TInitial>> => this.send("post", `${AppEndpoints.Roles}${AppEndpoints.Initial}`)

  getMenus = (): Promise<IResponse<TPermission[]>> =>
    this.get(`${AppEndpoints.Roles}${AppEndpoints.AccessControlMenus}`)

  logout = (link: string): Promise<IResponse<any>> => this.post(link)

  getProfile = (): Promise<IResponse<IStaff>> => this.get(AppEndpoints.Profile)

  getReportsCount = (): Promise<IResponse<IGetReportsCount>> => this.get(`${AppEndpoints.Reports}${AppEndpoints.Count}`)

  getNotifications = (): Promise<IResponse<number>> => this.get(`${AppEndpoints.Notifications}${AppEndpoints.Count}`)

  sendLog = (params: ILogSendRequestParams): Promise<IResponse<boolean>> => this.post(AppEndpoints.SenderLog, params)

  getQueueCount = (): Promise<IResponse<number>> =>
    this.get(`${AppEndpoints.Synchronization}${AppEndpoints.MessageCount}`)
}

export const appApi = new AppApi(config)
