import dayjs, { Dayjs } from "dayjs"
import duration from "dayjs/plugin/duration"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

export const TIME_FORMAT = "HH:mm"
export const TIME_FULL_FORMAT = "HH:mm:ss"
export const DATE_FORMAT_WITH_HOUR = "YYYY-MM-DD HH:mm"
export const YEAR_FORMAT = "YYYY-MM-DD"
export const YEAR_VIEW_FORMAT = "DD MMMM, YYYY"
export const DATE_MONTH_FORMAT = "DD-MMMM"
export const DAY_OF_MONTH_FORMAT = "DD.MM"
export const DAYS_WITH_DOTS = "DD.MM.YYYY"
export const LOCATION_DATE = "ddd MMM DD YYYY HH:mm"
export const MINUTES = "mm"
export const FULL_TIME = `${YEAR_VIEW_FORMAT} ${TIME_FORMAT}`
export const YEAR_FORMAT_FOR_ATTENDANCE = `${YEAR_FORMAT} ${TIME_FULL_FORMAT}`

export const getFormattedCurrentDate = (): string => dayjs(new Date()).format(YEAR_FORMAT)

export const getFormattedCurrentDateWithHour = (): string => dayjs(new Date()).format(DATE_FORMAT_WITH_HOUR)

export const getFormattedSelectedDateWithHour = (date?: string): string => {
  if (!date) return "-"

  return dayjs(date, FULL_TIME).format(DATE_FORMAT_WITH_HOUR)
}

export const getFormattedTomorrowDate = (): string => dayjs(new Date()).add(1, "d").format(YEAR_FORMAT)

export const formatDate = (date: string | any, formal?: boolean, full?: boolean) => {
  if (!date) return "-"

  return dayjs(date).format(full ? FULL_TIME : formal ? YEAR_FORMAT : YEAR_VIEW_FORMAT)
}

export const getFormattedCurrentDayOfMonthFormat = (): string => dayjs(new Date()).format(DAY_OF_MONTH_FORMAT)

export const getFormattedCurrentDayOfMonthFormatFromParams = (date: string | Date | Dayjs): string => {
  const dayjsDate = dayjs(date)

  return dayjsDate.isValid() ? dayjsDate.format(DAY_OF_MONTH_FORMAT) : ""
}

export const getFormattedDateFromParams = (date?: string, isView: boolean = false): string => {
  if (!date) return ""

  return dayjs(date).format(!isView ? YEAR_FORMAT : YEAR_VIEW_FORMAT)
}

export const getFormattedFullDateFromParams = (date: string | Date | Dayjs): string => {
  const dayjsDate = dayjs(date)

  return dayjsDate.isValid() ? dayjsDate.format(FULL_TIME) : ""
}

export const getFormattedFullDatePlus5HourFromParams = (date: string | Date | Dayjs): string | null => {
  const dayjsDate = dayjs(date)

  return dayjsDate.isValid() ? dayjsDate.add(5, "hour").format(FULL_TIME) : ""
}

export const getDay = (date: string) => dayjs(date).format(DATE_MONTH_FORMAT)

export const getFormattedTimeFromParams = (time: string): string => dayjs(time, TIME_FORMAT).format(TIME_FORMAT)

export const getFormattedDateFromParamsWidthHover = (date: string | Date): string =>
  dayjs(date).format(DATE_FORMAT_WITH_HOUR)
export const getFormattedTime = (date: string) => dayjs(date).format(TIME_FORMAT)

export const getTimeFormattedVersion = (time: string, defaultFormat?: boolean) =>
  dayjs(time, defaultFormat ? FULL_TIME : YEAR_FORMAT_FOR_ATTENDANCE).format(TIME_FULL_FORMAT)

export const getDateFormatForAttendance = (date: string): string => {
  const dayjsDate = dayjs(date)

  return dayjsDate.isValid() ? dayjs(date, FULL_TIME).format(YEAR_FORMAT) : date
}

export const getCurrentTime = () => dayjs().format(TIME_FORMAT)

export const fullTimeToFormattedDate = (date?: string | Date | null) => {
  if (!date) {
    return
  }

  return dayjs(date, YEAR_FORMAT).format(YEAR_VIEW_FORMAT)
}

export const newFormatToStandard = (date: string) => {
  if (!date) return

  return dayjs(date, YEAR_VIEW_FORMAT).format(YEAR_FORMAT)
}

export const compareTimes = (deadline: string, time: string, startTime: string): boolean => {
  const deadlineTime = dayjs(deadline, TIME_FORMAT)
  const inputTime = dayjs(time, TIME_FORMAT)
  const startingTime = dayjs(startTime, TIME_FORMAT)

  return inputTime.isAfter(deadlineTime) || inputTime.isBefore(startingTime)
}

export const getMinutes = (time: string) => {
  if (!time) return

  return dayjs(time, TIME_FULL_FORMAT).format(MINUTES)
}

export const formatTimeWithSeconds = (time: string) => {
  if (!time) return

  return dayjs(time, TIME_FORMAT).format(TIME_FULL_FORMAT)
}

export const getPreviousDate = () => {
  const currentDate = dayjs()

  const previousDate = currentDate.subtract(1, "day")

  return previousDate.format(YEAR_FORMAT)
}

export const getPastDays = (date: number, from?: string) => {
  if (!date) return

  return dayjs(from).subtract(date, "day").format(YEAR_FORMAT)
}

export const getFutureDays = (date: number, from?: string) => {
  if (!date) return

  return dayjs(from).add(date, "day").format(YEAR_FORMAT)
}

export const formatDateWithDots = (date?: string) => {
  if (!date) return ""

  return dayjs(date).format(DAYS_WITH_DOTS)
}

export const calculateBirthDate = (date?: string) => {
  if (!date) return ""

  return dayjs().diff(dayjs(date), "year")
}

export const formattedLocationDate = (date?: string) => {
  if (!date) return

  return dayjs(date).tz("Asia/Tashkent").locale("en").format(`${LOCATION_DATE} [GMT]Z [(Uzbekistan Standard Time)]`)
}

export const formatSecondsToMinutesAndSeconds = (seconds: number) => {
  const duration = dayjs.duration(seconds * 1000)

  return duration.format(TIME_FULL_FORMAT)
}
