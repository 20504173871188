export enum StudentEndpoints {
  Students = "/students/all",
  Student = "/students",
  Certificates = "/certificates",
  StudentChangeLogin = "/students/{id}/change-login",
  StudentResetPassword = "/students/{id}/reset-password",
  StudentCategory = "/students/filter-objects",
  StudentGroupsInfoFile = "/student-groups/info-file",
  StudentSmsHistory = "/students/{id}/sms-history",
  Export = "/export",
  StudentSmsSend = "/students/{id}/send-sms",
  SystemStatus = "/system-status",
  StudentGroups = "/student-groups",
  Groups = "/groups",
  UpdateProfile = "/update-profile",
  Update = "update",
  StudentsContractsCreate = "/students/contracts/create",
  StudentsContractsStudent = "/students/contracts/student",
  StudentContractsResend = "/students/contracts/resend",
}
