import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { Contracts, ContractsHistory } from "./lazy"

export const contractsRouter = [
  {
    path: ROUTES.contracts,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.contracts}>
        <Contracts />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.contractsHistory,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.contracts}>
        <ContractsHistory />
      </AvailableComponent>
    ),
  },
]
