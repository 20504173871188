import { useMemo } from "react"
import { observer } from "mobx-react"
import { Button, Flex, Table, Tooltip, Typography } from "antd"
import classNames from "classnames/bind"
import ExchangeIcon from "@/assets/icons/exchange.svg"
import mainDictionary from "@/dictionary"

import styles from "./table.module.scss"

const cn = classNames.bind(styles)

export const DuplicationsTable = observer(() => {
  const columns = useMemo(
    () => [
      {
        title: mainDictionary.mainText,
        render: () => (
          <Flex vertical>
            <Typography.Text>Nodirbek Abdumannopov</Typography.Text>
            <Typography.Text>+998 91 452 2414</Typography.Text>
          </Flex>
        ),
      },
      {
        width: 100,
        render: () => (
          <Tooltip title={mainDictionary.changePlacesOfMainAndAdditionalItems}>
            <img className={cn("table__exchange")} src={ExchangeIcon} alt="exchange" width={24} height={24} />
          </Tooltip>
        ),
      },
      {
        title: mainDictionary.additionalText,
        render: () => (
          <Flex vertical>
            <Typography.Text>Nodirbek Abdumannopov</Typography.Text>
            <Typography.Text>+998 91 452 2414</Typography.Text>
          </Flex>
        ),
      },
      {
        title: mainDictionary.matches,
        width: 300,
        render: () => <span>F.I.Sh</span>,
      },
      {
        render: () => (
          <Flex justify="flex-end" gap={20}>
            <Button size="small" className={cn("table__copy")}>
              {mainDictionary.dontCopy}
            </Button>

            <Button size="small" type="primary">
              {mainDictionary.upload}
            </Button>
          </Flex>
        ),
      },
    ],
    [],
  )

  return (
    <div className={cn("table")}>
      <Table columns={columns} dataSource={[{}, {}]} />
    </div>
  )
})
