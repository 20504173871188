import { IResponse } from "@/api/types"
import { Endpoints } from "../endpoints"
import { INetworkConfig, Instance } from "../instance"
import { AuditEndpoints } from "./endpoints"
import { IAuditParams, IAuditResponse, ISingleAudit } from "./types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class AuditsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getAudits = (params: IAuditParams): Promise<IResponse<IAuditResponse>> => this.get(AuditEndpoints.Audits, { params })

  getSingleAudits = (id: number): Promise<IResponse<ISingleAudit>> => this.get(`${AuditEndpoints.Audits}/${id}`)
}

export const auditsApi = new AuditsApi(config)
