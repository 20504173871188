import { observer } from "mobx-react"
import { Flex } from "antd"
import classNames from "classnames/bind"
import { useStores } from "@/stores"
import { Filter } from "./Filter"
import { InfoModal } from "./InfoModal"
import { ByGroupTable } from "./Table/Table"

import styles from "./by-group.module.scss"

const cn = classNames.bind(styles)

export const ByGroup = observer(() => {
  const { gamificationStore } = useStores()

  return (
    <Flex className={cn("by-group")} vertical>
      <Filter />
      <ByGroupTable />

      {gamificationStore.byGroupInfoModal && <InfoModal />}
    </Flex>
  )
})
