import { Button, Flex, Segmented, Typography } from "antd"
import { observer } from "mobx-react"
import mainDictionary from "@/dictionary"
import { studentTypes } from "@/pages/Groups/constants"
import { useState } from "react"
import styles from "./students.module.scss"
import classNames from "classnames/bind"
import "./helper.css"
import { Student } from "./Student"

const cn = classNames.bind(styles)

export const Students = observer(() => {
  const [selectedTab, setSelectedTab] = useState(1)

  return (
    <Flex gap={24} vertical className={cn("students")}>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap={32}>
          <Typography.Title level={5}>{mainDictionary.students}(13)</Typography.Title>

          <Segmented options={studentTypes} value={selectedTab} onChange={setSelectedTab} />
        </Flex>

        <Button type="primary">{mainDictionary.addStudent}</Button>
      </Flex>

      <Flex gap={16} vertical>
        <Student />
        <Student />
        <Student />
        <Student />
      </Flex>
    </Flex>
  )
})
