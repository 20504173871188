export type NotificationSettingsType = {
  id: number
  displayName: string
  actionType: number
  isEnable: boolean
}

export type NotificationSettings = {
  notificationTypeId: number
  isEnable: boolean
}

export type NotificationSettingsRequest = {
  settings: NotificationSettings[]
}

export type NotificationSettingsResponse = {
  data: NotificationSettingsType[]
}

export const enum NotificationFilterTypes {
  StudentExam = 1,
  StudentHomework,
  StudentGroup,
  AcademicAttendance,
  AcademicRemindAssignHomework,
  AcademicHomeworkCompletionAlert,
  AcademicExam,
  AcademicExamCompletionAlert,
  AcademicGroup,
}
