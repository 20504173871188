import { Endpoints } from "@/api/endpoints"
import { INetworkConfig, Instance } from "@/api/instance"
import { TeachersKpiRequestParamsType, TeachersKpiResponseType } from "@/types"
import { IResponse } from "../types"
import { TeachersEndpoints } from "./endpoints"
import { IGetAttendanceRequest, IGetTeacherAttendanceResponse, IGetTeacherGroupResponse } from "./types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class TeachersApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getTeacherGroups = (params: number): Promise<IResponse<IGetTeacherGroupResponse>> =>
    this.get(`${TeachersEndpoints.Teacher}/${params}/groups`)

  getTeacherAttendances = (params: IGetAttendanceRequest): Promise<IResponse<IGetTeacherAttendanceResponse>> =>
    this.get(`${TeachersEndpoints.Academic}/${params.id}/${params.groupId}/attendances`, {
      params: {
        page: params.page,
        perPage: params.perPage,
      },
    })

  getTeachersKpi = (params: TeachersKpiRequestParamsType): Promise<IResponse<TeachersKpiResponseType>> =>
    this.get(TeachersEndpoints.TeachersKpi, { params })
}

export const teachersApi = new TeachersApi(config)
