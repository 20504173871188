import { makeAutoObservable } from "mobx"
import { Group } from "@/api/groups"
import { EnterTypes } from "@/api/staff"
import { RoleTypes } from "../staff/types"

class FaceIdStore {
  activeTab = RoleTypes.STAFF
  total = 0
  key = ""
  startDate: string | undefined = undefined
  endDate: string | undefined = undefined
  page = 1
  perPage = 10
  isOpenGroupModal = false
  groups: Group[] = []
  roleId: number | null = null
  enterType: EnterTypes | string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  resetFilters = () => {
    this.key = ""
    this.startDate = undefined
    this.endDate = undefined
    this.page = 1
    this.roleId = null
    this.enterType = null
  }

  setActiveTab = (activeTab: RoleTypes) => {
    this.activeTab = activeTab
  }

  setTotal = (total: number) => {
    this.total = total
  }

  setKey = (key: string) => {
    this.key = key
  }

  setStartDate = (date: string | undefined) => {
    this.startDate = date
  }

  setEndDate = (date: string | undefined) => {
    this.endDate = date
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setIsOpenGroupModal = (isOpen: boolean) => {
    this.isOpenGroupModal = isOpen
  }

  setGroups = (groups: Group[]) => {
    this.groups = groups
  }

  reset() {
    this.activeTab = RoleTypes.STAFF
    this.total = 0
    this.key = ""
    this.startDate = undefined
    this.endDate = undefined
    this.page = 1
    this.perPage = 10
  }
}

export const faceIdStore = new FaceIdStore()
