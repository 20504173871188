import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import {
  Assistants,
  PaymentHistory,
  StudentAdd,
  StudentMore,
  Students,
  TeacherAttendance,
  Teachers,
  TeacherSingle,
  UsersAcademic,
  UsersAcademicEdit,
  UsersAcademicTeacherGroup,
  UsersStaff,
  UsersStaffEdit,
} from "./lazy"

export const userRouters = [
  {
    path: ROUTES.singleStudent,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersStudents}>
        <StudentMore />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.studentPaymentHistory,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersStudents}>
        <PaymentHistory />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.students,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersStudents}>
        <Students />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.studentAdd,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersStudents}>
        <StudentAdd />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.teacherSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersAcademic}>
        <TeacherSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.assistants,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersAcademic}>
        <Assistants />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.teachers,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.usersAcademic}>
        <Teachers />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.users,
    children: [
      {
        path: ROUTES.usersAcademic,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.usersAcademic}>
            <UsersAcademic />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.usersAcademicSingle,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.usersAcademic}>
            <UsersAcademicEdit />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.usersAcademicGroups,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.usersAcademic}>
            <UsersAcademicTeacherGroup />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.teacherAttendance,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.usersAcademic}>
            <TeacherAttendance key="teacher-attendence" />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.usersStaff,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.usersStaff}>
            <UsersStaff />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.usersSingleStaff,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.usersStaff}>
            <UsersStaffEdit />
          </AvailableComponent>
        ),
      },
    ],
  },
]
