import { useEffect } from "react"
import mainDictionary from "@/dictionary"
import { addCatchNotification } from "@/modules/notifications"
import { useStores } from "@/stores"

export const useDetectBrowserClose = () => {
  const { kinescopeStore, studentStore } = useStores()

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (!kinescopeStore.hasUploadingFiles() || !studentStore.isCreatingStudent) {
      return
    }

    addCatchNotification(mainDictionary.getNotUploadUrlFromKinescope(mainDictionary.notClose))

    const confirmationMessage = mainDictionary.notClose

    event.returnValue = confirmationMessage //Gecko + IE

    return confirmationMessage
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])
}
