import {
  AddNominationReq,
  EditNominationReq,
  GetAllGroupMembersReq,
  GetGroupAttendanceReq,
  GetStudentGroupTypes,
  GetTheBestOnesReq,
  IAddActionLevelRequest,
  IAddActionRequest,
  IAddLevelRequest,
  IAddParticipateLessonDeed,
  IAddStudentAwards,
  IAward,
  IDeleteInfo,
  IEditActionLevel,
  IEditDeedRequest,
  IEditMethodRequest,
  IGetActionParams,
  IGetCategoriesRequest,
  IGetCoursesParams,
  IGetGroupActionRequest,
  IGetGroupRequest,
  IGetStaffGroupActions,
  IGetStatistics,
  IGetStatusesParams,
  IGetStudentAwards,
  IUpdateActionGroups,
  IUpdateSelectIds,
} from "@/stores/gamification"
import { Endpoints } from "../endpoints"
import { IGroup, StaffGroupsDetail } from "../groups"
import { INetworkConfig, Instance } from "../instance"
import { IResponse } from "../types"
import { GamificationEndpoints } from "./endpoints"
import {
  GetAllGroupMembersRes,
  GetGroupsByActionRes,
  GetTheBestOnesRes,
  GroupAttendanceRes,
  IAction,
  IActionLevelResponse,
  ICategory,
  IGetActionResponse,
  IGetActionsByMethodRes,
  IGetCategoryResponse,
  IGetCoursesResponse,
  IGetGroupActionsByGroupResponse,
  IGetLevelsByCategoryIdResponse,
  IGetSingleActionResponse,
  IGetStaffGroupActionResponse,
  IGetStatisticsMethodsResponse,
  IGetStatusesResponse,
  IGetStudentAwardResponse,
  IGetStudentCategoriesOnly,
  IGetStudentStatistics,
  IGroupActionCategoriesResponse,
  IGroupInfo,
  ILevel,
  IMethod,
  IStatistics,
  IStatisticsResponse,
  LevelInfo,
  StudentAwardGroups,
  TCourse,
} from "./types"

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}`,
}

class GamificationApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  addAction = (params: IAddActionRequest): Promise<IResponse<IAction>> =>
    this.post(GamificationEndpoints.Actions, params)

  getActions = (params?: IGetActionParams): Promise<IResponse<IGetActionResponse>> =>
    this.get(GamificationEndpoints.Actions, { params })

  getActionLevels = (params: number): Promise<IResponse<IActionLevelResponse>> =>
    this.get(`${GamificationEndpoints.ActionLevels}${GamificationEndpoints.ByAction}/${params}`)

  addActionLevel = (params: IAddActionLevelRequest): Promise<IResponse<IAction>> =>
    this.post(`${GamificationEndpoints.ActionLevels}`, params)

  editActionLevel = (params: IEditActionLevel): Promise<IResponse<IAction>> =>
    this.put(`${GamificationEndpoints.ActionLevels}/${params.id}`, params.data)

  deleteActionLevel = (params: number): Promise<IResponse<IAction>> =>
    this.delete(`${GamificationEndpoints.ActionLevels}/${params}`)

  getSingleAction = (params: number): Promise<IResponse<IAction>> =>
    this.get(`${GamificationEndpoints.Actions}/${params}`)

  editMethod = (params: IEditMethodRequest): Promise<IResponse<IMethod>> =>
    this.put(`${GamificationEndpoints.Methods}/${params.id}`, params.data)

  getLevelsCategoryById = (): Promise<IResponse<IGetLevelsByCategoryIdResponse>> =>
    this.get(`${GamificationEndpoints.LevelsByCategory}`)

  getCategories = (params?: IGetCategoriesRequest): Promise<IResponse<IGetCategoryResponse>> =>
    this.get(GamificationEndpoints.Categories, { params })

  deleteLevel = (params: IDeleteInfo): Promise<IResponse<ILevel>> =>
    this.delete(`${GamificationEndpoints.Levels}/${params.id}`)

  addLevel = (params: IAddLevelRequest): Promise<IResponse<ILevel>> =>
    this.post(`${GamificationEndpoints.Levels}`, params)

  editSingleLevel = (params: IAddLevelRequest): Promise<IResponse<ILevel>> =>
    this.put(`${GamificationEndpoints.Levels}/${params.levelId}`, {
      requiredXp: params.requiredXp,
      xpProportion: params.xpProportion,
    })

  getSingleLevel = (id: number): Promise<IResponse<ILevel>> => this.get(`${GamificationEndpoints.Levels}/${id}`)

  getSingleCategory = (): Promise<IResponse<ICategory>> => this.get(GamificationEndpoints.Categories)

  getGroupActionByGroup = (params?: IGetGroupActionRequest): Promise<IResponse<IGetGroupActionsByGroupResponse>> =>
    this.get(`${GamificationEndpoints.GroupActions}${GamificationEndpoints.Groups}`, { params })

  getGroupActionDetail = (params: number): Promise<IResponse<StaffGroupsDetail>> =>
    this.get(
      `${GamificationEndpoints.GroupActions}${GamificationEndpoints.Group}/${params}${GamificationEndpoints.Detail}`,
    )

  getGroupActionCategories = (): Promise<IResponse<IGroupActionCategoriesResponse>> =>
    this.get(`${GamificationEndpoints.GroupActions}${GamificationEndpoints.Categories}`)

  getStaffGroupActions = (params?: IGetStaffGroupActions): Promise<IResponse<IGetStaffGroupActionResponse>> =>
    this.get(`${GamificationEndpoints.GroupActions}`, { params })

  updateGroupActionByGroup = (params: IUpdateSelectIds): Promise<IResponse<IGetStaffGroupActionResponse>> =>
    this.put(`${GamificationEndpoints.GroupActions}${GamificationEndpoints.ByGroup}/${params.id}`, params.data)

  getActionGroups = (params: number): Promise<IResponse<GetGroupsByActionRes[]>> =>
    this.get(
      `${GamificationEndpoints.GroupActions}${GamificationEndpoints.ByAction}/${params}${GamificationEndpoints.Groups}`,
    )

  updateActionGroups = (params: IUpdateActionGroups): Promise<IResponse<IGroupInfo>> =>
    this.put(`${GamificationEndpoints.GroupActions}${GamificationEndpoints.ByAction}/${params.id}`, params.data)

  getStatistics = (params: IGetStatistics): Promise<IResponse<IStatisticsResponse>> =>
    this.get(`${GamificationEndpoints.StudentAwards}${GamificationEndpoints.Statistics}`, { params })

  getCourses = (params?: IGetCoursesParams): Promise<IResponse<IGetCoursesResponse>> =>
    this.get(`${GamificationEndpoints.StudentAwards}${GamificationEndpoints.Courses}`, { params })

  geStatuses = (): Promise<IResponse<IGetStatusesResponse>> =>
    this.get(`${GamificationEndpoints.Students}${GamificationEndpoints.FilterObjects}`)

  addStudentAwards = (params: IAddStudentAwards): Promise<IResponse<IStatistics>> =>
    this.post(`${GamificationEndpoints.StudentAwards}`, params)

  getStudentAwardFilterObjects = (params?: IGetStatusesParams): Promise<IResponse<IGetStatusesResponse>> =>
    this.get(`${GamificationEndpoints.StudentAwards}${GamificationEndpoints.FilterObjects}`, { params })

  getStudentAwards = (params: IGetStudentAwards): Promise<IResponse<IGetStudentAwardResponse>> => {
    const { studentId, ...data } = params

    return this.get(`${GamificationEndpoints.StudentAwards}/${studentId}`, { params: data })
  }

  getStudentAwardsMethods = (params: IGetStudentAwards): Promise<IResponse<IGetStatisticsMethodsResponse[]>> => {
    const { studentId, ...data } = params

    return this.get(`${GamificationEndpoints.StudentAwards}/${studentId}${GamificationEndpoints.Methods}`, {
      params: data,
    })
  }

  getStudentStatistics = (params: IGetStudentAwards): Promise<IResponse<IGetStudentStatistics>> => {
    const { studentId, ...data } = params

    return this.get(`${GamificationEndpoints.StudentAwards}/${studentId}${GamificationEndpoints.Statistic}`, {
      params: data,
    })
  }

  getSingleGroupAction = (params: number): Promise<IResponse<IGetSingleActionResponse>> =>
    this.get(`${GamificationEndpoints.Actions}/${params}`)

  getStudentCategories = (id: number): Promise<IResponse<IGetCategoryResponse>> =>
    this.get(`${GamificationEndpoints.Students}/${id}${GamificationEndpoints.Categories}`)

  getGroupAttendance = (params: GetGroupAttendanceReq): Promise<IResponse<GroupAttendanceRes[]>> =>
    this.get(`${GamificationEndpoints.StudentAwards}/${GamificationEndpoints.Attendance}`, { params })

  getStudentGroups = (params: IGetGroupRequest): Promise<IResponse<IGroup[]>> =>
    this.get(`${GamificationEndpoints.StudentAwards}${GamificationEndpoints.Groups}`, { params: params.data })

  editDeed = (params: IEditDeedRequest): Promise<IResponse<IMethod>> =>
    this.put(`${GamificationEndpoints.Actions}/${params.id}`, params.data)

  getStudentCategoriesOnly = (studentId: { studentId: number }): Promise<IResponse<IGetStudentCategoriesOnly>> =>
    this.get(
      `${GamificationEndpoints.StudentAwards}${GamificationEndpoints.FilterObjects}${GamificationEndpoints.Categories}`,
      { params: studentId },
    )

  editAwardActionLevel = (params: IAddActionLevelRequest): Promise<IResponse<IAction>> =>
    this.put(`${GamificationEndpoints.ActionLevels}/${params.actionId}`, { awards: params.awards })

  addParticipateLessonDeed = (params: IAddParticipateLessonDeed): Promise<IResponse<IAward>> =>
    this.post(`${GamificationEndpoints.ActionLevels}`, params)

  getGroupActions = (): Promise<IResponse<IGetActionsByMethodRes[]>> =>
    this.get(`${GamificationEndpoints.GroupActions}${GamificationEndpoints.Actions}`)

  getLevelXpProportion = (levelId: number): Promise<IResponse<ILevel>> =>
    this.get(`${GamificationEndpoints.Levels}${GamificationEndpoints.ByNumber}/${levelId}`)

  getCoursesByMethod = (params: IMethod | number): Promise<IResponse<TCourse[]>> =>
    this.get(`${GamificationEndpoints.Actions}/${params}${GamificationEndpoints.Courses}`)

  getLevelNumber = (params: number): Promise<IResponse<LevelInfo>> =>
    this.get(`${GamificationEndpoints.StudentAwardLevels}/${params}`)

  getStudentAwardGroups = (params: GetStudentGroupTypes): Promise<IResponse<StudentAwardGroups[]>> =>
    this.get(`${GamificationEndpoints.StudentAwards}${GamificationEndpoints.Groups}`, { params })

  getGroup = (params: number): Promise<IResponse<IGroup>> =>
    this.get(`${GamificationEndpoints.Groups}/${params}${GamificationEndpoints.Detail}`)

  getTheBestOnes = (params: GetTheBestOnesReq): Promise<IResponse<GetTheBestOnesRes>> =>
    this.get(`${GamificationEndpoints.BestStudents}`, { params })

  deleteBestStudent = (params: number): Promise<IResponse<GetTheBestOnesRes>> =>
    this.delete(`${GamificationEndpoints.BestStudents}/${params}`)

  getAllStudents = (params: GetAllGroupMembersReq): Promise<IResponse<GetAllGroupMembersRes>> =>
    this.get(`${GamificationEndpoints.BestStudents}${GamificationEndpoints.All}`, { params })

  addNomination = (params: AddNominationReq): Promise<IResponse<GetAllGroupMembersRes>> =>
    this.post(`${GamificationEndpoints.BestStudents}`, params)

  editNomination = (params: EditNominationReq): Promise<IResponse<GetAllGroupMembersRes>> =>
    this.put(`${GamificationEndpoints.BestStudents}/${params.id}`, params.data)
}

export const gamificationApi = new GamificationApi(config)
