import { observer } from "mobx-react"
import { Modal } from "antd"
import mainDictionary from "@/dictionary"
import { BranchOfficeAddEditForm } from "../Form/BranchOfficeAddEditForm"
import { branchOfficeStore } from "../shared/branch-office.store"

export const BranchOfficeAddModal = observer(() => {
  const { isOpenAddModal, setOpenAddModalStatus, setInActionBranchOffice, inActionBranchOffice } = branchOfficeStore
  const isEdit = !!inActionBranchOffice

  const handleModalClose = () => {
    setInActionBranchOffice(null)
    setOpenAddModalStatus(false)
  }

  return (
    <Modal
      open={isOpenAddModal}
      title={isEdit ? mainDictionary.editBranchOffice : mainDictionary.addBranchOffice}
      footer={null}
      onCancel={handleModalClose}
    >
      <BranchOfficeAddEditForm />
    </Modal>
  )
})
