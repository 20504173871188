import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const Roles = lazy(() =>
  import("./Roles").then(({ Roles }) => ({ default: Roles })).catch(handleCatchChunkError),
)

export const Audit = lazy(() =>
  import("./Audit").then(({ Audit }) => ({ default: Audit })).catch(handleCatchChunkError),
)

export const Users = lazy(() =>
  import("./Users").then(({ Users }) => ({ default: Users })).catch(handleCatchChunkError),
)

export const UserSingle = lazy(() =>
  import("./Users/UserSingle").then(({ UserSingle }) => ({ default: UserSingle })).catch(handleCatchChunkError),
)

export const FaceIdLogs = lazy(() =>
  import("./FaceIdLogs").then(({ FaceIdLogs }) => ({ default: FaceIdLogs })).catch(handleCatchChunkError),
)

export const FaceIdScreens = lazy(() =>
  import("./FaceIdScreens").then(({ FaceIdScreens }) => ({ default: FaceIdScreens })).catch(handleCatchChunkError),
)

export const BranchOfficePage = lazy(() =>
  import("./BranchOffice").then(({ BranchOffices }) => ({ default: BranchOffices })).catch(handleCatchChunkError),
)

export const FaceIdDevices = lazy(() =>
  import("./FaceIdDevices").then(({ FaceIdDevices }) => ({ default: FaceIdDevices })).catch(handleCatchChunkError),
)
