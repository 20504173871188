import { TPermission } from "@/api/controls"
import { Instance } from "@/api/instance"
import { IResponse } from "@/api/types"
import { ENDPOINTS } from "./constants"

class Api extends Instance {
  getMenus = (): Promise<IResponse<TPermission[]>> => this.get(ENDPOINTS.accessControlMenus)

  getPermissions = (): Promise<IResponse<TPermission[]>> => this.get(ENDPOINTS.permissionObjectsAll)
}

export const api = new Api()
