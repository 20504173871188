import { weekDays } from "@/pages/Groups/constants"

export const specialDictionary = {
  special: "Maxsus",
  outstandingPayments: "Qoldiq to‘lovlar",
  balancesPeriod: "Davr bo‘yicha qoldiqlar",
  paidTime: "To‘langan vaqt",
  export: "Eksport",
  allFilters: "Barcha filtrlar",
  group: "Guruh",
  payer: "To'lovchi",
  rest: "Qoldiq",
  additionalCharge: "Qo‘shimcha to‘lov",
  pricePerHour: "Bir soatlik narx",
  paid: "To‘langan",
  filtr: "Filtr",
  clearAll: "Barchasini tozalash",
  reportingMonth: "Hisobot oyi",
  branch: "Filial",
  choosedBranch: "Filialni tanlang",
  chooseCourse: "Kursni tanlang",
  teacher: "O‘qituvchi",
  chooseTeacher: "O‘qituvchini tanlang",
  additionalFilter: "Qo‘shimcha filtr",
  monday: "Du",
  tuesday: "Se",
  wednesday: "Ch",
  thursday: "Pa",
  friday: "Ju",
  saturday: "Sh",
  sunday: "Ya",
  monthStartClasses: "Darslar boshlanish oyi",
  monthEndClasses: "Darslar tugash oyi",
  whoAddedGroup: "Guruhga kim qo‘shdi",
  finalResidue: "Yakuniy qoldiq",
  basedCurrentLessons: "Amaldagi darslarga asosan",
  unpaidDuringReportingPeriod: "Hisobot davrida to‘lovsizlar",
  hideEmptyRows: "Mazmunsiz qatorlarni yashirish",
}
