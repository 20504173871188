export enum NotificationTabs {
  PUBLIC_STAFF = "1",
  PUBLIC_STUDENTS = "2",
  PRIVATE = "3",
  DELETED = "4",
  TEMPLATE = "5",
}

export enum UsersRoleTypes {
  STAFF = 1,
  ACADEMIC = 2,
  STUDENT = 3,
}
