import { observer } from "mobx-react"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import DOMPurify from "dompurify"
import Logo from "@/assets/img/logo.png"
import { useStores } from "@/stores"
import { formatDate, makeFileUrl } from "@/utils"

import styles from "./single-notification.module.scss"

const cx = classNames.bind(styles)

type Props = {
  banner?: string
  body: string
  title?: string
  createdAt?: string
}

export const SingleNotification = observer(({ title, body, banner, createdAt }: Props) => {
  const { notificationStore } = useStores()

  const handleBtnBackClick = () => {
    notificationStore.setSingleNotificationId(null)
  }

  return (
    <Flex vertical gap={20} className={cx("single-notification")}>
      <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleBtnBackClick} />
      <Flex justify="center">
        {banner ? (
          <img className={cx("single-notification__image")} src={makeFileUrl(banner)} alt={title} />
        ) : (
          <img className={cx("single-notification__image-logo")} src={Logo} alt="Logo" />
        )}
      </Flex>

      <Flex vertical>
        <Typography.Title className={cx("single-notification__text")} level={4}>
          {title}
        </Typography.Title>

        <Typography.Text className={cx("single-notification__text")} type="secondary">
          {formatDate(createdAt, undefined, true)}
        </Typography.Text>
      </Flex>

      <h4 className={cx("single-notification__text")} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
    </Flex>
  )
})
