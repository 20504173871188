import { CallReasonTypes } from "@/pages/Management/AutoCall/types"

export enum UnprocessedTaskTypes {
  GroupAttendance = 1,
  CreateHomework,
}

export const enum NotComingToLessonReasons {
  COMING = 1,
  NOT_COMING_WITH_EXCUSE,
  NOT_COMING_WITHOUT_EXCUSE,
}

export interface IGetReportsTypes {
  page?: number
  perPage?: number
  key?: string
  reportType?: UnprocessedTaskTypes | string
  officeIds?: number[]
}

export type GetCallHistory = {
  page?: number
  perPage?: number
  callReasonType?: CallReasonTypes | string
  key?: string
  isCompleted?: boolean | null
  startDate?: string
  entDate?: string
  callResponseType?: NotComingToLessonReasons
}
