import { ValueOfPermissionObjectTypes } from "@/modules/permission"
import { useStores } from "@/stores"

interface IProps {
  option: string
  module: ValueOfPermissionObjectTypes
}

export const useCheckPermission = ({ option }: IProps) => {
  // will delete module
  const { permissionsStore } = useStores()

  const permissions = permissionsStore.permissions?.map((item) => item.name)

  // should check permission not CRUD option
  // return [permissions?.includes(option)]

  return [true]
}
