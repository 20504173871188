import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { RevenueAndPayments } from "./lazy"

export const financeRouters = [
  {
    path: ROUTES.finance,
    children: [
      {
        path: ROUTES.revenueAndPayments,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.finance}>
            <RevenueAndPayments />
          </AvailableComponent>
        ),
      },
    ],
  },
]
