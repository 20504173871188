import { makeAutoObservable, runInAction } from "mobx"
import { paymentsApi } from "@/api/payments"
import { addCatchNotification } from "@/modules/notifications"
import { PaymentsRes, RevenueAndPaymentsReqParams } from "@/types"

class FinanceStore {
  revenueAndPayments: PaymentsRes | null = null
  revenueAndPaymentsPage = 1
  revenueAndPaymentsPerPage = 10
  filterOptions: RevenueAndPaymentsReqParams = {
    page: 1,
    perPage: 10,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setPage = (value: number) => {
    this.revenueAndPaymentsPage = value
  }

  setPerPage = (value: number) => {
    this.revenueAndPaymentsPerPage = value
  }

  setFilterOption = (filterOptions: RevenueAndPaymentsReqParams) => {
    this.filterOptions = filterOptions
  }

  setRevenueAndPayments = (params: PaymentsRes | null) => {
    this.revenueAndPayments = params
  }

  getRevenueAndPayments = (params: RevenueAndPaymentsReqParams) =>
    paymentsApi
      .getRevenueAndPayments(params)
      .then((response) => {
        if (response.success && response.data) {
          runInAction(() => {
            this.setRevenueAndPayments(response.data)
          })
        }
      })
      .catch(addCatchNotification)

  reset = () => {
    this.revenueAndPayments = null
    this.revenueAndPaymentsPage = 1
    this.revenueAndPaymentsPerPage = 10
    this.filterOptions = {
      page: 1,
      perPage: 10,
    }
  }
}

export const financeStore = new FinanceStore()
