import { GetStudentsByGroupRes, HomeworkCreatedByTypes } from "@/api/groups"
import { IUpload } from "@/api/upload"

export interface IHomeWorkAdd {
  deadline: any
  desc: string
  lessonId: string
  time: any
  createdByType?: HomeworkCreatedByTypes
  file: IUpload[]
  homeworkDbLessonIds?: number[]
}

export enum HomeworkChatsStatus {
  WAITING = 1,
  REJECTED = 2,
  ACCEPTED = 3,
  NotDone,
  NotGiven,
}

export enum ExamChatsStatus {
  WAITING = 1,
  REJECTED = 2,
  ACCEPTED = 3,
  NotDone,
  NotGiven,
}

export interface IHomeworkCheckForm {
  message: string
  score: number
}

export const GroupPageState = {
  Attendance: "1",
  Videos: "2",
  Homework: "3",
  Exam: "4",
  Info: "5",
  Journal: "6",
  TeacherAttendance: "7",
  GroupInfo: "8",
  Students: "9",
  GroupLessons: "10",
  ActionsArchive: "11",
}
export interface IAddExamForm {
  examDate: any
  studyMonthId: string
  examName: string
}

export enum TableOrders {
  Descend = "descend",
  Ascend = "ascend",
}

export enum HomeworkTypes {
  Daily = 1,
  Optional,
  ThirtySixHours,
}

export enum HomeworkStatistics {
  AverageScore = "averageScore",
  RejectedHomeworkCount = "rejectedHomeworkCount",
  UncompletedHomeworkCount = "uncompletedHomeworkCount",
  AcceptedHomeworkCount = "acceptedHomeworkCount",
  WaitingHomework = "waitingHomeworkCount",
}

export enum HomeworkDeadlineTypes {
  OneDay = 1,
  Custom,
  ThirtySixHours,
  FortyEightHours,
}

export enum AttendanceType {
  Custom = 1,
  FromStudyPlan = 2,
}

export interface IStudentSelectOption {
  disabled: boolean
  value: number
  student: GetStudentsByGroupRes
  label: string
}

export interface IAddStudentFormValues {
  studentId: number
  lessonStart: any
  endDate: any
  price: number
  comment: string
}

export interface IFinishGroupFormValues {
  endDate: string
  reason: string
  negative: string[]
  comment: string
  lessonStart?: string
}

export interface IFinishGroup {
  id?: string
  values: IFinishGroupFormValues
}

export enum FinalResults {
  Homework = "homework",
  Attendance = "attendance",
}

export const enum StudentExamErrorTypes {
  ClickUpError = 1,
  HhError,
}

export type TOption = {
  label: string
  value: number | null
}

export type TAttachment = {
  url: string
  uid: string
  size: number
  origName: string
  status?: string
}

export enum GroupColumns {
  Groups = 1,
  Branch,
  Course,
  Category,
  Type,
  Schedule,
  Teachers,
  Room,
  Description,
  Debtor,
  ResponsiblePerson,
  GroupStatus,
  AdditionalSpace,
  GroupCapacity,
  Students,
}

export enum GroupSingleTabs {
  Info = 1,
  Students,
  GroupLessons,
  ActionsArchive,
  Attendance,
  Videos,
  Homework,
  Exams,
  GroupInfo,
  Journal,
  TeacherAttendance,
}
