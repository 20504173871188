export const studyDepartmentDictionary = {
  homeworks: "Uyga vazifalar",
  typeOfStudy: "O`quv turi",
  endOfDate: "Tuga(ydi)gan sana",
  byChecking: "Tekshirilishi bo`yicha",
  byDone: "Bajarilishi bo`yicha",
  byStudyMonth: "O`quv oyi bo`yicha",
  byTimeMonth: "Vaqt oralig`i bo`yicha",
  hash: "#",
  checked: "Tekshirilgan",
  studentNotGiven: "Berilmagan",
  notChecked: "Tekshirilmagan",
  hasEdited: "Tahrirlangan",
  noInformation: "Ma`lumot yo`q",
  disiplineOfHH: "HH disiplinasi",
  studyMonth: "O`quv oyi",
  iStudyPlan: "O`quv rejasi",
  courseAdd: "Kurs qo`shish",
  selectHolyHopeDicipline: "HH dan disiplina tanlang",
  enterCourseName: "Kurs nomini kiriting",
  lessonsCount: "Bir o`quv oyidagi darslar soni: ",
  academicRolesInStudyMonth: "O‘quv oyi uchun javobgar akademik rollar",
  journalsRoles: "Jurnal statistikasi hisoblanadigan akademik rollarni tanlang: ",
  enterLessonsCount: "O`quv oyidagi darslar sonini kiriting",
  selectAcademicRole: "O`quv oyi hisoblanadigan akademik rollarni tanlang",
  journalStatisticStaff: "Jurnal statistikasi hisoblanadigan akademik rollarni tanlang",
  reOrder: "Qayta tartiblash",
  addTopic: "Mavzu qo`shish",
  dragDrop: "D&D",
  allLessonsCount: "Kursning jami darslari soni: ",
  courseSuccessfullyEdited: "Kurs muvaffaqiyatli o`zgartirildi",
  sureDeleteCourse: "Siz rostdan ham ushbu kursni o`chirmoqchimisiz?",
  courseSuccessfullyDeleted: "Kurs muvaffaqiyatli o`chirildi",
  edited: "Muvaffaqiyatli o`zgartirildi",
  addSuccessfullyCurriculum: "Mavzu muvaffaqiyatli qo`shildi",
  sureToDeleteCurriculum: "Mavzuni rostdan ham o`chirmoqchimisiz?",
  successfullyDeletedCurriculum: "Mavzu mufavvaqiyatli o`chirildi",
  notNowGivenHomework: "Bu mavzuga uyga vazifa berilmagan",
  position: "Pozitsiya",
  notAllowed: "Bu sahifaga kirish uchun sizda ruxsat yo`q!",
  required: "Qiymat kiritish shart",
  minLength: "O`quv oyidagi darslar soni 1 dan kam bo`lmasligi kerak",
  choose: "Tanlang",
  checkedTime: "Tekshirilgan vaqti",
  addToGroup: "Guruhga qo`shish",
  courseLessonsCount: "Kursning jami darslari soni",
  requiredHomework: "Majburiy",
  notRequired: "Majburiy emas",
  homeworkRequirements: "Uy vazifalari tekshirilishi",
  chapters: "Mavzular",
  addChapter: "Mavzu qo`shish",
  editChapter: "Mavzu o`zgartirish",
  requiredField: "To`ldirish shart",
  easy: "Oson",
  medium: "O`rta",
  hard: "Qiyin",
  connectHomeworkToTheme: "Mavzuga uyga vazifa biriktirish",
  easyTopicsCount: "Oson savollar soni",
  mediumTopicsCount: "O`rtacha savollar soni",
  hardTopicsCount: "Qiyin savollar soni",
  period: "Muddat",
  teachersLatency: "Ustozlarning kechga qolishi",
  lessonRating: "Dars retingi",
  sureToConfirmThisHomework: "Ushbu vazifani asosiy bazaga qabul qilmoqchimisiz?",
  homeworkTopic: "Vazifa mavzusi",
  studentWhoDidNotCompleteHomework: "Vazifa bajarmayotganlar",
  skillClasses: "Mahorat darslari",
  pedagogic: "Pedagogik",
  technic: "Texnik",
  sizeVideo: "Video hajmi",
  addVideo: "Video qo`shish",
  videoFormat: ".mp4, .webm, .mpeg, .avi, .mkv, .m4v, .ogm, .mov, .mpg",
  videoFormatRequired: "formatlarida bo`lishi kerak.",
  video: "Video",
  videoFormatError: "Video formatida xatolik bor",
  downloadedVideos: (count: number) => `Yuklangan fayllar ${count} ta`,
  videoDownloadTitle: "Yuklash uchun bosing yoki faylni bu yerga torting",
  fileName: "Fayl nomi",
  emptyVideoNotificationMessage: "Video yuklanmagan",
  sureDeleteVideo: "Siz rostdan ham ushbu video ni o`chirmoqchimisiz?",
}
