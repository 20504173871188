import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const NotificationsSended = lazy(() =>
  import("./Sended").then(({ Sended }) => ({ default: Sended })).catch(handleCatchChunkError),
)

export const NotificationStudentPrivate = lazy(() =>
  import("./Sended/Private/Create").then(({ Create }) => ({ default: Create })).catch(handleCatchChunkError),
)

export const StaffAndStudentPublic = lazy(() =>
  import("./Sended/PublicStaff/Create").then(({ Create }) => ({ default: Create })).catch(handleCatchChunkError),
)

export const PublicStudentNotificationCreate = lazy(() =>
  import("./Sended/PublicStudents/Create")
    .then(({ PublicStudentCreate }) => ({ default: PublicStudentCreate }))
    .catch(handleCatchChunkError),
)

export const NotificationSingle = lazy(() =>
  import("./Sended/SingleNotification")
    .then(({ SingleNotificationForAdmin }) => ({ default: SingleNotificationForAdmin }))
    .catch(handleCatchChunkError),
)

export const NotificationsTemplates = lazy(() =>
  import("./Templates").then(({ Templates }) => ({ default: Templates })).catch(handleCatchChunkError),
)

export const MyNotification = lazy(() =>
  import("./MyNotification").then(({ MyNotification }) => ({ default: MyNotification })).catch(handleCatchChunkError),
)

export const MyNotificationSingle = lazy(() =>
  import("@/modules/SingleNotification")
    .then(({ SingleNotification }) => ({ default: SingleNotification }))
    .catch(handleCatchChunkError),
)

export const Message = lazy(() =>
  import("./MyNotification/Message").then(({ Message }) => ({ default: Message })).catch(handleCatchChunkError),
)

export const TemplateCreate = lazy(() => import("./Templates/Create").then(({ Create }) => ({ default: Create })))
