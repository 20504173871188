import { ScheduleItem, StaffGroupDetail } from "@/api/groups"
import { BranchOffice } from "@/pages/Controls/BranchOffice/types"
import { GenderTypes } from "@/pages/Controls/types"
import { AcStatuses } from "@/pages/Users/Students/types"
import { IPagination } from "../types"

export interface IAcademicData {
  acStatus?: AcStatuses
  [key: string]: any
  id: number
  photo: string
  firstName: string
  lastName: string
  role: AcademicRole
  login: string
  workStatus: number
  isActive: boolean
  hCreatedAt: string
  accountStatus: number
  genderType: number
  phone: string
  offices: BranchOffice[]
}

interface AcademicRole {
  author: any
  createdAt: string
  id: number
  name: string
  type: number
}

export interface IAcademicRequiest extends IPagination {
  keys?: string[]
  value?: string
  sortBy?: string
  orderBy?: string
  isActive?: boolean | null
  workStatus?: number | null
  roleId?: number | null
  officeIds?: number[]
  staffSystemStatus?: number | null
  categoryId?: number | null
}

export interface IAcademicResponse {
  total: number
  academics: IAcademicData[]
}

export enum AcademicAccountStatus {
  Active = 1,
  InActive = 2,
  HasNotAccount = 3,
}

export enum AcademicWorkStatus {
  Working = 1,
  NotWorking = 2,
}

export interface IAcademicRolesParams extends IPagination {
  keys?: string[]
  value?: string
  orderBy?: string
  sortBy?: string
  type?: number
}

export interface IAcademicRoles {
  roles: AcademicRolesResponse[]
  total: number
}

export interface AcademicRolesResponse {
  id: number
  name: string
  createdAt: string
  author: any | null
  staffCount: number
  type: number
}

export interface IBlockAcademicRequest {
  id: number
  login: string
  isActive: boolean
  roleId: number
  genderType?: GenderTypes
}

export interface ISuccessResponse {
  success: boolean
}

export interface ISingleAcademicparams {
  id: number
  data?: {
    kpiDate: string
  }
}

export interface ResetPassword {
  password: string
}

export interface IAcademicGroup {
  id: number
  name: string
  beginTime: string
  endTime: string
  studentsCount: number
  status: number
  staff: any
  staffDetail?: StaffGroupDetail[]
  activeScheduleItems: ScheduleItem[]
  othersScheduleItems: ScheduleItem[]
}

export interface IAcademicGroupsResponse {
  staffGroups: IAcademicGroup[]
}

export interface IGroupAssistants extends IAcademicData {
  canAttendance: boolean
}

export interface IGroupAcademics {
  teacher: IAcademicData
  assistants: IGroupAssistants[]
}

export interface IAcademicProfileAdd {
  id: number
  login: string
  roleId: AcademicRolesResponse | number
  genderType: number
}
