import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { AudienceAvailability, TeacherAvailability } from "./lazy"

export const analyticsRouter = [
  {
    path: ROUTES.analytics,
    children: [
      {
        path: ROUTES.analyticsAudienceAvailability,
        index: true,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.profile}>
            <AudienceAvailability />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.analyticsTeachersAvailability,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.profile}>
            <TeacherAvailability />
          </AvailableComponent>
        ),
      },
    ],
  },
]
