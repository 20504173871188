export const notificationsDictionary = {
  notifications: "Bildirishnomalar",
  notificationType: "Bildirishma turlari",
  publicStaff: "Ommaviy Xodim va Akademiklar",
  publicStudents: "Ommaviy o`quvchilar",
  private: "Shaxsiy",
  public: "Ommaviy",
  deleted: "O`chirilganlar",
  sortByType: "Turi bo`yicha",
  title: "Sarlavha",
  userType: "Foydalanuvchi turi",
  userRole: "Foydalanuvchi roli",
  readCount: "O`qiganlar soni",
  sendedTime: "Jo`natilgan vaqt",
  staffAcademicPublicNotificationSending: "Staff va Akademiklarga Ommaviy Bildirishnoma jo`natish",
  doYouSaveAsTemplate: "Shablon sifatida saqlaysizmi?",
  privateSending: "Shaxsiy bildirishnomalar jo`natish",
  office: "Fillial",
  selectUser: "Foydalanuvchini tanlang",
  sendingLetterPublicToStudents: "O`quvchilarga ommaviy xabar jo`natish",
  createTemplate: "Shablon yaratish",
  templateName: "Shablon nomi",
  successFullyAdded: "Muvaffaqiyatli qo`shildi",
  successFullySaved: "Muvaffaqiyattli saqlandi",
  sureToDelete: "Bildirishnoma o’chirilgandan keyin, foydalanuvchiga ko’rinmaydi",
  notFound: "Ma`lumot topilmadi",
  readAll: "Barchasini o`qish",
  notificationSettings: "Bildirishnoma sozlamalari",
  examStudent: "Imithon",
  announceExamStudent: "Imithon e’loni",
  deadlineExamStudent: "Imtihon vazifa muddati yaqin qolsa",
  homeworkExamStudent: "Uy vazifa",
  checkHomeworkStudent: "Uy vazifa tekshirilganda",
  deadlineHomeworkStudent: "Uy vazifa muddati yaqin qolsa",
  addGroupStudent: "Guruhga qo’shilganlik",
  stopGroupStudent: "Guruhda o’qishni to’xtatganlik",
  awardStudent: "XP, Kumush taqdim etilganda",
  attendanceStaff: "Yo’qlama haqida",
  homeworkStaff: "Vazifa berishi haqida",
  checkHomeworkStaff: "Vazifa bajarilgani haqida",
  examStaff: "Imtihon haqida eslatma",
  createExamStaff: "Imtihon olinishi haqida",
  examHomeworkStaff: "Imtihon uchun Vazifa berishi haqida",
  checkExamStaff: "Imtihon vazifasi bajarilgani haqida",
  addGroupStaff: "Guruhga biriktirilganda",
  academicRemindAssignHomework: "Uyga vazifa biriktirilganda",
  academicHomeworkCompletionAlert: "Uyga vazifa bajarilganda",
  academicExam: "Imtihon",
  academicExamCompletionAlert: "Imtihon topshirilganda",
  academicGroup: "Guruhga biriktirilganda",
  markAsRead: "O’qilgan deb belgilang",
}
