export enum ControlsEndpoints {
  Roles = "/roles",
  PermissionObjectsAll = "/permission-objects/all",
  Users = "/users",
  ByRole = "by-role",
  UsersCreate = "/users/create",
  Update = "update",
  ResetPassword = "reset-password",
  Detail = "/detail",
  Devices = "/devices",
  FaceIdScreens = "/face-control/face-screens",
  FaceControl = "/face-control",
  GetLogImage = "/get-log-image",
  FaceControlLastScreenInDevice = "/face-control/last-screen-in-device",
  OpenDoor = "/open-door",
  All = "/all",
}
