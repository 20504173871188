import { observer } from "mobx-react"
import { Flex, Typography } from "antd"
import classNames from "classnames/bind"

import styles from "./calendar-day.module.scss"

const cn = classNames.bind(styles)

type Props = {
  category?: "missed" | "came" | "schedule"
  week?: string
  day?: string
}

export const CalendarDay = observer(({ category, day, week }: Props) => {
  console.log("")

  return (
    <Flex className={cn("calendar-day", category)} vertical gap={4}>
      <Typography.Text className={cn("calendar-day__week")}>{week || "Se"}</Typography.Text>
      <Typography.Text className={cn("calendar-day__day")}>{day || "15"}</Typography.Text>
    </Flex>
  )
})
