export const enum EventStatuses {
  Scheduled = 1,
  Ended,
}

export const enum EventTypes {
  Competition = 1,
  MasterClass,
}

export const enum EventParticipationTypes {
  Single = 1,
  Team,
}
export const enum EventMemberTypes {
  Owner = 1,
  Member,
  Requested,
}
export const enum EventParticipantStatuses {
  Signed = 1,
  Came,
  Banned,
  SignedAndCame,
}

type Device = {
  id: number
  name?: string
  eventStartDate?: string
  eventEndDate?: string
}

type TotalType = {
  id: number
  name: string
}

export type MasterClass = {
  categories: string[]
  awardedXp: number
  courses: string[]
  offices: string[]
  coinBeGiven?: boolean
  devices: Device[]
  id: number
  title: string
  participantsCount: number
  eventDate: string
  banner: string
  desc: string
  requiredXp: number
  participationLimit?: number
  participationType: EventParticipationTypes
  eventTeamParticipationLimit?: number
}

export type ModifiedMasterClass = Omit<MasterClass, "categories" | "courses" | "offices"> & {
  categories: TotalType[]
  courses: TotalType[]
  offices: TotalType[]
}

export type GetMasterClassesRes = {
  total: number
  masterClasses: MasterClass[]
}

export type GetCompetitionRes = {
  total: number
  competitions: MasterClass[]
}

export type Participant = {
  id: number
  firstName: string
  lastName: string
  capturedDate: string
  hId: number
  xp: number
  awardedXp: number
  awardedCoin: number
  participantStatus: EventParticipantStatuses
  memberType: EventMemberTypes
  level: string
  comment: string
  createdAt: string
}

export type GetMasterClassParticipantsRes = {
  total: number
  eventTeamData: {
    participants: Participant[]
    team: any
  }
}

export type EventTeams = {
  id: number
  name: string
  createdAt: string
  membersCount: number
  awardedXp: number
}

export type GetEventTeamsRes = {
  eventTeamData: EventTeams[]
}
