import { observer } from "mobx-react"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from "@/modules/permission"
import { BranchOffice, branchOfficeStore } from "@/pages/Controls/BranchOffice"

type Props = {
  branchOffice: BranchOffice
}

export const BranchOfficeGridActionButtons = observer(({ branchOffice }: Props) => {
  const { setInActionBranchOffice, setOpenAddModalStatus, setOpenDeleteModalStatus } = branchOfficeStore
  const [isAvailableUpdate] = useCheckPermission({
    module: permissionObjectTypes.staffControlBranchOffice,
    option: PermissionOptions.Update,
  })

  const [isAvailableDelete] = useCheckPermission({
    module: permissionObjectTypes.staffControlBranchOffice,
    option: PermissionOptions.Delete,
  })

  const handleEditButtonClick = () => {
    setInActionBranchOffice(branchOffice)
    setOpenAddModalStatus(true)
  }

  const handleDeleteButtonClick = () => {
    setInActionBranchOffice(branchOffice)
    setOpenDeleteModalStatus(true)
  }

  return (
    <Space>
      {isAvailableUpdate && <Button type="text" icon={<EditOutlined />} onClick={handleEditButtonClick} />}
      {isAvailableDelete && <Button type="text" danger icon={<DeleteOutlined />} onClick={handleDeleteButtonClick} />}
    </Space>
  )
})
