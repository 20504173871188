import { makeAutoObservable } from "mobx"
import { academicApi, IAcademicRolesParams } from "@/api/academic"
import {
  GetAllMyNotificationRes,
  Notification,
  NotificationRes,
  notificationsApi,
  Template,
  TemplateRes,
} from "@/api/notifications"
import { IStaffParams, staffApi } from "@/api/staff"
import { Category, FilterResponse, StatusResponse, studentApi, StudentParams } from "@/api/students"
import { addCatchNotification } from "@/modules/notifications"
import { allOption } from "@/pages/Groups/constants"
import { NotificationTabs } from "@/pages/Notifications/Sended/types"
import { NotificationSettingsType } from "@/types"
import { makeFileUrl } from "@/utils"
import {
  FilterObjectParams,
  GetAcademicsParams,
  GetAllNotificationsParams,
  GetNotificationParams,
  NotificationBody,
  ReadMyNotifications,
  SelectOptions,
  TemplateBody,
  TemplateEditBody,
  TemplateRequest,
  Users,
} from "./types"

class NotificationStore {
  notifications: NotificationRes | null = null
  roles: SelectOptions[] | null = null
  notificationFilter: GetNotificationParams | null = null
  loader = false
  notificationTabs: NotificationTabs = NotificationTabs.PUBLIC_STAFF
  singleNotification: Notification | null = null
  templates: TemplateRes | null = null
  templatesLoader = false
  templateFilter: TemplateRequest | null = null
  selectedTemplate: Template | null = null
  users: Users[] | null = null
  filterOffices: FilterResponse[] | null = null
  filterGroups: FilterResponse[] | null = null
  filterCourses: FilterResponse[] | null = null
  categories: Category[] = []
  studentStatus: StatusResponse[] | [] = []
  filterObjectsParams: FilterObjectParams | null = null
  filterObjectsLoader = false
  myNotifications: GetAllMyNotificationRes | null = null
  myNotificationLoader = false
  myNotificationsPage = 1
  isNotificationSettings = false
  isDrawerOpen = false
  notificationSettings: NotificationSettingsType[] = []
  checkedNotificationSettings: number[] = []
  branchModal = false
  selectedNotification: Notification | null = null
  categoryModal = false
  courseModal = false
  groupModal = false
  singleNotificationId: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setSingleNotificationId = (params: number | null) => {
    this.singleNotificationId = params
  }

  setGroupModal = (params: boolean) => {
    this.groupModal = params
  }

  setCourseModal = (params: boolean) => {
    this.courseModal = params
  }

  setCategoryModal = (params: boolean) => {
    this.categoryModal = params
  }

  setSelectedNotification = (params: Notification | null) => {
    this.selectedNotification = params
  }

  setBranchModal = (params: boolean) => {
    this.branchModal = params
  }

  setNotificationSettings = (settings: NotificationSettingsType[]) => {
    this.notificationSettings = settings
  }

  setConfigureNotifications = (value: boolean) => {
    this.isNotificationSettings = value
  }

  setIsDrawerOpen = (value: boolean) => {
    this.isDrawerOpen = value
  }

  setMyNotificationsPage = (params: number) => {
    this.myNotificationsPage = params
  }

  setMyNotificationLoader = (params: boolean) => {
    this.myNotificationLoader = params
  }

  setMyNotifications = (params: GetAllMyNotificationRes | null) => {
    this.myNotifications = params
  }

  setFilterObjectsLoader = (params: boolean) => {
    this.filterObjectsLoader = params
  }

  setFilterObjectsParams = (params: FilterObjectParams | null) => {
    this.filterObjectsParams = params
  }

  setUsers = (params: Users[] | null) => {
    this.users = params
  }

  setSelectedTemplate = (params: Template | null) => {
    this.selectedTemplate = params
  }

  setSelectedFilter = (params: TemplateRequest | null) => {
    this.templateFilter = params
  }

  setTemplateFilter = (params: TemplateRequest | null) => {
    this.templateFilter = params
  }

  setTemplatesLoader = (params: boolean) => {
    this.templatesLoader = params
  }

  setTemplates = (params: TemplateRes | null) => {
    this.templates = params
  }

  setNotification = (params: Notification | null) => {
    this.singleNotification = params
  }

  setNotificationTabs = (params: NotificationTabs) => {
    this.notificationTabs = params
  }

  setLoader = (params: boolean) => {
    this.loader = params
  }

  setNotificationFilter = (params: GetNotificationParams | null) => {
    this.notificationFilter = params
  }

  setRoles = (params: SelectOptions[] | null) => {
    this.roles = [allOption, ...(params || [])]
  }

  setNotifications = (params: NotificationRes | null) => {
    this.notifications = params
  }

  setCheckedNotifications = (value: number[]) => {
    this.checkedNotificationSettings = value
  }

  getNotificationSettings = () =>
    notificationsApi
      .getNotificationSettings()
      .then((response) => {
        if (response.success && response.data) {
          this.setNotificationSettings(response.data.data)
          this.setCheckedNotifications(response.data.data.filter((data) => data.isEnable).map((item) => item.id))
        }
      })
      .catch(addCatchNotification)

  getNotification = (params: number) =>
    notificationsApi
      .getSingleNotification(params)
      .then((res) => {
        if (res.success) {
          this.setNotification(res.data)
        }
      })
      .catch(addCatchNotification)

  getAcademicNotification = (params: number) =>
    notificationsApi
      .getAcademicNotification(params)
      .then((res) => {
        if (res.success) {
          this.setNotification(res.data)
        }
      })
      .catch(addCatchNotification)

  readNotification = (params?: number, data?: ReadMyNotifications) => notificationsApi.readNotification(params, data)

  setFilterOffices = (params: FilterResponse[] | null) => {
    this.filterOffices = params
  }

  setFilterCourses = (params: FilterResponse[] | null) => {
    this.filterCourses = params
  }

  setFilterGroups = (params: FilterResponse[] | null) => {
    this.filterGroups = params
  }

  setCategories = (params: Category[]) => {
    this.categories = params
  }

  setStatus = (params: StatusResponse[]) => {
    this.studentStatus = params
  }

  getCategories = (params?: FilterObjectParams) =>
    studentApi
      .getCategory(params)
      .then((res) => {
        if (res.success) {
          this.setCategories(res.data.categories)
          this.setStatus(res.data.statuses)
          this.setFilterGroups(res.data?.groups || null)
          this.setFilterOffices(res.data?.offices || null)
          this.setFilterCourses(res.data?.courses || null)
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setFilterObjectsLoader(false)
      })

  getAllNotifications = (params: GetAllNotificationsParams) =>
    notificationsApi
      .getMyNotifications(params)
      .then((res) => {
        if (res.success) {
          this.setMyNotifications(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setMyNotificationLoader(false))

  getNotifications = (params: GetNotificationParams) =>
    notificationsApi
      .getNotifications(params)
      .then((res) => {
        if (res.success) {
          this.setNotifications(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setLoader(false)
      })

  getStudents = (params: StudentParams) =>
    studentApi
      .getStudents(params)
      .then((res) => {
        if (res.success) {
          const students = res.data?.students?.map((item) => ({
            userId: item.userId,
            name: `${item.firstName} ${item.lastName}`,
            photo: item?.photo ? makeFileUrl(item.photo) : "",
          }))

          this.setUsers(students || [])
        }
      })
      .catch(addCatchNotification)

  getStaff = (params: IStaffParams) =>
    staffApi
      .getStaff(params)
      .then((res) => {
        if (res.success) {
          const staffs = res?.data?.staff?.map((item) => ({
            userId: item.userId,
            name: `${item.firstName} ${item.lastName}`,
            photo: item?.photo ? makeFileUrl(item.photo) : "",
          }))

          this.setUsers(staffs)
        }
      })
      .catch(addCatchNotification)

  getAcademics = (params: GetAcademicsParams) =>
    staffApi
      .getAcademics(params)
      .then((res) => {
        if (res.success) {
          const academics = res?.data?.academics?.map((item) => ({
            userId: item.userId,
            name: `${item.firstName} ${item.lastName}`,
            photo: item?.photo ? makeFileUrl(item.photo) : "",
          }))

          this.setUsers(academics)
        }
      })
      .catch(addCatchNotification)

  addNotification = (params: NotificationBody) => notificationsApi.addNotification(params)

  deleteNotification = (params: number) => notificationsApi.deleteNotification(params)

  addTemplate = (params: TemplateBody) => notificationsApi.addTemplate(params)

  editTemplate = (params: TemplateEditBody) => notificationsApi.editTemplate(params)

  deleteTemplate = (params: number) => notificationsApi.deleteTemplate(params)

  getSingleTemplate = (params: number) =>
    notificationsApi
      .getSingleTemplate(params)
      .then((res) => {
        if (res.success) {
          this.setSelectedTemplate(res.data || null)
        }
      })
      .catch(addCatchNotification)

  getRoles = (params: IAcademicRolesParams) =>
    academicApi
      .getTeacherRoles(params)
      .then((res) => {
        if (res.success) {
          const roles = res?.data?.roles?.map((item) => ({
            label: item.name,
            value: item.id,
          }))

          this.setRoles(roles || [])
        }
      })
      .catch(addCatchNotification)

  getTemplates = (params: TemplateRequest) =>
    notificationsApi
      .getTemplates(params)
      .then((res) => {
        if (res.success) {
          this.setTemplates(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setTemplatesLoader(false)
      })
}

export const notificationStore = new NotificationStore()
