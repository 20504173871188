import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import classNames from "classnames/bind"
import { IGroup } from "@/api/groups"
import { StoreTitle, Table } from "@/components/Table"
import { ROUTES } from "@/constants"
import { getPaginationParams } from "@/pages/utils"
import { getPerPages } from "@/shared"
import { useStores } from "@/stores"
import { getPreviousDate } from "@/utils/date"
import { formattedColumns } from "./util"

import styles from "./table.module.scss"

const cn = classNames.bind(styles)

interface ITableProps {
  isMyGroup?: boolean
}

export const GroupTable = observer(({ isMyGroup }: ITableProps) => {
  const navigate = useNavigate()
  const { groupsStore, appStore } = useStores()
  const perPageFromLocalStorage = getPerPages()
  const [selectedKey, setSelectedKey] = useState<number | null>(null)

  const handleTablePaginationChange = (value: number, perPage: number) => {
    const previousDate = getPreviousDate()

    const defaultValue = groupsStore?.myGroupFilter?.kpiDate || previousDate

    const info = {
      ...groupsStore.filterOptions,
      page: value,
      perPage,
    }

    const myGroupInfo = {
      ...groupsStore.myGroupFilter,
      page: value,
      id: appStore.profile?.id,
      status: groupsStore.myGroupStatus,
      kpiDate: groupsStore.myGroupFilter?.kpiDate || defaultValue,
      perPage,
    }

    groupsStore.setFilterOption(info)
    if (isMyGroup) {
      groupsStore.getAcademicGroups(myGroupInfo)

      return
    }
    groupsStore.getGroups(info)
  }

  const handleOpenModal = (item: IGroup) => {
    const schedules = {
      activeScheduleItems: item?.activeScheduleItems,
      othersScheduleItems: item?.othersScheduleItems,
    }

    groupsStore.setGroupTeachers(schedules)
    groupsStore.setAssistantsModal(true)
  }

  const handleShowSizeChange = (page: number, perPage: number) => {
    groupsStore.setFilterOption({
      ...groupsStore.filterOptions,
      perPage,
      page,
    })
  }

  const handleMyGroupShowSizeChange = (page: number, perPage: number) => {
    groupsStore.setMyGroupFilter({
      ...groupsStore.filterOptions,
      perPage,
      page,
    })
  }

  const handleRowChange = (record: IGroup) => ({
    onMouseEnter: () => setSelectedKey(record.id),
    onMouseLeave: () => setSelectedKey(null),
  })

  const handleGroupNameClick = (item: IGroup) => {
    navigate(ROUTES.singleGroup.replace(":id", item.id?.toString()))
  }

  return (
    <Table
      columns={
        formattedColumns({
          handleGroupNameClick,
          handleOpenModal,
          selectedKey,
          availableColumns: groupsStore.columnsSettingsValues,
        }) || []
      }
      key={groupsStore.columnsSettingsValues.length}
      rowKey="id"
      onRow={handleRowChange}
      className={cn("group-table")}
      scroll={{ x: 1500 }}
      dataSource={isMyGroup ? groupsStore.academicGroups?.staffGroups : groupsStore.groups?.groups}
      onStoreChange={isMyGroup ? handleMyGroupShowSizeChange : handleShowSizeChange}
      storeTitle={isMyGroup ? StoreTitle.MyGroupPerPage : StoreTitle.GroupsPerPage}
      loading={groupsStore.groupsTableLoading}
      pagination={{
        current: (isMyGroup ? groupsStore.myGroupFilter?.page : groupsStore.filterOptions?.page) || 1,
        total: isMyGroup ? groupsStore.academicGroups?.total! : groupsStore.groups?.total!,
        onChange: handleTablePaginationChange,
        ...getPaginationParams({
          total: isMyGroup ? groupsStore.academicGroups?.total! : groupsStore.groups?.total!,
          notShowSizeChanger: false,
        }),
        showSizeChanger: true,
        pageSize: isMyGroup ? perPageFromLocalStorage?.myGroupsPerPage : perPageFromLocalStorage?.groupsPerPage!,
      }}
    />
  )
})
