import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const SettingsGamificationOffering = lazy(() =>
  import("./GamificationOffering")
    .then(({ GamificationOffering }) => ({ default: GamificationOffering }))
    .catch(handleCatchChunkError),
)

export const SettingsGamificationOfferingUpdate = lazy(() =>
  import("./GamificationOffering/GamificationOfferingEdit")
    .then(({ GamificationOfferingEdit }) => ({ default: GamificationOfferingEdit }))
    .catch(handleCatchChunkError),
)

export const StudentStatuses = lazy(() =>
  import("./StudentStatuses")
    .then(({ StudentStatuses }) => ({ default: StudentStatuses }))
    .catch(handleCatchChunkError),
)

export const StudentStatusesMake = lazy(() =>
  import("./StudentStatuses/StudentStatusMake")
    .then(({ StudentStatusMake }) => ({ default: StudentStatusMake }))
    .catch(handleCatchChunkError),
)

export const MobileAppVersions = lazy(() =>
  import("./MobileAppVersions")
    .then(({ MobileAppVersions }) => ({ default: MobileAppVersions }))
    .catch(handleCatchChunkError),
)

export const SettingsFinance = lazy(() =>
  import("./SettingsFinance")
    .then(({ SettingsFinance }) => ({ default: SettingsFinance }))
    .catch(handleCatchChunkError),
)
