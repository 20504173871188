import { observer } from "mobx-react"
import { EditOutlined } from "@ant-design/icons"
import { Button, Divider, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"

import styles from "./statistics.module.scss"

const cn = classNames.bind(styles)

export const Statistics = observer(() => {
  console.log("")

  return (
    <div className={cn("statistics")}>
      <Flex gap={24} vertical>
        <Typography.Title level={5}>{mainDictionary.statistics}</Typography.Title>

        <Flex className={cn("statistics__wrapper")}>
          <Typography.Text className={cn("statistics__title")} type="secondary">
            {mainDictionary.fromPayers}
          </Typography.Text>

          <Typography.Text className={cn("statistics__price")}>245 000 000 so‘m</Typography.Text>
        </Flex>

        <Flex className={cn("statistics__wrapper")}>
          <Typography.Text className={cn("statistics__title")} type="secondary">
            {mainDictionary.lastPrice}
          </Typography.Text>

          <Typography.Text className={cn("statistics__title")}>8 800 000 so‘m + 7 360 soat</Typography.Text>
        </Flex>

        <Divider className={cn("statistics__divider")} />

        <Flex className={cn("statistics__wrapper")}>
          <Typography.Text className={cn("statistics__title")} type="secondary">
            {mainDictionary.allOfthem}
          </Typography.Text>

          <Typography.Text className={cn("statistics__title")}>8 800 000 so‘m + 7 360 soat</Typography.Text>
        </Flex>

        <Flex className={cn("statistics__wrapper")}>
          <Typography.Text className={cn("statistics__title")} type="secondary">
            {mainDictionary.visits}
          </Typography.Text>

          <Flex justify="space-between" align="center">
            <Typography.Text className={cn("statistics__title")}>305</Typography.Text>

            <Typography.Text type="secondary">23.09.2024 holatiga kora</Typography.Text>
          </Flex>
        </Flex>

        <Flex className={cn("statistics__wrapper")}>
          <Typography.Text className={cn("statistics__title")} type="secondary">
            {mainDictionary.payed}
          </Typography.Text>

          <Typography.Text className={cn("statistics__title")}>305</Typography.Text>
        </Flex>

        <Divider className={cn("statistics__divider")} />

        <Flex className={cn("statistics__wrapper")}>
          <Typography.Text className={cn("statistics__title")} type="secondary">
            {mainDictionary.scoreForGroup}
          </Typography.Text>

          <Flex align="center" gap={12}>
            <Typography.Text className={cn("statistics__title", { "text--bold": true })}>
              {mainDictionary.includesSalary}
            </Typography.Text>

            <Button icon={<EditOutlined />} size="small" type="text" shape="circle" />
          </Flex>
        </Flex>

        <Flex className={cn("statistics__wrapper")}>
          <Typography.Text className={cn("statistics__title")} type="secondary">
            {mainDictionary.allHours}
          </Typography.Text>

          <Flex align="center" gap={12}>
            <Typography.Text className={cn("statistics__title")}>305</Typography.Text>

            <Typography.Text className={cn("statistics__text", { "text--link": true })}>
              {mainDictionary.joiningGroups}
            </Typography.Text>
          </Flex>
        </Flex>

        <Flex className={cn("statistics__wrapper")}>
          <Typography.Text className={cn("statistics__title")} type="secondary">
            {mainDictionary.salaryForAllGroups}
          </Typography.Text>

          <Typography.Text className={cn("statistics__title")}>305</Typography.Text>
        </Flex>
      </Flex>
    </div>
  )
})
