export const ENDPOINTS = {
  permissionObjectsAll: "/access-control/permissions",
  accessControlMenus: "/access-control/menus",
} as const

export const permissionObjectTypes = {
  staffControl: "staffManagement",
  staff: "staff",
  staffControlRoles: "staff_control:roles",
  staffControlUsers: "staff_control:users",
  audits: "auditLogs",
  staffControlFaceIdLogs: "deviceLogs",
  staffControlBranchOffice: "offices",
  staffControlDevices: "devices",

  users: "users",
  usersAcademic: "academicStaff",
  usersStaff: "administrativeStaff",
  usersStudents: "students",
  usersTeachers: "users:teachers",

  allGroups: "groups",
  schooling: "schooling",

  myGroups: "my_groups",
  myGroupsAllHomeworks: "my_groups:all_homeworks",
  myGroupsAttendance: "my_groups:attendance",
  myGroupsCheckHomeworks: "my_groups:check_homeworks",
  myGroupsExam: "my_groups:exam",
  myGroupsVideoUpload: "my_groups:video_upload",
  myGroupsHomeworkStatistics: "my_groups:homework_statistics",
  myGroupsJournal: "my_groups:journal",

  gamification: "gamification",
  gamificationActions: "actions",
  gamificationGroups: "bind",
  gamificationLevels: "levels",
  gamificationMethodActions: "gamification:method_actions",
  gamificationStatistics: "gamification:statistics",
  gamificationUnits: "statistics",
  theBestOnes: "bestStudents",

  profile: "profile",
  study: "studyDepartment",
  studyCourses: "study:courses",
  studyMonths: "study:months",
  studyCurriculum: "study:curriculum",
  studyHomeworkStatistics: "homeworkStatistics",
  myGroupsExamChats: "my_groups:exam_chats",
  allGroupsExamChats: "all_groups:exam_chats",
  academicsKPI: "academicsKPI",
  skillClasses: "skillClasses",

  management: "management",
  managementReports: "reports",
  managementAutoCall: "autoCalls",

  notifications: "notifications",
  notificationsSent: "notifications:sent",

  settings: "settings",
  settingsStatus: "student_statutes",
  settingStudentStatus: "settings:student_statutes",
  settingsGamificationOffer: "settings:gamification_oferta",

  finance: "finance",

  students: "students",

  studentPayments: "studentPayments",

  store: "store",
  storeCategories: "storeCategories",
  storeItems: "storeItems",
  storeTransactions: "storeTransactions",
  studentCertificate: "student:certificate",
  settingsStudentStatuses: "settings:student_statutes",
  studyHomeworkDb: "study:homework_db",
  contracts: "settings:contracts",
  events: "events",
  eventsMasterClasses: "masterClasses",
  eventsCompetitions: "competitions",
  usersStudentsSmsHistory: "users:students_sms_history",
  studentDetail: "users:student_detail",
  settingsMobileVersions: "settings:mobile_versions",
} as const

export const permissions = {
  ViewAdministrativeStaff: "viewAdministrativeStaff",
  ManageAdministrativeStaff: "manageAdministrativeStaff",
  ViewAcademicStaff: "viewAcademicStaff",
  ManageAcademicStaff: "manageAcademicStaff",
  ViewAcademicStaffStatistics: "viewAcademicStaffStatistics",
  ViewStudents: "viewStudents",
  AddStudent: "addStudent",
  SendStudentSMS: "sendStudentSMS",
  ManageStudents: "manageStudents",
  ViewStudentCertificates: "viewStudentCertificates",
  EditStudentCertificate: "editStudentCertificate",
  ViewGroups: "viewGroups",
  ManageGroups: "manageGroups",
  ManageGroupAttendances: "manageGroupAttendances",
  ViewGamificationStatistics: "viewGamificationStatistics",
  ManageGamificationRewards: "manageGamificationRewards",
  ViewGamificationLevels: "viewGamificationLevels",
  ManageGamificationLevels: "manageGamificationLevels",
  ViewGamificationActions: "viewGamificationActions",
  ManageGamificationActions: "manageGamificationActions",
  ViewGamificationBestStudents: "viewGamificationBestStudents",
  ManageGamificationBestStudents: "manageGamificationBestStudents",
  ManageSettings: "manageSettings",
  ViewAuditLogs: "viewAuditLogs",
  ManageAutoCalls: "manageAutoCalls",
  ViewOffices: "viewOffices",
  ManageOffices: "manageOffices",
  ManageClassrooms: "manageClassrooms",
  ViewSchedules: "viewSchedules",
  ViewDevices: "viewDevices",
  ViewDeviceLogs: "viewDeviceLogs",
  ManageDevices: "manageDevices",
  ViewCurriculums: "viewCurriculums",
  ManageCurriculums: "manageCurriculums",
  ViewJournal: "viewJournal",
  ManageVideos: "manageVideos",
  ManageHomeworks: "manageHomeworks",
  ViewHomeworkStatistics: "viewHomeworkStatistics",
  ManageHomeworkDb: "manageHomeworkDb",
  ManageExams: "manageExams",
  ManageNotifications: "manageNotifications",
  ViewReports: "viewReports",
  ManageStore: "manageStore",
  ManageEvents: "manageEvents",
  ManageSkillClasses: "manageSkillClasses",
}

export const permissionObjectTypesValues = Object.values(permissionObjectTypes)
