import { useEffect, useMemo } from "react"
import { observer } from "mobx-react"
import { Button, Flex, Form, Input, Select } from "antd"
import classNames from "classnames/bind"
import dayjs from "dayjs"
import { useDebounceValue } from "usehooks-ts"
import { IGroupStatus } from "@/api/groups"
import SettingsIcon from "@/assets/icons/settings.svg"
import mainDictionary from "@/dictionary"
import { addCatchNotification } from "@/modules/notifications"
import { branchOfficeStore } from "@/pages/Controls/BranchOffice/shared/branch-office.store"
import { getPerPages } from "@/shared"
import { useStores } from "@/stores"
import { ISelectOption } from "@/stores/study-department/types"
import { allOption, groupStatus } from "../constants"

import styles from "./filter.module.scss"

const cn = classNames.bind(styles)

export const Filter = observer(() => {
  const { groupsStore, studyDepartment } = useStores()
  const { filterOptions, setSelectedOption } = groupsStore
  const [debouncedValue, setDebounceValue] = useDebounceValue("", 1000)
  const [form] = Form.useForm()
  const perPageFromLocalStorage = getPerPages()

  const handleGroupNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDebounceValue(e.currentTarget.value)

    const info = {
      ...filterOptions,
      value: e.currentTarget.value,
      page: 1,
    }

    groupsStore.setFilterOption(info)
  }

  const handleClear = () => {
    setDebounceValue("")

    const info = {
      page: 1,
      status: IGroupStatus.Active,
      keys: ["hName"],
      perPage: perPageFromLocalStorage?.groupsPerPage || 10,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
    form.resetFields()
    form.setFieldValue("status", IGroupStatus.Active)
  }

  useEffect(() => {
    if (groupsStore.isSynchronized) {
      handleClear()
    }
  }, [groupsStore.isSynchronized])

  useEffect(() => {
    const info = {
      ...filterOptions,
      value: filterOptions?.value || debouncedValue,
      status: filterOptions?.status ? filterOptions?.status : String(IGroupStatus.Active),
    }

    if (filterOptions?.status === null) {
      info.status = ""
    }

    groupsStore.setGroupsTableLoading(true)
    groupsStore.getGroups(info)
  }, [debouncedValue])

  useEffect(() => {
    if (Object.keys(filterOptions).length) {
      setDebounceValue(filterOptions.value!)
      setSelectedOption(
        filterOptions.status ? filterOptions.status : filterOptions?.status === null ? null : IGroupStatus.Active,
      )
      form.setFieldsValue({
        groupName: filterOptions.value,
        startDate: filterOptions.fromByStartedDate
          ? [dayjs(filterOptions.fromByStartedDate), dayjs(filterOptions.toByStartedDate)]
          : undefined,
        endDate: filterOptions?.fromByEndedDate
          ? [dayjs(filterOptions.fromByEndedDate), dayjs(filterOptions.toByEndedDate)]
          : undefined,
        officeId: filterOptions?.officeIds,
        category: filterOptions?.categoryId,
        course: filterOptions?.courseId,
        teacher: filterOptions?.teacherId,
        type: filterOptions?.learningTypeId,
        status: filterOptions?.status,
      })
    }
  }, [])

  useEffect(() => {
    if (groupStatus.length > 1) {
      form.setFieldsValue({
        status: filterOptions.status
          ? filterOptions.status
          : filterOptions?.status === null
            ? null
            : IGroupStatus.Active,
      })
    }
  }, [groupStatus])

  useEffect(() => {
    studyDepartment.getFilterObjects()
  }, [])

  useEffect(() => {
    branchOfficeStore.getBranchOffices().catch(addCatchNotification)
  }, [])

  const categories = useMemo(
    () =>
      studyDepartment.categories?.map((item) => ({
        label: item.label,
        value: item?.value,
      })),
    [studyDepartment.categories],
  )

  const handleChangeCourse = (courseId: number) => {
    const info = {
      ...filterOptions,
      page: 1,
      courseId,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleChangeLearningType = (learningTypeId: number) => {
    const info = {
      ...filterOptions,
      page: 1,
      learningTypeId,
    }

    groupsStore.getGroups(info)
    groupsStore.setFilterOption(info)
  }

  const handleFilter = (input: string, option?: ISelectOption) =>
    (option?.label ?? "").toLowerCase().includes(input?.toLowerCase() ?? "")

  const handleFilterSort = (optionA: ISelectOption, optionB: ISelectOption) =>
    (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())

  return (
    <Form disabled={groupsStore.groupsTableLoading} layout="vertical" className={cn("filter")} form={form}>
      <Flex align="center" justify="space-between" gap={20}>
        <Flex gap={20}>
          <Form.Item label={mainDictionary.searchInputPlaceholder} name="groupName">
            <Input placeholder={mainDictionary.filterName.placeholder} onChange={handleGroupNameChange} />
          </Form.Item>

          <Form.Item label={mainDictionary.course} name="course">
            <Select
              options={studyDepartment.courses!}
              onChange={handleChangeCourse}
              placeholder={mainDictionary.select}
              filterOption={handleFilter}
              showSearch
              filterSort={handleFilterSort}
              className={cn("filter__select")}
            />
          </Form.Item>

          <Form.Item label={mainDictionary.category} name="category">
            <Select
              options={categories}
              className={cn("filter__select")}
              placeholder={mainDictionary.searchInputPlaceholder}
              defaultValue={allOption?.value}
            />
          </Form.Item>

          <Form.Item label={mainDictionary.typeOfStudy} name="type">
            <Select
              className={cn("filter__select")}
              placeholder={mainDictionary.searchInputPlaceholder}
              options={studyDepartment.learningTypes!}
              onChange={handleChangeLearningType}
            />
          </Form.Item>
        </Flex>

        <Button disabled icon={<img src={SettingsIcon} alt="settings" />}>
          {mainDictionary.additionalFilter}
        </Button>
      </Flex>
    </Form>
  )
})
