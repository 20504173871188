import { useMemo } from "react"
import { observer } from "mobx-react"
import { Modal, Table, Typography } from "antd"
import classNames from "classnames/bind"
import { ScheduleItem } from "@/api/groups"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { getFormattedTimeFromParams } from "@/utils/date"

import styles from "./info-modal.module.scss"

const cn = classNames.bind(styles)

export const InfoModal = observer(() => {
  const { gamificationStore } = useStores()

  const groupStaff = useMemo(() => gamificationStore.groupStaff, [gamificationStore.groupStaff])

  const handleClose = () => {
    gamificationStore.resetGroupStaff()
    gamificationStore.setByGroupInfoModal(false)
  }

  const columns = useMemo(
    () => [
      {
        title: mainDictionary.order,
        render: (_: ScheduleItem, item: ScheduleItem, index: number) => <span>{index + 1}</span>,
      },
      {
        title: mainDictionary.teacherName,
        render: (item: ScheduleItem) => <span>{`${item.staff?.firstName} ${item.staff?.lastName}`}</span>,
      },
      {
        title: mainDictionary.iRole,
        render: (item: ScheduleItem) => <span>{item.staff?.role?.name || "-"}</span>,
      },
      {
        title: mainDictionary.dLessonDays,
        render: (item: ScheduleItem) => <span>{item?.daysOfWeek.join("-")}</span>,
      },
      {
        title: mainDictionary.dLessonTime,
        render: (item: ScheduleItem) => (
          <span>{`${getFormattedTimeFromParams(item.beginTime)} - ${getFormattedTimeFromParams(item.endTime)}`}</span>
        ),
      },
    ],
    [],
  )

  return (
    <Modal
      open={gamificationStore.byGroupInfoModal}
      onCancel={handleClose}
      title={mainDictionary.groupInfo}
      onOk={handleClose}
      width={800}
    >
      <div className={cn("info-modal")}>
        <div>
          <Typography.Title level={5}>{mainDictionary.activeSchedules}</Typography.Title>
          <Table dataSource={groupStaff?.activeScheduleItems} columns={columns} bordered pagination={false} />
        </div>

        <div>
          <Typography.Title level={5}>{mainDictionary.other}</Typography.Title>

          <Table columns={columns} bordered pagination={false} dataSource={groupStaff?.othersScheduleItems!} />
        </div>
      </div>
    </Modal>
  )
})
