import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { AutoCall, AutoCallHistory, Reports } from "./lazy"

export const managementRoute = [
  {
    path: ROUTES.management,
    children: [
      {
        index: true,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.managementReports}>
            <Reports />
          </AvailableComponent>
        ),
      },
    ],
  },
  {
    path: ROUTES.managementAutoCall,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.management}>
        <AutoCall />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.managementAutoCallHistory,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.management}>
        <AutoCallHistory />
      </AvailableComponent>
    ),
  },
]
