import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import { DeleteOutlined, EditOutlined, ScheduleOutlined } from "@ant-design/icons"
import { Button, Flex, Tooltip, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"

import styles from "./row-item.module.scss"

const cn = classNames.bind(styles)

export const RowItem = observer(() => {
  const { groupsStore } = useStores()

  const handleEdit = () => {
    groupsStore.setScheduleModal(true)
  }

  return (
    <div className={cn("row")}>
      <Link className={cn("row__name")} to="/">
        Azizbek Tursunov
      </Link>

      <Typography.Text>Du/Se/Ch/Pa/Ju</Typography.Text>
      <Typography.Text>09:00 dan - 12:00 gacha</Typography.Text>
      <Typography.Text>01 Avg, 2024 - 01 Fev, 2025</Typography.Text>
      <Typography.Text>Yandeks</Typography.Text>

      <Flex gap={16} justify="center">
        <Tooltip title={mainDictionary.schedule}>
          <Button icon={<ScheduleOutlined />} />
        </Tooltip>

        <Tooltip title={mainDictionary.edit}>
          <Button onClick={handleEdit} icon={<EditOutlined />} />
        </Tooltip>

        <Tooltip title={mainDictionary.delete}>
          <Button danger icon={<DeleteOutlined />} />
        </Tooltip>
      </Flex>
    </div>
  )
})
